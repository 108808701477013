import React, { Component } from 'react';
//import { userLogout } from "../../actions/userActions";
import { questionInstanceGrade } from '../../actions/questionActions';
import { connect } from 'react-redux';
import { Intent, Spinner } from '@blueprintjs/core';
import { Button } from '@blueprintjs/core'; //MenuDivider
import { gradingQuestionSets } from '../../actions/questionSetActions';
import _ from 'lodash';
import PureGridBox24th from '../utils/PureGridBox24th';
import { arrayFromKeyedObject } from '../utils/sharedUtils';
import Moment from 'react-moment';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';

const ALLOWED_QUESTION_TYPES = ['essay'];

class Grading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: {
        grading: { rules: [{ name: 'isNumber' }], isValid: true, errors: [] },
      },
      grades: {},
      initialized: false,
    };
  }

  componentDidMount() {
    this.props.gradingQuestionSets(
      this.props.router.params.questionSetId,
      true,
      true,
      () => this.setInitialGradeValues(),
      null
    );
    // let descOrderedBy = _.orderBy(this.props.questionSetInstances, ['end_time'], ['asc']);
  }

  setInitialGradeValues = () => {
    this.getEssayQuestionInstances();
  };

  handleGradeChange = (e, instanceId) => {
    let grades = this.state.grades;
    grades[instanceId] = e.target.value;
    this.setState({
      grades: grades,
    });
  };

  handleGradeSubmit = (instanceId) => {
    if (this.state.grades[instanceId]) {
      this.props.questionInstanceGrade(
        instanceId,
        this.state.grades[instanceId],
        () => this.showGradedToaster(),
        null
      );
    }
  };

  showGradedToaster = () => {
    AppToaster.show({
      message: 'Question Graded.',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  getIntent(name) {
    if (this.state.validation[name].isValid) {
      return 'bp5-intent-primary';
    } else {
      return 'bp5-intent-warning';
    }
  }

  getEssayQuestionInstances = () => {
    let closedAndPendingQuestionSetInstances = _.filter(
      this.props.questionSetInstances,
      { closed: true, flex: { meta_data: { pending_grading: true } } }
    );
    let allowedQuestionTypes = _.filter(this.props.questionTypes, (qt) => {
      return ALLOWED_QUESTION_TYPES.indexOf(qt.code) > -1;
    });
    let allowedQuestions = _.filter(this.props.questions, (question) => {
      return _.map(allowedQuestionTypes, 'id').indexOf(question.type) > -1;
    });
    let questionInstances = _.filter(this.props.questionInstances, (qi) => {
      return _.map(allowedQuestions, 'id').indexOf(qi.question) > -1;
    });

    _.map(closedAndPendingQuestionSetInstances, (qsi, i) => {
      let qInstances = _.filter(questionInstances, {
        question_set_instance: qsi.id,
      });
      for (let j = 0; j < qInstances.length; j++) {
        let question = _.find(allowedQuestions, { id: qInstances[j].question });
        let type = _.find(allowedQuestionTypes, { id: question.type });
        let typeCode = type.code;

        switch (typeCode) {
          case 'essay':
            this.setState({
              grades: {
                [qInstances[j].id]: _.get(qInstances[j], 'flex.grade', 0),
              },
              initialized: true,
            });
            break;
          default:
            break;
        }
      }
    });
  };

  goBackToReport = () => {
    this.props.router.navigate({
      pathname: '/admin/report',
      state: { fromGrading: true },
    });
  };

  getRenderedQuestionSetInstancesWithQuestions = () => {
    let closedAndPendingQuestionSetInstances = _.filter(
      this.props.questionSetInstances,
      { closed: true, flex: { meta_data: { pending_grading: true } } }
    );
    let allowedQuestionTypes = _.filter(this.props.questionTypes, (qt) => {
      return ALLOWED_QUESTION_TYPES.indexOf(qt.code) > -1;
    });
    let allowedQuestions = _.filter(this.props.questions, (question) => {
      return _.map(allowedQuestionTypes, 'id').indexOf(question.type) > -1;
    });
    let questionInstances = _.filter(this.props.questionInstances, (qi) => {
      return _.map(allowedQuestions, 'id').indexOf(qi.question) > -1;
    });
    let addHr = false;

    let renderedQuestionSetInstances = _.map(
      closedAndPendingQuestionSetInstances,
      (qsi, i) => {
        let user = this.props.users[qsi.user];
        let qInstances = _.filter(questionInstances, {
          question_set_instance: qsi.id,
        });
        let renderedEssayQuestionInstaces = [];
        for (let j = 0; j < qInstances.length; j++) {
          let question = _.find(allowedQuestions, {
            id: qInstances[j].question,
          });
          let type = _.find(allowedQuestionTypes, { id: question.type });
          let typeCode = type.code;

          switch (typeCode) {
            case 'essay':
              let renderedEssayQuestionInstance =
                this.getRenderedEssayQuestions(qInstances[j], question, i);
              renderedEssayQuestionInstaces.push(renderedEssayQuestionInstance);
              break;
            default:
              break;
          }

          addHr = false;
          if (i === 0 && i < closedAndPendingQuestionSetInstances.length - 1) {
            addHr = true;
          }
        }

        return (
          <div key={i}>
            <div className="row">
              Test taken by{' '}
              <b>
                {user.first_name} {user.last_name}
              </b>{' '}
              on{' '}
              <b>
                <Moment format="LLL">{qsi.createdAt}</Moment>
              </b>
            </div>
            <div className="row">
              <div
                className="pure-u-1-6"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                <b>Question</b>
              </div>
              <div
                className="pure-u-3-8"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                <b>Answer</b>
              </div>
              <div
                className="pure-u-1-4"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                <b>Grade (0 - 100)</b>
              </div>
              <div className="5-24"></div>
            </div>
            <div className="row">{renderedEssayQuestionInstaces}</div>
            <div className="row">{addHr ? <hr /> : null}</div>
            <div className="row">
              <div className="pure-u-1-3"></div>
              <button
                className="bp5-button bp5-large bp5-intent-primary pure-u-1-3"
                style={{ cursor: 'pointer', float: 'center' }}
                onClick={() => this.goBackToReport()}
              >
                Go back to report view
              </button>
            </div>
          </div>
        );
      }
    );

    return renderedQuestionSetInstances;
  };

  CheckDefined = (id) => {
    if (id === '' || id === 0) {
      return 'Grade';
    } else {
      return 'Update';
    }
  };

  getRenderedEssayQuestions = (instance, question, i) => {
    return (
      <div className="row" key={question.question_text}>
        <div
          className="pure-u-1-6"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          {question.question_text}
        </div>
        <div
          className="pure-u-3-8"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          {instance.flex.answer}
        </div>
        <div
          className="pure-u-1-4"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          <input
            type="numeric"
            max="100"
            min="0"
            step="1"
            placeholder="Number 0 - 100"
            value={this.state.grades[instance.id] || ''}
            className={'bp5-input ' + this.getIntent('grading')}
            onChange={(e) => this.handleGradeChange(e, instance.id)}
          />
        </div>
        <div
          className="pure-u-5-24"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          <Button
            intent="primary"
            onClick={() => this.handleGradeSubmit(instance.id)}
          >
            {this.CheckDefined(this.state.grades[instance.id])}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.initialized === false) {
      return (
        <div>
          <br />
          <Spinner />
        </div>
      );
    }

    let renderedQuestionSetsWithQuestions =
      this.getRenderedQuestionSetInstancesWithQuestions();
    //map the resulting gradable essay questions, allow manager / admin user to read the answers and an
    //input to grade the answers (show existing grade of the instance as initial value), ability to submit the graded answers at the bottom of the page, which should call updateQuestion
    return (
      <PureGridBox24th>
        <div className="pure-u-1-6" />
        <div className="pure-u-5-8">
          <div>
            <h2>
              Pending reviews in <b>{this.props.questionSet.name}</b>
            </h2>
          </div>
          <hr />
          {renderedQuestionSetsWithQuestions}
        </div>
      </PureGridBox24th>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let questionSet =
    state.data.questionSets[ownProps.router.params.questionSetId];
  let questionSetInstances = _.filter(
    arrayFromKeyedObject(state.data.questionSetInstances),
    { question_set: Number(ownProps.router.params.questionSetId) }
  );
  let questionInstances = _.filter(
    arrayFromKeyedObject(state.data.questionInstances),
    (qi) => {
      return (
        _.map(questionSetInstances, 'id').indexOf(qi.question_set_instance) > -1
      );
    }
  );
  let questionIds = _.map(questionInstances, 'question');
  let questions = _.filter(
    arrayFromKeyedObject(state.data.questions),
    (question) => {
      return questionIds.indexOf(question.id) > -1;
    }
  );
  let questionTypes = arrayFromKeyedObject(state.data.questionTypes);

  return {
    questionInstances: questionInstances,
    questionSetInstances: state.data.questionSetInstances,
    questions: questions,
    questionSet: questionSet,
    questionTypes: questionTypes,
    users: state.data.users,
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    gradingQuestionSets: (
      questionSetId,
      closed,
      pendingGrading,
      onSuccess,
      onFail
    ) => {
      dispatch(
        gradingQuestionSets(
          questionSetId,
          closed,
          pendingGrading,
          onSuccess,
          onFail
        )
      );
    },
    questionInstanceGrade: (questionInstanceId, grade, onSuccess, onFail) => {
      dispatch(
        questionInstanceGrade(questionInstanceId, grade, onSuccess, onFail)
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Grading)
);
