import _ from 'lodash';
import { isNull } from './sharedUtils';
const validator = require('validator');
// import validator from 'validator';

export function errorsToString(errors) {
    let str = "";
    for (var i = 0; i < errors.length; i++) {
        str += errors[i];
        if (i !== errors.length - 1) {
            str += ', ';
        }
    }
    return str;
}

export function getIntent(stateValidation, name) {
    if (stateValidation[name].isValid) {
        return 'bp5-intent-primary';
    }
    else {
        return 'bp5-intent-warning';
    }
}

export function countErrors(validation) {
    let invalidCount = 0;
    Object.keys(validation).forEach((key) => {
        if (!validation[key].isValid) {
            invalidCount++;
        }
    });
    return invalidCount;
}

export function validate(state) {

    let returnValidation = _.cloneDeep(state.validation);
    _.forOwn(state.validation, function (value, key) {

        returnValidation[key].isValid = true; //assume true to start
        returnValidation[key].errors = []; //clear array for full validation
        _.forEach(returnValidation[key].rules, rule => {
            let check;
            switch (rule.name) {
                case 'isString':
                    check = isString(state[key]);
                    break;
                case 'forceError':
                    check = forceError(state[key]);
                    break;
                case 'isRequired':
                    check = isRequired(state[key]);
                    break;
                case 'isInt':
                    check = isInt(state[key]);
                    break;
                case 'greaterThan':
                    check = greaterThan(state[key], rule.val);
                    break;
                case 'greaterThanOrEqualTo':
                    check = greaterThanOrEqualTo(state[key], rule.val);
                    break;
                case 'lessThan':
                    check = lessThan(state[key], rule.val);
                    break;
                case 'lessThanOrEqualTo':
                    check = lessThanOrEqualTo(state[key], rule.val);
                    break;
                case 'isEmail':
                    check = isEmail(state[key])
                    break;
                case 'isPassword':
                    check = isPassword(state[key])
                    break;
                case 'matchesVal':
                    check = matchesVal(state[key], rule.val, rule.errMessage)
                    break;
                case 'isIntOrStringInt':
                    check = isIntOrStringInt(state[key]);
                    break;
                default:
                    break;
            }
            if (!check) {
                return;
            }
            if (check.valid === false) {
                returnValidation[key].isValid = false;
                returnValidation[key].errors.push(check.error);
            }
        })

    });

    return returnValidation;

    function isEmail(item) {
        if (!validator.isEmail(item)) {
            return { valid: false, error: 'Valid Email required' }
        }
        else {
            return { valid: true, error: null }
        }
    }

    function isPassword(item) {
        if (typeof item !== 'string') {
            return { valid: false, error: 'password must be a string' }
        }
        else if (item.length < 5) {
            return { valid: false, error: 'password must be at least 5 characters' }
        }
        return { valid: true, error: null }
    }

    function matchesVal(item, val, errMessage) {
        if (item === val) {
            return { valid: true, error: null }
        }
        return { valid: false, error: errMessage }
    }

    function isRequired(item) {
        if (isNull(item)) {
            if (Array.isArray(item)) {
                if (item.length > 0) {
                    return { valid: true, error: null }
                }
            }
            return { valid: false, error: 'Value is required' }
        }
        if (item instanceof Array && item.length === 0) {
            return { valid: false, error: 'At least one is required' }
        }
        return { valid: true, error: null }
    }

    function isInt(item) {
        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            if (_.isInteger(item)) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be a valid int' }
            }
        }
    }

    function isIntOrStringInt(item) {
        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            item = Number(item)
            if (_.isInteger(item)) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be a valid int' }
            }
        }
    }

    function lessThan(item, val) {

        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            if (_.isNumber(item) && item < val) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be less than ' + val }
            }
        }


    }

    function lessThanOrEqualTo(item, val) {
        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            if (_.isNumber(item) && item <= val) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be less or equal to ' + val }
            }
        }

    }

    function greaterThan(item, val) {
        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            if (_.isNumber(item) && item > val) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be greater than ' + val }
            }
        }
    }

    function greaterThanOrEqualTo(item, val) {
        if (isNull(item)) {
            return { valid: false, error: 'This number must be set' }
        }
        else {
            if (_.isNumber(item) && item >= val) {
                return { valid: true, error: null }
            }
            else {
                return { valid: false, error: 'This value must be greater or equal than ' + val }
            }
        }

    }

    function isString(item) {
        if (typeof item === 'string') {
            if (item !== "" && item.length > 0) {
                return { valid: true, error: null };
            }
        }
        return { valid: false, error: 'Valid string required' };
    }

    function forceError(item) {
        return { valid: false, error: 'This error was forced' };
    }


}
