import { call, put } from 'redux-saga/effects';
import request from '../utils/api/ServerRequest';
import _ from 'lodash';
import { schema } from 'normalizr';
const departmentSchema = new schema.Entity('departments');

async function fetchDepartmentsAPI(payload) {
  return await request.get('/v1/department/fetch');
}

async function createDepartmentsAPI(payload) {
  return await request.post('/v1/department/create', payload);
}

async function updateDepartmentsAPI(payload) {
  return await request.put('/v1/department/update', payload);
}

const departmentSaga = {
  departmentFetch: function* (action) {
    let success = false;
    try {
      const response = yield call(fetchDepartmentsAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'DEPARTMENT_FETCH_SUCCESS',
          payload: {
            actives: response.data.departments.filter((d) => d.active),
            all: response.data.departments,
          },
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {}

    if (!success) {
      yield put({
        type: 'DEPARTMENT_FETCH_FAIL',
        payload: { departments: [] },
      });
      if (action.onFail) action.onFail();
    }
  },

  createDepartment: function* (action) {
    let success = false;
    try {
      const response = yield call(createDepartmentsAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'DEPARTMENT_CREATE_SUCCESS',
          payload: response.data.department,
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {
      console.log('error', e);
    }

    if (!success) {
      yield put({
        type: 'DEPARTMENT_CREATE_FAIL',
        payload: { department: null },
      });
      if (action.onFail) action.onFail();
    }
  },

  updateDepartment: function* (action) {
    let success = false;
    try {
      const response = yield call(updateDepartmentsAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'DEPARTMENT_UPDATE_SUCCESS',
          payload: response.data.department,
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {
      console.log('error', e);
    }

    if (!success) {
      yield put({
        type: 'DEPARTMENT_UPDATE_FAIL',
        payload: { department: null },
      });
      if (action.onFail) action.onFail();
    }
  },
};

export default departmentSaga;
