import { call, put } from "redux-saga/effects";
import _ from "lodash";
import request from '../utils/api/ServerRequest';
import { normalize, schema } from 'normalizr';
const roleSchema = new schema.Entity('roles')

async function roleFetchAPI() {
  try {
    return await request.get('/v1/auth/fetch_roles');
  }
  catch (e) {
    return e;
  }
}

async function getCSRFAPI() {
  try {
    return await request.get('/security/grant-csrf-token');
  }
  catch (e) {
    return e;
  }
}

async function registerAPI(payload) {
  try {
    return await request.post('/v1/auth/register', {
      email: payload.email,
      first_name: payload.first_name,
      last_name: payload.last_name,
      password: payload.password,
      restaurants: payload.restaurants,
      departments: payload.departments,
      captcha: payload.captcha
    });
  }
  catch (e) {
    return e
  }
}

async function loginAPI(payload) {
  try {
    return await request.post('/v1/auth/login', {
      email: payload.email,
      password: payload.password,
      method: 'session'
    });
  }
  catch (e) {
    return e
  }
}

async function logoutAPI(payload) {
  try {
    return await request.get('/v1/auth/logout');
  }
  catch (e) {
    return e
  }
}


async function requestPasswordResetAPI(payload) {
  try {
    return await request.get('/v1/auth/request_password_reset', {
      email: payload.email,
      captcha: payload.captcha
    });
  } catch (e) {
    return e;
  }
}

async function passwordResetAPI(payload) {
  try {
    return await request.post('/v1/auth/password_reset', {
      password: payload.password,
      token: payload.token
    });
  } catch (e) {
    return e;
  }
}

async function validateTokenAPI(payload) {
  try {
    return await request.get('/v1/auth/validate_token', {
      token: payload.token
    });
  } catch (e) {
    return e;
  }
}

const authSaga = {

  roleFetch: function* (action) {
    let success = false;
    try {
      const response = yield call(roleFetchAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({ type: "ROLE_FETCH_SUCCESS", normalized: normalize(response.data.roles, [roleSchema]) });
        if (action.onSuccess) {
          action.onSuccess()
        }
      }
    } catch (e) {

    }
    if (!success) {
      yield put({ type: "USER_REGISTER_FAIL", payload: { authorized: false, method: 'session' } });
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  getCSRF: function* (action) {
    let success = false;
    try {
      const response = yield call(getCSRFAPI, action.payload);
      const csrf = _.get(response, 'data._csrf', null)
      if (csrf !== null) {
        request.setDefaultOption('csrf', csrf);
        success = true;
        yield put({ type: "GET_CSRF_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      success = false;
    }

    if (!success) {
      yield put({ type: "GET_CSRF_FAIL", payload: { departments: [] } });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  login: function* (action) {
    let success = false;
    try {
      const response = yield call(loginAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({ type: "USER_LOGIN_SUCCESS", payload: { authorized: true, ...response.data.data.user, method: action.payload.method } });
        yield put({ type: "USER_LOGIN_ROLES_SUCCESS", normalized: normalize(response.data.data.roles, [roleSchema]) });
        success = true;
        if (action.onSuccess) {
          action.onSuccess(response.data.data.user);
        }
      }
      else {

      }
    } catch (e) {

    }
    if (!success) {
      if (action.onFail) {
        action.onFail()
      }
      yield put({ type: "USER_LOGIN_FAIL", payload: { authorized: false, method: action.payload.method } });
    }
  },

  logout: function* (action) {
    let success = false;
    try {
      const response = yield call(logoutAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({ type: "USER_LOGOUT_SUCCESS", payload: { authorized: false } }); //_.get(response, 'data.data.user.first_name', null)
        success = true;
        if (action.onSuccess) {
          action.onSuccess()
        }
      }
      else {

      }
    } catch (e) {

    }
    if (!success) {
      //console.log("LOGOUT FAIL?")
    }
  },

  register: function* (action) {
    let success = false;
    try {
      const response = yield call(registerAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        //yield put({ type: "USER_REGISTER_SUCCESS", payload: { authorized: true, ...response.data.data.user, method: 'session' } });
        if (action.onSuccess) {
          action.onSuccess()
        }
      } else {
        yield put({ type: "USER_REGISTER_FAIL", payload: { authorized: false, method: 'session' } });
        if (action.onFail) {
          action.onFail(response.response.data.errors[0].detail)
        }
      }
    } catch (e) {
      action.onFail(e)
    }
  },

  passwordResetRequest: function* (action) {
    //console.log("hitting passwordResetRequest")
    try {
      const response = yield call(requestPasswordResetAPI, action.payload);
      let status = _.get(response, 'data.status', null);

      if (status !== null) {
        if (status === "success") {
          yield put({ type: "USER_PASSWORD_RESET_REQUEST_SUCCESS", payload: response.data });
          if (action.onSuccess)
            action.onSuccess(response.data.data);
        } else {
          yield put({ type: "USER_PASSWORD_RESET_REQUEST_FAIL", payload: {} });
          if (action.onFail)
            //console.log("failing", response.data.errors);
            action.onFail(response.data.errors);
        }
      } else {
        if (action.onFail)
          action.onFail(null);
      }
    } catch (e) {
      if (action.onFail)
        action.onFail(null);
    }
  },

  passwordReset: function* (action) {
    try {
      const response = yield call(passwordResetAPI, action.payload);
      let status = _.get(response, 'data.status', null);

      if (status !== null) {
        if (status === "success") {
          yield put({ type: "USER_PASSWORD_RESET_SUCCESS", payload: response.data });
          if (action.onSuccess)
            action.onSuccess(response.data.data);
        } else {
          yield put({ type: "USER_PASSWORD_RESET_FAIL", payload: {} });
          if (action.onFail)
            action.onFail(response.data.errors);
        }
      } else {
        if (action.onFail)
          action.onFail(null);
      }
    } catch (e) {
      if (action.onFail)
        action.onFail(null);
    }
  },

  validateToken: function* (action) {
    let success = false;
    try {
      const response = yield call(validateTokenAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({ type: "USER_VALIDATE_TOKEN_SUCCESS", payload: response.data });
        if (action.onSuccess)
          action.onSuccess(response.data.data);
      } else {
        action.onFail(response.data.errors);
      }
    } catch (e) {
      console.log(e);
    }

    if (!success) {
      yield put({ type: "USER_VALIDATE_TOKEN_FAIL", payload: {} });
      if (action.onFail)
        action.onFail(null);
    }
  },

}

export default authSaga
