import React, { Component } from 'react';
import { logout, passwordReset } from '../../actions/authActions';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import Cellbox from '../utils/Cellbox';
import { userUpdate } from '../../actions/userActions';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';

class TemporaryPasswordReset extends Component {
  constructor(props) {
    super(props);

    let user = this.props.user;
    let id = user.id;
    let first_name = user.first_name;
    let last_name = user.last_name;
    let email = user.email;
    this.state = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      current_password: '',
      new_password: '',
      confirm_password: '',
      validation: {
        first_name: { rules: ['isString'], isValid: true, errors: [] },
        last_name: { rules: ['isString'], isValid: true, errors: [] },
      },
    };
  }

  handleStateChange = (event, attribute) => {
    this.setState({
      [attribute]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const onSuccess = (user) => this.handleSuccess(user);
    const onFail = (message) => this.handleFail(message);

    let new_password = null;
    let current_password = null;
    if (this.state.new_password !== '') {
      if (this.state.new_password !== this.state.confirm_password) {
        this.handleFail('Password confirmation does not match');
        return;
      } else if (this.state.current_password === '') {
        this.handleFail('Current password is required');
        return;
      } else {
        //TODO: logic to confirm password is secure enough regex
        new_password = this.state.new_password;
        current_password = this.state.current_password;
      }
    }
    this.props.userUpdate(
      this.state.id,
      this.state.first_name,
      this.state.last_name,
      current_password,
      new_password,
      onSuccess,
      onFail
    );
  };

  handleSuccess = (user) => {
    AppToaster.show({
      message: 'User updated successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
    this.props.logout(() => this.props.router.navigate('/'));
  };

  handleFail = (custom_message) => {
    let message = 'User update failed.';
    if (custom_message) {
      message = custom_message;
    }
    AppToaster.show({
      message: message,
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
  };

  render() {
    let pureClass = 'pure-u-1 pure-u-md-6-24 pure-u-xl-3-12';

    return (
      <div>
        <div className="flexcenter" style={{ paddingTop: 16, fontSize: 24 }}>
          Change your password
        </div>

        <div className="pure-g">
          <div className="pure-u-1">
            <div className="flexcenter">
              <div className="pure-u-xl-1-5 pure-u-lg-1-4 pure-u-md-1-4 pure-u-sm-3-4">
                <hr />
              </div>
            </div>
          </div>

          <div className="pure-u-1">
            <div className="flexcenter">
              <Cellbox
                showBreak={false}
                pure_override={pureClass}
                label="Current Password"
              >
                <div className="">
                  <input
                    placeholder="Current temporary password"
                    className="bp5-input bp5-fill bp5-intent-primary"
                    type="password"
                    value={this.state.current_password}
                    onChange={(e) =>
                      this.handleStateChange(e, 'current_password')
                    }
                    dir="auto"
                  />
                </div>
              </Cellbox>
            </div>

            <div className="flexcenter">
              <Cellbox
                showBreak={false}
                pure_override={pureClass}
                label="Password"
                validation={this.state.validation.new_password}
              >
                <div className="">
                  <input
                    placeholder="Password"
                    className="bp5-input bp5-fill bp5-intent-primary"
                    type="password"
                    value={this.state.new_password}
                    onChange={(e) => this.handleStateChange(e, 'new_password')}
                    dir="auto"
                  />
                </div>
              </Cellbox>
            </div>

            <div className="flexcenter">
              <Cellbox
                showBreak={false}
                pure_override={pureClass}
                label="Confirm Password"
                validation={this.state.validation.confirm_password}
              >
                <div className="">
                  <input
                    placeholder="Confirm password"
                    className="bp5-input bp5-fill bp5-intent-primary"
                    type="password"
                    value={this.state.confirm_password}
                    onChange={(e) =>
                      this.handleStateChange(e, 'confirm_password')
                    }
                    dir="auto"
                  />
                </div>
              </Cellbox>
            </div>
          </div>
        </div>

        <div className="pure-u-1" style={{ paddingTop: 16 }}>
          <div className="flexcenter">
            <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
              <button
                onClick={() => this.handleSubmit()}
                className="bp5-button bp5-fill bp5-intent-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    passwordReset: (password, token, onSuccess, onFail) => {
      dispatch(passwordReset(password, token, onSuccess, onFail));
    },
    userUpdate: (
      id,
      first_name,
      last_name,
      current_password,
      new_password,
      onSuccess,
      onFail
    ) => {
      dispatch(
        userUpdate(
          id,
          first_name,
          last_name,
          null,
          null,
          null,
          current_password,
          new_password,
          null,
          null,
          onSuccess,
          onFail
        )
      );
    },
    logout: (onSuccess) => {
      dispatch(logout(onSuccess));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TemporaryPasswordReset));
