import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import testSaga from './testSaga';
import userSaga from './userSaga';
import questionSetSaga from './questionSetSaga';
import departmentSaga from './departmentSaga';
import restaurantSaga from './restaurantSaga';
import questionSaga from './questionSaga';
import reportSaga from './reportSaga';
import utilSaga from './utilSaga';
import authSaga from './authSaga';
import { genericSaga, genericGet } from './genericSaga';

export default function* rootSaga() {
  yield takeLatest(actionTypes.TEST_CONNECTION, testSaga.testConnection);

  //auth
  yield takeLatest(actionTypes.GET_CSRF, authSaga.getCSRF);
  yield takeLatest(actionTypes.REGISTER, authSaga.register);
  yield takeLatest(actionTypes.LOGIN, authSaga.login);
  yield takeLatest(actionTypes.LOGOUT, authSaga.logout);
  yield takeLatest(
    actionTypes.PASSWORD_RESET_REQUEST,
    authSaga.passwordResetRequest
  );
  yield takeLatest(actionTypes.PASSWORD_RESET, authSaga.passwordReset);
  yield takeLatest(actionTypes.VALIDATE_TOKEN, authSaga.validateToken);
  yield takeLatest(actionTypes.ROLE_FETCH, authSaga.roleFetch);

  //user
  yield takeEvery(actionTypes.USER_FETCH, userSaga.userFetch);
  yield takeLatest(actionTypes.USER_UPDATE, userSaga.userUpdate);
  yield takeLatest(actionTypes.USER_CREATE, userSaga.userCreate);
  // yield takeLatest(actionTypes.USER_GET_DATA, userSaga.userGetData);

  yield takeLatest(actionTypes.USER_GET_DATA, (action) =>
    genericSaga.genericHandler(action, (data) =>
      genericGet(data, '/v1/user/get_data')
    )
  );

  yield takeLatest(actionTypes.USER_GET_DATA_EXTENDED, (action) =>
    genericSaga.genericHandler(action, (data) =>
      genericGet(data, '/v1/user/get_data_extended')
    )
  );

  yield takeLatest(actionTypes.GET_DASHBOARD_DATA, (action) =>
    genericSaga.genericHandler(action, (data) =>
      genericGet(data, '/v1/reportadmin/get_dashboard_data')
    )
  );
  // yield takeLatest(actionTypes.USER_GET_DATA, (action) =>
  // {
  //   console.log("in the yield?")
  //   genericSaga.genericHandler(action, (data) => genericGet(data, "/v1/user/get_data"))
  // }

  // );

  //question set
  yield takeLatest(
    actionTypes.QUESTION_SET_FETCH,
    questionSetSaga.questionSetFetch
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_CREATE,
    questionSetSaga.questionSetCreate
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_UPDATE,
    questionSetSaga.questionSetUpdate
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_UPLOAD_FILE,
    questionSetSaga.questionSetUploadFile
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_GRADING,
    questionSetSaga.gradingQuestionSets
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_INSTANCES_CREATE,
    questionSetSaga.questionSetInstanceCreate
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_RESOURCE_MOVE_UP,
    questionSetSaga.questionSetResourceMoveUp
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_RESOURCE_MOVE_DOWN,
    questionSetSaga.questionSetResourceMoveDown
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_RESOURCE_DELETE,
    questionSetSaga.questionSetResourceDelete
  );
  yield takeLatest(
    actionTypes.QUESTION_SET_INSTANCE_FINISH_TIME,
    questionSetSaga.setFinishedTime
  );
  yield takeLatest(
    actionTypes.DELETE_QUESTION_SET_INSTANCE,
    questionSetSaga.deleteQuestionSetInstance
  );
  yield takeLatest(
    actionTypes.IS_QUESTION_SET_CLOSED,
    questionSetSaga.isQuestionSetClosed
  );

  //questions
  yield takeLatest(actionTypes.QUESTION_CREATE, questionSaga.questionCreate);
  yield takeLatest(actionTypes.QUESTION_UPDATE, questionSaga.questionUpdate);
  yield takeLatest(actionTypes.QUESTION_MOVE_UP, questionSaga.questionMoveUp);
  yield takeLatest(
    actionTypes.QUESTION_MOVE_DOWN,
    questionSaga.questionMoveDown
  );
  yield takeLatest(actionTypes.QUESTION_DELETE, questionSaga.questionDelete);
  yield takeLatest(
    actionTypes.QUESTION_TYPE_FETCH,
    questionSaga.questionTypeFetch
  );
  yield takeLatest(
    actionTypes.QUESTION_UPLOAD_FILE,
    questionSaga.questionUploadFile
  );
  yield takeLatest(
    actionTypes.QUESTION_REMOVE_FILE,
    questionSaga.questionRemoveFile
  );
  yield takeLatest(
    actionTypes.QUESTION_INSTANCE_UPSERT,
    questionSaga.questionInstanceUpsert
  );
  yield takeLatest(
    actionTypes.GET_QUESTION_SET_INSTANCES,
    questionSetSaga.getQuestionSetInstances
  );
  yield takeLatest(
    actionTypes.CLOSE_QUESTION_SET_INSTANCE,
    questionSaga.closeQuestionSetInstance
  );
  yield takeLatest(
    actionTypes.QUESTION_INSTANCE_GRADE,
    questionSaga.questionInstanceGrade
  );
  yield takeLatest(
    actionTypes.GET_QUESTION_INSTANCES_FROM_QSI,
    questionSaga.getQuestionInstancesFromQsi
  );

  //department
  yield takeLatest(
    actionTypes.DEPARTMENT_FETCH,
    departmentSaga.departmentFetch
  );
  yield takeLatest(
    actionTypes.DEPARTMENT_CREATE,
    departmentSaga.createDepartment
  );
  yield takeLatest(
    actionTypes.DEPARTMENT_UPDATE,
    departmentSaga.updateDepartment
  );

  //restaurant
  yield takeLatest(
    actionTypes.RESTAURANT_FETCH,
    restaurantSaga.restaurantFetch
  );

  // Reports for Admins
  yield takeLatest(actionTypes.REPORTS_VIEW_FETCH, reportSaga.fetchReports);
  yield takeLatest(
    actionTypes.REPORTS_OVERVIEW_FETCH,
    reportSaga.fetchReportOverview
  );
  yield takeLatest(
    actionTypes.REPORTS_OVERVIEW_RESULTS_FETCH,
    reportSaga.fetchReportOverviewResults
  );

  // Util
  yield takeLatest(actionTypes.BREADCRUMB_SET, utilSaga.breadcrumbSet);
}
