import { Intent, OverlayToaster, Position } from '@blueprintjs/core';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = OverlayToaster.create({
  className: 'recipe-toaster',
  position: Position.TOP_RIGHT,
});

export const toastSuccess = (message) => {
  return AppToaster.show({
    message,
    intent: Intent.SUCCESS,
    icon: 'tick',
  });
};
