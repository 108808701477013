import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Intent } from "@blueprintjs/core";
import { login, getCSRF } from "../../actions/authActions";
import { userHasRole, userHasRoleV2 } from '../utils/sharedUtils';
import { AppToaster } from '../common/AppToaster'
import _ from 'lodash';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const user = useSelector((state) => state.data.user);
    const roles = useSelector((state) => state.data.roles);
    const toaster = useSelector((state) => state.data.toaster);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const onSuccess = (user) => handleSuccess(user);
        const onFail = () => handleFail();
        dispatch(getCSRF(() => dispatch(login(email, password, null, onSuccess, onFail))));
    }

    const handleSuccess = (user) => {

        AppToaster.show({
            message: "Login Success.",
            intent: Intent.SUCCESS,
            icon: 'tick'
        })

        // Role verification and redirection
        if (user.temporary_password) {
            navigate('/temporarypasswordreset/');
        } else {
            if (user.roles.length > 0) {
                if (userHasRoleV2('sysadmin', user.roles)) {
                    navigate('/admindashboard');
                } else if (userHasRoleV2('admin', user.roles)) {
                    navigate('/admindashboard');
                } else if (userHasRoleV2('manager', user.roles)) {
                    navigate('/managerdashboard');
                } else if (userHasRoleV2('employee', user.roles)) {
                    navigate('/dashboard');
                }
            } else {
                navigate('/dashboard');
            }
        }
    }

    const handlePasswordReset = () => {
        navigate('/passwordresetrequest');
    }

    const handleFail = () => {
        AppToaster.show({ message: 'Login Attempt Failed', intent: Intent.DANGER, icon: 'warning-sign'})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flexcenter" style={{ paddingTop: 16, fontSize: 24 }}>
                Welcome back
            </div>
            <div className="pure-g">
                <div className="pure-u-1" style={{ paddingTop: 16 }}>
                    <div className="flexcenter">
                        <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
                            <input
                                placeholder="email"
                                className="bp5-input bp5-fill bp5-intent-primary"
                                type="text"
                                value={email}
                                onChange={handleEmailChange}
                                dir="auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pure-g">
                <div className="pure-u-1" style={{ paddingTop: 16 }}>
                    <div className="flexcenter">
                        <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
                            <input
                                placeholder="password"
                                className="bp5-input bp5-fill bp5-intent-primary"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                dir="auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pure-g">
                <div className="pure-u-1" style={{ paddingTop: 16 }}>
                    <div className="flexcenter">
                        <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
                            <button type="submit" className="bp5-button bp5-fill bp5-intent-primary">Log in</button>
                        </div>
                    </div>                        
                </div>
            </div>
            <div className="pure-g">
                <div className="pure-u-1" style={{ paddingTop: 16 }}>
                    <div className="flexcenter">
                        <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
                            <a onClick={() => navigate('/register')}>Register</a>
                            <br /><br />
                            <a onClick={handlePasswordReset}>Forgot your password?</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Login;
