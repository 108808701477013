import React, { Component } from 'react';
import { connect } from "react-redux";
import PureGridBox24th from '../utils/PureGridBox24th';
import _ from 'lodash';
import { arrayFromKeyedObject } from '../utils/sharedUtils';
import { getQuestionInstancesFromQsi } from '../../actions/questionActions';
import withRouter from '../common/withRouter';
class QuestionInstances extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionInstances: [],
      questions: []
    }
  }

  componentDidMount() {
    this.props.getQuestionInstancesFromQsi(this.props.router.params.questionSetInstanceId, this.handleSuccess, this.handleError);
  }

  handleSuccess = () => {
    this.setState({
      questionInstances: this.props.questionInstances,
      questions: this.props.questions
    }, () => {
      this.gettingScoresFromQuestions();
    });
  }

  getRenderedQuestionInstance = (questionInstance, index) => {
    return (
      <div className="row" key={index}>
        <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          {questionInstance.question_text}
        </div>
        <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          {questionInstance.flex.answer}
        </div>
        <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          {questionInstance.score}
        </div>
        <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          {questionInstance.max_score}
        </div>
        <hr />
      </div>
    );
  }
  /********************************************************************
  * Assigning the scores (Passed or Failed) to each question instance *
  *********************************************************************/
  gettingScoresFromQuestions = () => {
    let renderedQuestionInstances = [];

    _.forEach(arrayFromKeyedObject(this.state.questionInstances), (qi, index) => {
      let question = _.find(this.state.questions, ['id', qi.question]);
      let type_question = _.get(qi, 'flex.type', null);
      qi.max_score = _.get(question, 'point_value');
      qi.question_text = _.get(question, 'question_text');
      // The type question is an Essay
      if (type_question === 'essay') {
        qi.score = (qi.correct_status * question.point_value) / 100;
        qi.type = 'essay';
      }
      else if (type_question === 'true_false' || type_question === 'multiple_choice') { // Otherwise, is other type of question
        if (qi.correct_status === 100) { // The answer is correct.
          qi.score = _.get(question, 'point_value');
        }
        else {
          qi.score = 0; // the answer is wrong
        }
      }
      let renderedQuestionInstance = this.getRenderedQuestionInstance(qi, index);
      renderedQuestionInstances.push(renderedQuestionInstance);
    })
    
    return (
      <div>
        <div className="row">
          <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}><b>Question</b></div>
          <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}><b>Answer</b></div>
          <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}><b>Score</b></div>
          <div className="pure-u-1-4" style={{ paddingLeft: "15px", paddingRight: "15px" }}><b>Max Score</b></div>
          <div className="5-24"></div>
        </div>
        <div className="row">
          {renderedQuestionInstances}
        </div>
      </div>
    )
  }

  render() {
    let renderQuestionInstances = this.gettingScoresFromQuestions();

    return (
      <PureGridBox24th>
        <div className="pure-u-1-6" />
        <div className="pure-u-5-8">
          <div>
            <h2>Question Instances<br />
            User: {this.props.user.email} <br />
            Test: {this.props.questionSet.name}
            </h2>
          </div>
          <hr />
          {renderQuestionInstances}
        </div>
      </PureGridBox24th>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  let questionIds = _.map(state.data.questionInstances, 'question');
  let questions = _.filter(arrayFromKeyedObject(state.data.questions), (question) => { return questionIds.indexOf(question.id) > -1 });
  let questionInstances = _.filter(arrayFromKeyedObject(state.data.questionInstances), {'question_set_instance' : Number(ownProps.router.params.questionSetInstanceId)})
  
  let questionSetInstance = state.data.questionSetInstances[ownProps.router.params.questionSetInstanceId]
  let questionSet = {}
  let user = {}
  if (questionSetInstance){
    questionSet = state.data.questionSets[questionSetInstance.question_set]
    user = state.data.users[questionSetInstance.user]
  }
  return {
    questionInstances: questionInstances,
    questionSetInstances: state.data.questionSetInstances,
    questions: questions,
    questionSet: questionSet,
    user: user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuestionInstancesFromQsi: (question_set_instance, onSuccess, onFail) => {
      dispatch(getQuestionInstancesFromQsi(question_set_instance, onSuccess, onFail));
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionInstances));