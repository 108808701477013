import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { userFetch } from '../actions/userActions'
import { breadcrumbSet } from '../actions/utilActions';
import { questionSetFetch } from '../actions/questionSetActions';
import { departmentFetch } from '../actions/departmentActions';
import { restaurantFetch } from '../actions/restaurantActions';
import { questionTypeFetch } from '../actions/questionActions';

const WithAdminData = ({ children }) => {
  const dispatch = useDispatch();

  // State to track the completion of each fetch
  const [fetchStatus, setFetchStatus] = useState({
    userFetch: false,
    departmentFetch: false,
    questionTypeFetch: false,
    restaurantFetch: false,
    questionSetFetch: false,
    breadcrumbSet: true, // Breadcrumb doesn't need async tracking
  });

  // Function to update the fetch status
  const updateFetchStatus = (key) => {
    setFetchStatus((prevStatus) => ({
      ...prevStatus,
      [key]: true,
    }));
  };

  useEffect(() => {
    // Dispatch actions and update fetch status on success
    dispatch(userFetch(() => updateFetchStatus('userFetch')));
    dispatch(departmentFetch(() => updateFetchStatus('departmentFetch')));
    dispatch(questionTypeFetch(() => updateFetchStatus('questionTypeFetch')));
    dispatch(restaurantFetch(() => updateFetchStatus('restaurantFetch')));
    dispatch(questionSetFetch(true, [], [], () => updateFetchStatus('questionSetFetch')));
  }, [dispatch]);

  // Check if all fetch operations are complete
  const allDataFetched = Object.values(fetchStatus).every((status) => status);

  if (!allDataFetched) {
    return (
      <div>
        <br />
        <br />
        <br />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spinner size={50} />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default WithAdminData;
