import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FileInput } from '@blueprintjs/core'; //MenuDivider
import PureGridBox24th from '../utils/PureGridBox24th';
import { validate } from '../utils/validate';
import Cellbox from '../utils/Cellbox';
import MultiSelectWrapper from '../utils/MultiSelectWrapper';
import {
  questionSetUpdate,
  questionSetCreate,
  questionSetUploadFile,
  questionResourceMoveUp,
  questionResourceMoveDown,
  questionSetResourceDelete,
} from '../../actions/questionSetActions';
import {
  questionMoveUp,
  questionMoveDown,
  questionDelete,
} from '../../actions/questionActions';
import { breadcrumbSet } from '../../actions/utilActions';
import { Intent, Checkbox } from '@blueprintjs/core';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  arrayFromKeyedObject,
  arrayFromKeys,
  getRandomStr,
  getDashboard,
} from '../utils/sharedUtils';
import SingleUpload from '../utils/SingleUpload';
import { Table, Popconfirm, Tooltip } from 'antd';
import _ from 'lodash';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';
class UpsertQuestionSet extends Component {
  constructor(props) {
    super(props);

    let id = null;
    let editMode = false;
    let name = '';
    let description = '';
    let departments = [];
    let restaurants = [];
    let images = [];
    let active = true;
    let time = 30;
    this._addedResources = [];
    this._allResources = [];
    let showTimeInput = false;
    let passing_score = 75;

    if (this.props.router.params.questionSetId) {
      id = this.props.router.params.questionSetId;
      editMode = true;
      let questionSet = this.props.questionSets[id];
      name = questionSet.name;
      description = questionSet.description;
      departments = arrayFromKeys(
        this.props.departments,
        questionSet.departments
      );
      restaurants = arrayFromKeys(
        this.props.restaurants,
        questionSet.restaurants
      );
      active = questionSet.active;
      time = parseInt(questionSet.time, 10) / 60;
      if (time && time !== 0) {
        showTimeInput = true;
      }
      passing_score = questionSet.passing_score || 75;
    }

    this.state = {
      id: Number(id),
      name: name,
      description: description,
      departments: departments,
      restaurants: restaurants,
      images: images,
      active: active,
      editMode: editMode,
      validation: {
        name: { rules: [{ name: 'isString' }], isValid: true, errors: [] },
        description: {
          rules: [{ name: 'isString' }],
          isValid: true,
          errors: [],
        },
        departments: {
          rules: [{ name: 'isRequired' }],
          isValid: true,
          errors: [],
        },
        restaurants: {
          rules: [{ name: 'isRequired' }],
          isValid: true,
          errors: [],
        },
        time: {
          rules: [{ name: 'isInt' }, { name: 'greaterThan', val: 0 }],
          isValid: true,
          errors: [],
        },
        passing_score: {
          rules: [
            { name: 'isInt' },
            { name: 'greaterThan', val: 0 },
            { name: 'lessThanOrEqualTo', val: 100 },
          ],
          isValid: true,
          errors: [],
        },
      },
      showTimeInput: showTimeInput,
      time: time,
      timeLimit: null,
      sortedResources: [],
      passing_score: passing_score,
    };
  }

  componentWillMount() {
    if (this.state.time !== 0) {
      this.setState({
        timeLimit: true,
      });
    } else {
      this.setState({
        timeLimit: false,
      });
    }
    this.props.breadcrumbSet([
      { name: 'Tests', active: true, link: '/tests' },
      {
        name: `${this.state.editMode ? 'Edit' : 'New'} test`,
        active: false,
        link: null,
      },
    ]);
  }

  componentDidMount() {
    this.setupResources();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps && _.get(this.state, 'sortedResources', []).length > (_.get(this.props, 'questionSets['+this.state.id+'].resources', []).length+this._addedResources.length)){
    //   console.log("setting up resources again")
    //   this.setupResources()
    // }
    if (
      Object.keys(prevProps.resources).length !==
      Object.keys(this.props.resources).length
    ) {
      this.setupResources();
    }
  }

  setupResources = () => {
    let resources = [];
    let sortedResources = [];
    if (
      _.has(
        this.props,
        'questionSets[' + this.state.id + '].questionSetResources'
      ) &&
      this.props.questionSets[this.state.id].questionSetResources.length > 0
    ) {
      resources = this.props.questionSets[
        this.state.id
      ].questionSetResources.map((qrId) => {
        let questionResource = this.props.questionSetResources[qrId];
        let resource = this.props.resources[questionResource.resource];
        resource.rank = questionResource.rank;
        resource.question_set_resource_id = qrId;
        return resource;
      });
    }

    let allResources = resources.concat(this._addedResources);
    this._allResources = allResources;
    sortedResources = _.sortBy(allResources, function (res) {
      return res.rank;
    });

    this.setState({
      sortedResources: sortedResources,
    });
  };

  renderResources = () => {
    let sortedResources = this.state.sortedResources;
    let renderedResources = sortedResources.map((res, index) => {
      let arrayIndex = res.index !== undefined ? res.index : null;
      let newIndex = res.id;
      return (
        <div key={newIndex}>
          <SingleUpload
            file={res}
            id={res.question_set_resource_id}
            index={index}
            arrayIndex={arrayIndex}
            action={this.handleUpload}
            textField={true}
            textFieldName="Name"
            handleRank={true}
            handleRankUp={this.handleRankUp}
            handleRankDown={this.handleRankDown}
            handleRemove={this.handleRemove}
            onSuccess={this.setupResources}
          />
        </div>
      );
    });

    return renderedResources;
  };

  addNewImage = () => {
    let images = this.state.images;
    images.push({
      name: '',
      loaded: false,
    });
    this.setState({
      images: images,
    });
  };

  addNewResource = () => {
    let index = getRandomStr();
    this._addedResources.push({
      status: 'new',
      index: index,
      asset: {
        rank: index,
      },
      id: index,
    });
    this.setupResources();
  };

  handleFail = (errors) => {
    if (!errors) {
      return null;
    }
    let errorStr = '';
    if (errors.length > 0) {
      _.forEach(errors, (error) => {
        errorStr += error.detail;
        errorStr += ' ';
      });
      AppToaster.show({
        message: errorStr,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      AppToaster.show({
        message: 'An unexpected error has occured',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    }
  };

  // CellBox = (label, validation, contents) => {
  //   return (
  //     <div className="pure-u-1 pure-u-md-12-24 pure-u-xl-6-24" style={{ textAlign: 'left' }}>
  //       <span>{label}</span><br />
  //       {contents}<br />
  //       {validation.isValid === false ? <span style={{ color: 'red' }}>{errorsToString(validation.errors)}</span> : null}
  //     </div>
  //   )
  // }

  handleStateChange(event, attribute) {
    let val = event.target.value;
    if (attribute === 'passing_score') {
      val = Number(val);
    }
    this.setState({
      [attribute]: val,
    });
  }

  handleSuccess = (response) => {
    AppToaster.show({
      message: 'Test Updated Successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
    if (this.state.editMode) {
      let dashboard = getDashboard(this.props.user, this.props.roles);
      this.props.router.navigate(dashboard);
    } else {
      this.props.router.navigate('/test/' + response.questionSet.id);
    }
  };

  handleActiveChange = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  handleTimeChange = (e) => {
    this.setState(
      {
        time: Number(e.target.value),
      },
      () => {
        // If the time is different than 0 so the test has time
        // The checkbox should appear selected
        if (
          parseInt(this.state.time, 10) !== 0 &&
          this.state.time.toString() !== ''
        ) {
          this.setState({
            timeLimit: true,
          });
        } else {
          // Otherwise, it should appear without selecting
          this.setState({
            timeLimit: false,
          });
        }
      }
    );
  };

  // This method is used in order to show/hide time-limit.

  handleCheckLimit = () => {
    this.setState(
      {
        showTimeInput: !this.state.showTimeInput,
      },
      () => {
        // Checking if the time is different than 0 and is not empty
        if (
          parseInt(this.state.time, 10) !== 0 &&
          this.state.time.toString() !== ''
        ) {
          this.setState({
            timeLimit: true,
          });
        } else {
          // Otherwise, it should appear the time limit checkbox selected
          this.setState({
            timeLimit: false,
          });
        }
      }
    );
  };

  handleSubmit() {
    let validation = validate(this.state);
    if (!this.state.showTimeInput) {
      delete validation.time;
    }

    let invalidCount = 0;
    Object.keys(validation).forEach((key) => {
      if (!validation[key].isValid) {
        invalidCount++;
      }
    });

    if (invalidCount > 0) {
      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
      });
    } else {
      const onSuccess = (response) => this.handleSuccess(response);
      const onFail = (response) => this.handleFail(response);
      const timeLimitFinal = this.state.showTimeInput
        ? this.state.time * 60
        : 0;
      if (this.state.editMode === true) {
        this.props.questionSetUpdate(
          this.state.id,
          this.state.name,
          this.state.active,
          this.state.description,
          _.map(this.state.departments, 'id'),
          _.map(this.state.restaurants, 'id'),
          timeLimitFinal,
          this.state.passing_score,
          onSuccess,
          onFail
        );
      } else {
        this.props.questionSetCreate(
          this.state.name,
          this.state.description,
          _.map(this.state.departments, 'id'),
          _.map(this.state.restaurants, 'id'),
          timeLimitFinal,
          this.state.passing_score,
          onSuccess,
          onFail
        );
      }
    }
  }

  getIntent(name) {
    if (this.state.validation[name].isValid) {
      return 'bp5-intent-primary';
    } else {
      return 'bp5-intent-warning';
    }
  }

  setDepartments = (departments) => {
    this.setState({
      departments: departments,
    });
  };

  setRestaurants = (restaurants) => {
    this.setState({
      restaurants: restaurants,
    });
  };

  moveQuestionUp = (recordId) => {
    let question = this.props.questions[recordId];
    if (question.rank === 0) {
      return;
    }
    this.props.questionMoveUp(
      recordId,
      () => {
        this.setState({});
      },
      (response) => this.handleFail(response)
    );
  };

  moveQuestionDown = (recordId) => {
    let question = this.props.questions[recordId];
    let questions = arrayFromKeys(
      this.props.questions,
      this.props.questionSets[this.state.id].questions
    );
    if (question.rank === questions.length - 1) {
      return;
    }
    this.props.questionMoveDown(
      recordId,
      () => {
        this.setState({});
      },
      (response) => this.handleFail(response)
    );
  };

  questionDelete = (recordId) => {
    this.props.questionDelete(
      recordId,
      () => {
        this.setState({});
      },
      () =>
        this.handleFail(
          'Question could not be deleted. Please ensure there are no open instances of this test.'
        )
    );
  };

  handleQuestionUploadSuccess = () => {
    AppToaster.show({
      message: 'The file has been uploaded successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  handleUpload = (request) => {
    const onSuccess = () => this.handleQuestionUploadSuccess();
    const onFail = () => this.handleFail('The file could not be uploaded');
    request.data.id = this.state.id;
    this.props.questionSetUploadFile(request, onSuccess, onFail);

    if (this._addedResources.length > 0) {
      let index = _.findIndex(this._addedResources, {
        id: request.data.resourceId,
      });
      if (index > -1) {
        this._addedResources.splice(index, 1);
      }
    }
    //this.setupResources()
  };

  handleRemove = (questionResourceId, removeFromServer) => {
    const onSuccess = (response) => this.handleQuestionSetSuccess(response);
    const onFail = (response) => this.handleQuestionSetFail(response);
    if (removeFromServer) {
      if (questionResourceId !== undefined && questionResourceId !== null) {
        this.props.questionSetResourceDelete(
          questionResourceId,
          onSuccess,
          onFail
        );
      }
    } else {
      if (this._addedResources.length > 0) {
        let index = _.findIndex(this._addedResources, {
          id: questionResourceId,
        });
        if (index > -1) {
          this._addedResources.splice(index, 1);
        }
      }
      this.setupResources();
    }
  };

  handleQuestionSetSuccess = () => {
    AppToaster.show({
      message: 'The file has been removed successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
    this.setState({});
  };

  handleQuestionSetFail = () => {
    AppToaster.show({
      message: 'The file could not be removed',
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
    this.setState({});
  };

  handleRankUp = (index) => {
    const onSuccess = (response) => this.handleRankSuccess(response);
    const onFail = (response) => this.handleQuestionUploadSuccess(response);
    let resource = this.state.sortedResources[index];
    if (resource.rank !== undefined && resource.rank > 0) {
      this.props.questionResourceMoveUp(
        resource.question_set_resource_id,
        onSuccess,
        onFail
      );
    }
  };

  handleRankDown = (index) => {
    const onSuccess = (response) => this.handleRankSuccess(response);
    const onFail = (response) => this.handleQuestionUploadSuccess(response);
    let resource = this.state.sortedResources[index];
    let questionSetResources = arrayFromKeyedObject(
      this.props.questionSetResources
    );
    let orderedQuestionSetResources = _(questionSetResources)
      .filter({ question_set: this.state.id })
      .orderBy(['rank'], ['desc'])
      .value();
    let lastItemRank = orderedQuestionSetResources[0]
      ? orderedQuestionSetResources[0].rank
      : null;

    if (
      resource.rank !== undefined &&
      lastItemRank.rank !== null &&
      resource.rank !== lastItemRank
    ) {
      this.props.questionResourceMoveDown(
        resource.question_set_resource_id,
        onSuccess,
        onFail
      );
    }
  };

  handleRankSuccess = (response) => {
    this.setupResources();
  };

  renderQuestions = () => {
    if (this.state.editMode) {
      let questionSet = this.props.questionSets[this.state.id];
      let questions = arrayFromKeys(
        this.props.questions,
        questionSet.questions
      );
      let questionsSorted = _.sortBy(questions, ['rank']);
      _.forEach(questionsSorted, (question) => {
        let questionType = _.find(this.props.questionTypes, {
          id: _.get(question, 'type', 0),
        });
        if (questionType) {
          question.type_name = questionType.name;
        }
      });
      let questionColumns = [
        {
          title: 'Question Text',
          dataIndex: 'question_text',
          sorter: (a, b) => {
            if (a.question_text.toLowerCase() > b.question_text.toLowerCase())
              return -1;
            if (a.question_text.toLowerCase() < b.question_text.toLowerCase())
              return 1;
            return 0;
          },
        },
        {
          title: 'Answer',
          dataIndex: 'flex',
          render: (flex, record) => {
            let answer = '-';
            if (record.type_name === 'True False') {
              answer =
                flex.true_false.answer === 'true'
                  ? flex.true_false.true_label
                  : flex.true_false.false_label;
            } else if (record.type === 48) {
              const mc = flex.multiple_choice;
              if (mc.answer >= 0 && mc.answer <= mc.options.length) {
                answer = mc.options[mc.answer]?.label ?? '-';
              }
            }
            if (answer.length > 60) {
              return (
                <Tooltip title={answer}>
                  {answer.length > 60
                    ? `${answer.substring(0, 60)}...`
                    : answer}
                </Tooltip>
              );
            } else {
              return answer;
            }
          },
        },
        {
          title: 'Type',
          dataIndex: 'type_name',
          sorter: (a, b) => {
            if (a.type_name.toLowerCase() > b.type_name.toLowerCase())
              return -1;
            if (a.type_name.toLowerCase() < b.type_name.toLowerCase()) return 1;
            return 0;
          },
        },
        {
          title: 'Point Value',
          dataIndex: 'point_value',
          sorter: (a, b) => {
            if (a.point_value > b.point_value) return -1;
            if (a.point_value < b.point_value) return 1;
            return 0;
          },
        },
        {
          title: 'Edit',
          dataIndex: 'edit',
          render: (text, record) => {
            return (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log('[UpsertQuestionSet] record: ', record);
                  console.log('[UpsertQuestionSet] questionSet: ', questionSet);
                  this.props.router.navigate(
                    '/test/' + this.state.id + '/question/' + record.id
                  );
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon="edit" />
              </span>
            );
          },
        },
        {
          title: 'Rank',
          dataIndex: 'rank',
          sorter: (a, b) => {
            if (a.rank > b.rank) return -1;
            if (a.rank < b.rank) return 1;
            return 0;
          },
          render: (text, record) => <span>{record.rank + 1}</span>,
        },
        {
          title: 'Order',
          dataIndex: 'order',
          render: (text, record) => (
            <div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.moveQuestionDown(record.id)}
              >
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  className="fa-lg"
                  icon={['far', 'arrow-circle-down']}
                />
              </span>
              <span />
              &nbsp;&nbsp;
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.moveQuestionUp(record.id)}
              >
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  className="fa-lg"
                  icon={['far', 'arrow-circle-up']}
                />
              </span>
              &nbsp;&nbsp;
              <Popconfirm
                placement="top"
                title="Are you sure you want delete this question? This operation cannot be undone."
                onConfirm={() => this.questionDelete(record.id)}
                cancelText="No"
              >
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  className="fa-lg"
                  icon={['far', 'times']}
                />
              </Popconfirm>
              {/* <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.questionDelete(record.id)}
              >
                <FontAwesomeIcon style={{cursor: 'pointer'}} className="fa-lg" icon={['far', 'times']} />
              </span> */}
            </div>
          ),
        },
      ];
      return (
        <div>
          <div style={{ textAlign: 'left' }}>
            <br />
            <h3>
              <b>Questions</b>
            </h3>
          </div>
          <hr />
          <div
            className="flexcenter"
            style={{ color: '#006666', position: 'relative' }}
          >
            <Table
              rowKey={(record) => record.id}
              columns={questionColumns}
              dataSource={questionsSorted}
              // title={() => <h2>Questions</h2>}
              bordered={true}
            />
          </div>
          <div
            className="flexcenter"
            style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
          >
            <Button
              onClick={() =>
                this.props.router.navigate(
                  '/test/' + this.state.id + '/question/new'
                )
              }
              intent="primary"
              large
            >
              Create a Question
            </Button>
          </div>
        </div>
      );
    }

    return '';
  };

  renderImages = (images) => {
    let imagesInput = images.map((images) => {
      return (
        <Cellbox label="Image">
          <FileInput
            text="Choose file..."
            onInputChange={(e) => this.fileInputChanged(e)}
          />
        </Cellbox>
      );
    });

    return (
      <div>
        <div
          className="flexcenter"
          style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
        >
          <Button onClick={() => this.addNewImage()} intent="primary" large>
            Add Image
          </Button>
        </div>
        {imagesInput}
      </div>
    );
  };

  render() {
    return (
      <div>
        {/* {this.props.resources[4] ? this.props.resources[4].name : ""}} */}
        {/* {this.props.someLength} */}
        <PureGridBox24th>
          <br />
          <div style={{ textAlign: 'left' }}>
            <br />
            <h3>
              <b>{this.state.name || 'Test Name'}</b>
            </h3>
          </div>
          {/* <div style={{ textAlign: 'center' }}>
          <h1>{this.state.name || "Test Name"}</h1>
        </div> */}
          <hr />
          <div style={{ color: 'black', textAlign: 'center', fontSize: 14 }}>
            <br />
            <Cellbox
              pure_xl="pure-u-xl-12-24"
              label="Name"
              validation={this.state.validation.name}
            >
              <input
                className={'bp5-input bp5-large ' + this.getIntent('name')}
                type="text"
                value={this.state.name}
                onChange={(e) => this.handleStateChange(e, 'name')}
              />
            </Cellbox>

            <Cellbox
              pure_xl="pure-u-xl-12-24"
              label="Description"
              validation={this.state.validation.description}
            >
              <input
                className={
                  'bp5-input bp5-large ' + this.getIntent('description')
                }
                type="text"
                value={this.state.description}
                onChange={(e) => this.handleStateChange(e, 'description')}
              />
            </Cellbox>

            <Cellbox
              pure_xl="pure-u-xl-12-24"
              label="Departments"
              validation={this.state.validation.departments}
            >
              <MultiSelectWrapper
                initialItems={this.state.departments}
                items={arrayFromKeyedObject(this.props.departments)}
                onChange={this.setDepartments}
              ></MultiSelectWrapper>
            </Cellbox>

            <Cellbox
              pure_xl="pure-u-xl-12-24"
              label="Restaurants"
              validation={this.state.validation.restaurants}
            >
              <MultiSelectWrapper
                initialItems={this.state.restaurants}
                items={arrayFromKeyedObject(this.props.restaurants)}
                onChange={this.setRestaurants}
              ></MultiSelectWrapper>
            </Cellbox>

            <Cellbox
              pure_override="pure-u-md-12-24"
              label=""
              validation={this.state.validation.active}
            >
              <Checkbox
                label="Active"
                checked={this.state.active}
                onChange={() => this.handleActiveChange()}
              />
            </Cellbox>

            {/* <Cellbox pure_md="pure-u-md-6-24" label="">

          </Cellbox> */}

            <Cellbox
              pure_override="pure-u-md-12-24"
              validation={this.state.validation.time}
              label=""
            >
              <Checkbox
                label="Time limit"
                checked={this.state.showTimeInput}
                onChange={() => this.handleCheckLimit()}
              />
              {this.state.showTimeInput ? (
                <input
                  className={'bp5-input bp5-large'}
                  type="number"
                  placeholder="Time limit"
                  maxLength={10}
                  step={1}
                  value={this.state.time}
                  onChange={(e) => this.handleTimeChange(e)}
                />
              ) : null}
            </Cellbox>

            <Cellbox
              pure_xl="pure-u-xl-24-24"
              label="Passing Score"
              validation={this.state.validation.passing_score}
            >
              <input
                type="number"
                step="1"
                className={'bp5-input bp5-large '}
                value={this.state.passing_score}
                onChange={(e) => this.handleStateChange(e, 'passing_score')}
              />
            </Cellbox>

            <div
              className="flexcenter"
              style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
            >
              <Button
                onClick={() => this.handleSubmit()}
                intent="primary"
                large
              >
                Submit
              </Button>
            </div>

            {this.renderQuestions()}

            {this.state.editMode ? (
              <div className="pure-u-1">
                <div style={{ textAlign: 'left' }}>
                  <br />
                  <h3>
                    <b>Study Materials</b>
                  </h3>
                </div>
                <hr />
                <div>
                  <div className="row">
                    <div
                      className="pure-u-1"
                      style={{ marginBottom: '15px', textAlign: 'left' }}
                    >
                      <button
                        type="button"
                        onClick={this.addNewResource}
                        className="bp5-intent-primary bp5-button bp5-icon-add"
                      >
                        Add Material
                      </button>
                    </div>
                  </div>
                  <div className="pure-u-1">
                    <div className="pure-u-7-24">
                      <span>Name</span>
                    </div>
                    <div className="pure-u-7-24">
                      <span>File</span>
                    </div>
                    <div className="pure-u-4-24">
                      <span>Preview</span>
                    </div>
                    <div className="pure-u-2-24">
                      <span>Actions</span>
                    </div>
                    {/* <div className="pure-u-2-24">
                  <span>Upload</span>
                </div> */}
                    <div className="pure-u-4-24">&nbsp;</div>
                  </div>
                  <hr />
                  {this.renderResources()}
                </div>
              </div>
            ) : null}
          </div>
        </PureGridBox24th>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questionSetResources: state.data.questionSetResources,
    questionSets: state.data.questionSets,
    departments: state.data.departments,
    restaurants: state.data.restaurants,
    questionTypes: state.data.questionTypes,
    questions: state.data.questions,
    resources: state.data.resources,
    toaster: state.data.toaster,
    roles: state.data.roles,
    user: state.data.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    questionSetCreate: (
      name,
      description,
      departments,
      restaurants,
      time,
      passing_score,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionSetCreate(
          name,
          description,
          departments,
          restaurants,
          time,
          passing_score,
          onSuccess,
          onFail
        )
      );
    },
    questionSetUpdate: (
      id,
      name,
      active,
      description,
      departments,
      restaurants,
      time,
      passing_score,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionSetUpdate(
          id,
          name,
          active,
          description,
          departments,
          restaurants,
          time,
          passing_score,
          onSuccess,
          onFail
        )
      );
    },
    questionMoveUp: (questionId, onSuccess, onFail) => {
      dispatch(questionMoveUp(questionId, onSuccess, onFail));
    },
    questionMoveDown: (questionId, onSuccess, onFail) => {
      dispatch(questionMoveDown(questionId, onSuccess, onFail));
    },
    questionSetUploadFile: (request, onSuccess, onFail) => {
      dispatch(questionSetUploadFile(request, onSuccess, onFail));
    },
    questionResourceMoveUp: (questionSetResourceId, onSuccess, onFail) => {
      dispatch(
        questionResourceMoveUp(questionSetResourceId, onSuccess, onFail)
      );
    },
    questionResourceMoveDown: (questionSetResourceId, onSuccess, onFail) => {
      dispatch(
        questionResourceMoveDown(questionSetResourceId, onSuccess, onFail)
      );
    },
    questionSetResourceDelete: (questionSetResourceId, onSuccess, onFail) => {
      dispatch(
        questionSetResourceDelete(questionSetResourceId, onSuccess, onFail)
      );
    },
    questionDelete: (questionId, onSuccess, onFail) => {
      dispatch(questionDelete(questionId, onSuccess, onFail));
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpsertQuestionSet));
