import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { arrayFromKeyedObject, arrayFromKeys } from '../utils/sharedUtils';
import PureGridBox24th from '../utils/PureGridBox24th';
import Cellbox from '../utils/Cellbox';
import { breadcrumbSet } from '../../actions/utilActions';
import withRouter from '../common/withRouter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userGetDataExtended } from '../../actions/userActions';
import { Table } from 'antd';
import { yyyymmddhhmmss } from '../../utils/DateHelpers';

const ViewUser = (props) => {
  const dispatch = useDispatch();


  // Accessing state using useSelector
  const users = useSelector(state => state.data.users);
  const departments = useSelector(state => state.data.departments);
  const restaurants = useSelector(state => state.data.restaurants);
  const roles = useSelector(state => state.data.roles);
  const question_set_instances = useSelector(state => Object.values(state.data.questionSetInstances));
  const all_state = useSelector(state => state);
  console.log("all_state", all_state)
  const { userId } = useParams();

  const [userData, setUserData] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    roles: [],
    departments: [],
    restaurants: []
  });

  useEffect(() => {
    if (userId) {
      const user = users[userId];
      if (user) {
        setUserData({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          departments: arrayFromKeys(departments, user.departments),
          restaurants: arrayFromKeys(restaurants, user.restaurants),
          roles: arrayFromKeys(roles, user.roles)
        });
      }

    }

    // Set breadcrumbs
    dispatch(breadcrumbSet([
      { name: 'dashboard', active: true, link: '/admindashboard' },
      { name: 'user', active: false, link: null }
    ]));
  }, [dispatch, userId, users, departments, restaurants, roles]);
  // Filter question set instances for the current user
  const userQuestionSetInstances = question_set_instances.filter(qsi => qsi.user === Number(userId));

  // Ant Design table columns
  const questionSetInstanceColumns = [
    {
      title: 'Question Set Id',
      dataIndex: 'question_set_id',
      render: (_, record) => record.question_set,
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      sorter: (a, b) => a.progress - b.progress,
    },
    {
      title: 'Closed',
      dataIndex: 'closed',
      render: (closed) => (closed ? 'Yes' : 'No'),
    },
    {
      title: 'Date Closed',
      dataIndex: 'date_closed',
      render: (date_closed) => date_closed ? yyyymmddhhmmss(new Date(Number(date_closed))) : 'N/A',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      render: (end_time) => end_time ? yyyymmddhhmmss(new Date(Number(end_time))) : 'N/A',
    },
    {
      title: 'Score',
      dataIndex: 'flex',
      render: (flex) => flex?.meta_data?.percent_score ? flex?.meta_data?.percent_score : 'N/A',
    }
  ];

  const tableData = userQuestionSetInstances.map((qsi) => ({
    id: qsi.id,
    question_set: qsi.question_set,
    progress: qsi.progress,
    closed: qsi.closed,
    date_closed: qsi.date_closed,
    end_time: qsi.end_time,
    flex: qsi.flex,
  }));

  useEffect(() => {
    dispatch(userGetDataExtended({ id: userId }));
  }, [])

  if (!userData.id) {
    return <div>Loading...</div>
  }

  console.log("question_set_instances", question_set_instances)

  return (
    <>
      <PureGridBox24th>
        <div style={{ color: 'black', textAlign: 'center', fontSize: 14, marginTop: 16 }}>
          <h2>User Details</h2>

          <Cellbox label="First name">
            <div>{userData.first_name}</div>
          </Cellbox>

          <Cellbox label="Last name">
            <div>{userData.last_name}</div>
          </Cellbox>

          <Cellbox label="Email">
            <div>{userData.email}</div>
          </Cellbox>

          <Cellbox label="Departments">
            <div>
              {userData.departments.length > 0 ? (
                userData.departments.map(department => (
                  <div key={department.id}>{department.name}</div>
                ))
              ) : (
                <div>No departments</div>
              )}
            </div>
          </Cellbox>

          <Cellbox label="Restaurants">
            <div>
              {userData.restaurants.length > 0 ? (
                userData.restaurants.map(restaurant => (
                  <div key={restaurant.id}>{restaurant.name}</div>
                ))
              ) : (
                <div>No restaurants</div>
              )}
            </div>
          </Cellbox>

          <Cellbox label="Role">
            <div>
              {userData.roles.length > 0 ? (
                userData.roles.map(role => (
                  <div key={role.id}>{role.name}</div>
                ))
              ) : (
                <div>No roles</div>
              )}
            </div>
          </Cellbox>
        </div>

      </PureGridBox24th>
      <PureGridBox24th>
        <Table
          rowKey="id"
          columns={questionSetInstanceColumns}
          dataSource={tableData}
          pagination={false}
        />
      </PureGridBox24th>
    </>
  );
};

export default withRouter(ViewUser);
