import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import AppContainer from './containers/AppContainer';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './config/store';

const container = document.getElementById('root'); // Get the root element

if (container) { // Ensure the container exists
  const root = createRoot(container); // Create a root

  root.render(
    <React.StrictMode> {/* Wrap in StrictMode for better checks */}
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </React.StrictMode>
  );

  registerServiceWorker();
} else {
  console.error('Root container not found'); // Log an error if the container doesn't exist
}