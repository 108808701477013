import React, { Component } from 'react';

class Test2 extends Component {
    render() {
        return (
            <div style={{ color: 'blue' }}>
                Testing Route 2
        </div>
        )
    }
}

export default Test2