import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';
import request from '../utils/api/ServerRequest';

const questionSetInstanceSchema = new schema.Entity('questionSetInstances');
const questionInstanceSchema = new schema.Entity('questionInstances');
async function fetchQuestionSets(payload) {
  try {
    return await request.get('/v1/questionset/fetch', {
      include_answers: payload.include_answers,
      restaurants: payload.restaurants,
      departments: payload.departments,
      userId: payload.userId,
    });
  } catch (e) {
    return e;
  }
}

async function createQuestionSet(payload) {
  try {
    return await request.post('/v1/questionset/create', {
      name: payload.name,
      description: payload.description,
      departments: payload.departments,
      restaurants: payload.restaurants,
      time: payload.time,
      passing_score: payload.passing_score,
    });
  } catch (e) {
    return e;
  }
}

async function getQuestionSetInstancesAPI(payload) {
  try {
    return await request.get(
      '/v1/questionsetinstance/get_question_set_instances',
      {
        id: payload.question_set, //optional
        get_question_instances: payload.get_question_instances,
      }
    );
  } catch (e) {
    return e;
  }
}

async function createQuestionSetInstanceAPI(payload) {
  try {
    return await request.put('/v1/questionsetinstance/create', {
      userId: payload.userId,
      questionSetId: payload.questionSetId,
    });
  } catch (e) {
    return e;
  }
}

async function updateQuestionSet(payload) {
  try {
    return await request.put('/v1/questionset/update', {
      id: payload.id,
      name: payload.name,
      active: payload.active,
      description: payload.description,
      departments: payload.departments,
      restaurants: payload.restaurants,
      time: payload.time,
      passing_score: payload.passing_score,
    });
  } catch (e) {
    return e;
  }
}

async function moveDownQuestionSetResource(payload) {
  try {
    return request.post(
      '/v1/questionset/move_down_question_set_resource',
      {
        questionSetResourceId: payload.questionSetResourceId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    return e;
  }
}

async function moveUpQuestionSetResource(payload) {
  try {
    return await request.post(
      '/v1/questionset/move_up_question_set_resource',
      {
        questionSetResourceId: payload.questionSetResourceId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    return e;
  }
}

async function deleteQuestionSetResource(payload) {
  try {
    return await request.delete(
      '/v1/questionset/delete_resource',
      {
        questionSetId: payload.questionSetId,
        questionSetResourceId: payload.questionSetResourceId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    return e;
  }
}

async function uploadQuestionSetFileAPI(payload) {
  try {
    let formData = new FormData();
    let textFieldValue = _.get(payload.request.data, 'textFieldValue', null);
    let resourceId = _.get(payload.request.data, 'resourceId', null);

    if (textFieldValue) {
      formData.append('textFieldValue', textFieldValue);
    }
    if (resourceId !== null) {
      formData.append('resourceId', resourceId);
    }

    formData.append('id', _.get(payload.request.data, 'id', null));
    formData.append('file', payload.request.file);
    return await request.post('/v1/questionset/upload_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        payload.request.onProgress({ percent: percentCompleted });
      },
      timeout: 150000,
    });
    // let response = await axios.post(cfg.server_url + '/v1/questionset/upload_file', formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'X-CSRF-Token': request.getOption('csrf')
    //   },
    //   onUploadProgress: function(progressEvent) {
    //     let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
    //     payload.request.onProgress({ percent: percentCompleted });
    //   }
    // });
  } catch (e) {
    return e;
  }
}

async function questionSetsGrading(payload) {
  try {
    return await request.post('/v1/questionsetinstance/grading_question_sets', {
      questionSetId: payload.questionSetId,
      closed: payload.closed,
      pendingGrading: payload.pendingGrading,
    });
  } catch (e) {
    return e;
  }
}

async function setFinishedTimeDb(payload) {
  try {
    return await request.put('/v1/questionsetinstance/update_finished_time', {
      end_time: payload.end_time,
      question_set_instance: payload.question_set_instance,
    });
  } catch (e) {
    return e;
  }
}

async function deleteQuestionSetInstanceAPI(payload) {
  try {
    return await request.put(
      '/v1/questionsetinstance/delete_question_set_instance',
      {
        question_set_instance: payload.question_set_instance,
      }
    );
  } catch (e) {
    return e;
  }
}
async function isQuestionSetClosedAPI(payload) {
  try {
    return await request.get('/v1/questionset/is_closed', {
      question_set_id: payload.questionSetId,
    });
  } catch (e) {
    return e;
  }
}

const questionSetSaga = {
  questionSetFetch: function* (action) {
    let success = false;
    try {
      const response = yield call(fetchQuestionSets, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'QUESTION_SET_FETCH_SUCCESS',
          payload: {
            questionSets: response.data.questionSets,
            resources: response.data.resources,
            questionSetResources: response.data.questionSetResources,
          },
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {}

    if (!success) {
      yield put({
        type: 'QUESTION_SET_FETCH_FAIL',
        payload: { questionSets: [], resources: [] },
      });
      if (action.onFail) action.onFail();
    }
  },

  questionSetCreate: function* (action) {
    try {
      const response = yield call(createQuestionSet, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_CREATE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_SET_CREATE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetInstanceCreate: function* (action) {
    try {
      const response = yield call(createQuestionSetInstanceAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_INSTANCES_CREATE_SUCCESS',
          normalized: normalize(response.data.question_set_instances, [
            questionSetInstanceSchema,
          ]),
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({
          type: 'QUESTION_SET_INSTANCES_CREATE_FAIL',
          payload: null,
        });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },

  setFinishedTime: function* (action) {
    try {
      const response = yield call(setFinishedTimeDb, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'SET_FINISHED_TIME_SUCCESS',
          normalized: normalize(
            response.data.questionSetInstance,
            questionSetInstanceSchema
          ),
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'SET_FINISHED_TIME_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetUpdate: function* (action) {
    try {
      const response = yield call(updateQuestionSet, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_UPDATE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_SET_UPDATE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  gradingQuestionSets: function* (action) {
    try {
      const response = yield call(questionSetsGrading, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_GRADING_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_SET_GRADING_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetUploadFile: function* (action) {
    try {
      const response = yield call(uploadQuestionSetFileAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_UPLOAD_FILE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
          action.payload.request.onFinished('success');
        }
      } else {
        yield put({ type: 'QUESTION_SET_UPLOAD_FILE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
          action.payload.request.onFinished('error');
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetResourceMoveUp: function* (action) {
    try {
      const response = yield call(moveUpQuestionSetResource, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_RESOURCE_MOVE_UP_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({
          type: 'QUESTION_SET_RESOURCE_MOVE_UP_FAIL',
          payload: null,
        });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetResourceMoveDown: function* (action) {
    try {
      const response = yield call(moveDownQuestionSetResource, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_RESOURCE_MOVE_DOWN_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({
          type: 'QUESTION_SET_RESOURCE_MOVE_DOWN_FAIL',
          payload: null,
        });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionSetResourceDelete: function* (action) {
    try {
      const response = yield call(deleteQuestionSetResource, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_SET_RESOURCE_DELETE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_SET_RESOURCE_DELETE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  getQuestionSetInstances: function* (action) {
    try {
      const response = yield call(getQuestionSetInstancesAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        //yield put({ type: "GET_QUESTION_INSTANCES_SUCCESS", payload: response.data });
        yield put({
          type: 'GET_QUESTION_SET_INSTANCES_SUCCESS',
          normalized: normalize(response.data.questionSetInstances, [
            questionSetInstanceSchema,
          ]),
        });
        yield put({
          type: 'GET_QUESTION_INSTANCES_SUCCESS',
          normalized: normalize(response.data.questionInstances, [
            questionInstanceSchema,
          ]),
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({ type: 'GET_QUESTION_SET_INSTANCES_FAIL', payload: null });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },

  deleteQuestionSetInstance: function* (action) {
    try {
      const response = yield call(deleteQuestionSetInstanceAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'DELETE_QUESTION_SET_INSTANCE_SUCCESS',
          removeEntityKeys: [
            {
              entity: 'questionSetInstances',
              entityKeys: [response.data.questionSetInstancesDeleted[0].id],
            },
            {
              entity: 'questionInstances',
              entityKeys: _.map(response.data.questionInstancesDeleted, 'id'),
            },
          ],
          payload: response.data,
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({ type: 'DELETE_QUESTION_SET_INSTANCE_FAIL', payload: null });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },

  isQuestionSetClosed: function* (action) {
    try {
      const response = yield call(isQuestionSetClosedAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'IS_QUESTION_SET_CLOSED_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) action.onSuccess(response.data.isClosed);
      } else {
        yield put({ type: 'IS_QUESTION_SET_CLOSED_FAIL', payload: null });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },
};

export default questionSetSaga;
