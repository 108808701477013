export const COLORS = {
  PRIMARY: '#7CAB2F',
  SECONDARY: '#7CAB2F',
  TERTIARY: '#00ffcc',
  QUATERNARY: '#00ff99',
  QUINARY: '#00cc99',
  SENARY: '#00cc66',
  SEPTENARY: '#00cc33',
  OCTONARY: '#00cc00',
  NONARY: '#00ff00',
  DENARY: '#33ff33',
  ELEVENARY: '#66ff66',
  DUODENARY: '#99ff99',
  TREDENARY: '#ccffcc',
  QUADENARY: '#ffffff',
}