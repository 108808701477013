import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { departmentFetch } from '../../actions/departmentActions';
import { breadcrumbSet } from '../../actions/utilActions';
import DepartmentTable from '../department/DepartmentTable';

const DepartmentPage = () => {
  const [state, setState] = useState({
    size: undefined,
    hasValue: undefined,
    intent: undefined,
    value: undefined,
  });
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.data.departments);

  useEffect(() => {
    dispatch(departmentFetch());
    dispatch(
      breadcrumbSet([
        { name: 'Departments', active: true, link: '/departments' },
      ])
    );
  }, [dispatch]);

  const { size, hasValue, intent, value } = state;

  if (!departments) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spinner intent={intent} size={size} value={hasValue ? value : null} />
      </div>
    );
  }

  return (
    <div>
      <div
        className="flexcenter"
        style={{ color: '#006666', position: 'relative' }}
      >
        <DepartmentTable />
      </div>
    </div>
  );
};

export default DepartmentPage;
