import * as ChartJs from 'chart.js';
import 'chartjs-adapter-date-fns'; // Register the date adapter for date-fns

ChartJs.Chart.register.apply(
  null,
  // @ts-ignore
  Object.values(ChartJs).filter((chartClass) => chartClass.id),
);

export default ChartJs;
