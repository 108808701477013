import {
  QUESTION_SET_FETCH,
  QUESTION_SET_CREATE,
  QUESTION_SET_UPDATE,
  QUESTION_SET_UPLOAD_FILE,
  QUESTION_SET_INSTANCES_CREATE,
  QUESTION_INSTANCE_UPSERT,
  QUESTION_SET_RESOURCE_MOVE_UP,
  QUESTION_SET_RESOURCE_MOVE_DOWN,
  QUESTION_SET_RESOURCE_DELETE,
  GET_QUESTION_SET_INSTANCES,
  QUESTION_SET_INSTANCE_FINISH_TIME,
  CLOSE_QUESTION_SET_INSTANCE,
  QUESTION_SET_GRADING,
  DELETE_QUESTION_SET_INSTANCE,
  IS_QUESTION_SET_CLOSED,
} from './actionTypes';

export const questionSetFetch = (
  include_answers,
  restaurants,
  departments,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_FETCH,
  payload: {
    include_answers: include_answers,
    restaurants: restaurants || [], //if empty array return all question sets
    departments: departments || [], //if empty array return all question sets
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionSetCreate = (
  name,
  description,
  departments,
  restaurants,
  time,
  passing_score,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_CREATE,
  payload: {
    name: name,
    description: description,
    departments: departments,
    restaurants: restaurants,
    time: time,
    passing_score: passing_score,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionSetUpdate = (
  id,
  name,
  active,
  description,
  departments,
  restaurants,
  time,
  passing_score,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_UPDATE,
  payload: {
    id: id,
    name: name,
    active: active,
    description: description,
    departments: departments,
    restaurants: restaurants,
    time: time,
    passing_score: passing_score,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionSetUploadFile = (request, onSuccess, onFail) => ({
  type: QUESTION_SET_UPLOAD_FILE,
  payload: {
    request: request,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const gradingQuestionSets = (
  questionSetId,
  closed,
  pendingGrading,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_GRADING,
  payload: {
    questionSetId: questionSetId,
    closed: closed,
    pendingGrading: pendingGrading,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionSetInstanceCreate = (
  userId,
  questionSetId,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_INSTANCES_CREATE,
  payload: {
    userId: userId,
    questionSetId: questionSetId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getQuestionSetInstances = (
  question_set,
  get_question_instances,
  onSuccess,
  onFail
) => ({
  type: GET_QUESTION_SET_INSTANCES,
  payload: {
    question_set: question_set, //optional
    get_question_instances: get_question_instances,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionInstanceUpsert = (
  question,
  user,
  flex,
  question_set,
  last_question,
  onSuccess,
  onFail
) => ({
  type: QUESTION_INSTANCE_UPSERT,
  payload: {
    question: question,
    user: user,
    flex: flex,
    question_set: question_set,
    last_question: last_question,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const setFinishedTime = (
  end_time,
  question_set_instance,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_INSTANCE_FINISH_TIME,
  payload: {
    end_time: end_time,
    question_set_instance: question_set_instance,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionResourceMoveUp = (
  questionSetResourceId,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_RESOURCE_MOVE_UP,
  payload: {
    questionSetResourceId: questionSetResourceId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionResourceMoveDown = (
  questionSetResourceId,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_RESOURCE_MOVE_DOWN,
  payload: {
    questionSetResourceId: questionSetResourceId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const questionSetResourceDelete = (
  questionSetResourceId,
  onSuccess,
  onFail
) => ({
  type: QUESTION_SET_RESOURCE_DELETE,
  payload: {
    questionSetResourceId: questionSetResourceId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const closeQuestionSetInstance = (
  question_set_instance,
  onSuccess,
  onFail
) => ({
  type: CLOSE_QUESTION_SET_INSTANCE,
  payload: {
    question_set_instance: question_set_instance,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteQuestionSetInstance = (
  question_set_instance,
  onSuccess,
  onFail
) => ({
  type: DELETE_QUESTION_SET_INSTANCE,
  payload: {
    question_set_instance: question_set_instance,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const isQuestionSetClosed = (questionSetId, onSuccess, onFail) => ({
  type: IS_QUESTION_SET_CLOSED,
  payload: {
    questionSetId: questionSetId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
