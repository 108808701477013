import React, { Component } from 'react';
import { Table, Input, Button } from 'antd';
import { Alert, Intent, Checkbox } from '@blueprintjs/core'; //Button, H5, IToaster, Switch,
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { connect } from 'react-redux';
import { questionSetUpdate } from '../../actions/questionSetActions';
import { userHasRole } from '../utils/sharedUtils';
import {
  arrayFromKeyedObject,
  commaSeperateArrayByField,
  arrayFromKeys,
} from '../utils/sharedUtils';
import faClone from '@fortawesome/fontawesome-pro-regular/faClone';
import HighlightText from '../utils/HighlightText';
import withRouter from '../common/withRouter';
import { SearchOutlined } from '@ant-design/icons';
import { AppToaster } from '../common/AppToaster';

class QuestionSetTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTextName: '',
      alertOpen: false,
      questionSetIndex: null,
      filteredInfo: {
        active: ['true'],
      },
    };
  }

  handleSearch = (key, selectedKeys, confirm) => () => {
    confirm();
    this.setState({ [key]: selectedKeys[0] });
  };

  handleReset = (key, clearFilters) => {
    clearFilters();
    this.setState({ [key]: '' });
  };

  handleUpdateCancel = () => {
    this.setState({
      alertOpen: false,
    });
  };

  handleUpdateConfirm = () => {
    this.setState({
      alertOpen: false,
    });
    const onSuccess = () => this.handleSuccess();
    const onFail = (response) => this.handleFail(response);
    // let record = this.props.questionSets[this.state.questionSetIndex];
    let record = this.props.questionSets.find(
      (q) => q.id === this.state.questionSetIndex
    );
    this.props.questionSetUpdate(
      record.id,
      record.name,
      !record.active,
      record.description,
      record.departments,
      record.restaurants,
      record.time,
      null,
      onSuccess,
      onFail
    );
  };

  handleActiveChange = (record) => {
    let questionSetIndex = record.id;
    this.setState({
      alertOpen: true,
      questionSetIndex: questionSetIndex,
    });
  };

  handleSuccess = (response) => {
    AppToaster.show({
      message: 'The status has been successfully changed',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  handleFail = (errors) => {
    if (!errors) {
      return null;
    }
    let errorStr = '';
    if (errors.length > 0) {
      _.forEach(errors, (error) => {
        errorStr += error.detail;
        errorStr += ' ';
      });
      AppToaster.show({
        message: errorStr,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      AppToaster.show({
        message: 'An unexpected error has occured',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize, // Keep track of the current page size
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  render() {
    let questionSetData = [];
    questionSetData = arrayFromKeyedObject(this.props.questionSets);

    questionSetData.map(
      (x) =>
        (x.restaurantsConcat = commaSeperateArrayByField(
          arrayFromKeys(this.props.restaurants, x.restaurants),
          'name'
        ))
    );
    questionSetData.map(
      (x) =>
        (x.departmentsConcat = commaSeperateArrayByField(
          arrayFromKeys(this.props.departments, x.departments),
          'name'
        ))
    );

    let adminOrSysAdmin =
      userHasRole('admin', this.props.user.roles, this.props.roles) ||
      userHasRole('sysadmin', this.props.user.roles, this.props.roles);
    if (adminOrSysAdmin === false) {
      questionSetData = questionSetData.filter((qs) => {
        let resExists = _.filter(
          qs.restaurants,
          (rs) => this.props.user.restaurants.indexOf(rs) > -1
        );
        let depExists = _.filter(
          qs.departments,
          (dt) => this.props.user.departments.indexOf(dt) > -1
        );
        return resExists.length > 0 && depExists.length > 0;
      });
    }

    const { alertOpen, questionSetIndex } = this.state;
    let record = this.props.questionSets.find(
      (q) => q.id === this.state.questionSetIndex
    );
    const qsName = record ? record.name : undefined;
    const qsActive = record ? record.active : undefined;
    let confirmButtonText = qsActive ? 'Deactivate' : 'Activate';
    let alertWord = confirmButtonText.toLowerCase();
    let alertText = (
      <p>
        Are you sure you want to {alertWord} <b>{qsName}?</b>
      </p>
    );

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    let questionSetColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset('searchTextName', clearFilters)}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextName } = this.state;
          return searchTextName ? (
            <HighlightText
              text={text}
              highlight={searchTextName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Departments',
        dataIndex: 'departmentsConcat',
        render: (text, record) => {
          return record.departmentsConcat;
        },
        filters: this.props.departmentFilters,
        onFilter: (value, record) => record.departmentsConcat.includes(value),
      },
      {
        title: 'Restaurants',
        dataIndex: 'restaurantsConcat',
        render: (text, record) => {
          return record.restaurantsConcat;
        },
        filters: this.props.restaurantFilters,
        onFilter: (value, record) => record.restaurantsConcat.includes(value),
      },
      {
        title: 'Active',
        dataIndex: 'active',
        filters: [
          {
            text: 'true',
            value: true,
          },
          {
            text: 'false',
            value: false,
          },
        ],
        onFilter: (value, record) => {
          return record.active === value;
        },
        // filteredValue: filteredInfo.active || null,
        render: (text, record) => {
          return (
            <span>
              <Checkbox
                checked={record.active}
                onChange={() => this.handleActiveChange(record)}
              />
            </span>
          );
        },
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        className: 'tests-table-edit-column',
        render: (text, record) => (
          <div>
            <span
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.router.navigate('/test/' + record.id);
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon="edit" />
            </span>
            {adminOrSysAdmin ? (
              <span
                style={{ cursor: 'pointer', marginLeft: 10 }}
                onClick={() => {
                  this.props.router.navigate(
                    '/test/' + record.id + '/question_set_instances'
                  );
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon={faClone} />
              </span>
            ) : null}
          </div>
        ),
      },
    ];

    return (
      <div>
<Table
  rowKey={(record) => record.id}
  columns={questionSetColumns}
  dataSource={questionSetData}
  title={() => <h2>Tests</h2>}
  bordered={true}
  onChange={this.handleChange}
  pagination={{
    current: this.state.currentPage || 1,
    pageSize: this.state.pageSize || 20, // Default page size
    total: questionSetData.length, // Total number of records
    showSizeChanger: true, // Enable the page size changer
    pageSizeOptions: ["5", "10", "20", "50"], // Available page size options
    onShowSizeChange: (current, size) => {
      this.setState({
        currentPage: current,
        pageSize: size, // Update page size
      });
    },
  }}
/>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText={confirmButtonText}
          icon="changes"
          intent={Intent.PRIMARY}
          isOpen={alertOpen}
          onCancel={this.handleUpdateCancel}
          onConfirm={this.handleUpdateConfirm}
        >
          {alertText}
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let questionSetsFiltered = [];
  let isManager = false;

  // Verify if the user already connected is a manager
  arrayFromKeyedObject(state.data.roles).forEach((role) => {
    if (role['name'] === 'manager') {
      if (state.data.user.roles[0] === role['id']) {
        isManager = true;
      }
    }
  });

  // If the user has the role of manager
  if (isManager) {
    arrayFromKeyedObject(state.data.questionSets).forEach((qs) => {
      qs.restaurants.forEach((restaurant) => {
        if (
          state.data.user.restaurants.join().indexOf(restaurant.toString()) !==
          -1
        ) {
          questionSetsFiltered.push(qs);
        }
      });
    });
  } else {
    questionSetsFiltered = arrayFromKeyedObject(state.data.questionSets || []);
  }

  // Delete users duplicated
  questionSetsFiltered = _.uniqBy(questionSetsFiltered, (qs) => {
    return qs.id;
  });

  let restaurantFilters = [];
  _.forOwn(state.data.restaurants, function (value, key) {
    restaurantFilters.push({ text: value.name, value: value.name });
  });

  let departmentFilters = [];
  _.forOwn(state.data.departments, function (value, key) {
    departmentFilters.push({ text: value.name, value: value.name });
  });
  return {
    user: state.data.user,
    roles: state.data.roles,
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    restaurantFilters: restaurantFilters,
    departmentFilters: departmentFilters,
    questionSets: questionSetsFiltered,
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    questionSetUpdate: (
      id,
      name,
      active,
      description,
      departments,
      restaurants,
      time,
      passingScore,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionSetUpdate(
          id,
          name,
          active,
          description,
          departments,
          restaurants,
          passingScore,
          time,
          onSuccess,
          onFail
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionSetTable));
