
import axios from 'axios';

export const objectToQuery = (obj, includeLeadingQuestion = true) => {
	let returnStr = '';
  if (includeLeadingQuestion){
	  returnStr += "?";
  }
  returnStr += Object.keys(obj)
  .map((key) => key + "=" + obj[key])
  .join("&")
  return returnStr;
};


export default class HttpRequest {

  constructor(opts) {
    this.ajax = axios.create({
      baseURL: opts.baseUrl,
      timeout: opts.timeout || 200000,
      withCredentials: true
    });
  }
  
  setDefaultOption(name, value) {
    switch (name) {
      case 'csrf':
        this.ajax.defaults.headers['X-CSRF-Token'] = value;
        break;
      case 'credentials':
        this.ajax.defaults.withCredentials = value;
        break;
      default:
        
      break;
    }
  }
  
  async get(url, params, options) {
    return await this.ajax({
      method: 'get',
      url,
      params,
      ...options
    });
  }
  
  async post(url, data, options) {
    return await this.ajax(url, {
      method: 'post',
      url,
      data,
      ...options
    });
  }

  async put(url, data, options) {
    return await this.ajax(url,  {
      method: 'put',
      url,
      data,
      ...options
    });
  }

  async delete(url, params, options) {
    return await this.ajax(url,  {
      method: 'delete',
      url,
      params,
      ...options
    });
  }
}