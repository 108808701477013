import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { arrayFromKeyedObject, safeToPercentStr } from '../utils/sharedUtils';
import Cellbox from '../utils/Cellbox';
import MultiSelectWrapper from '../utils/MultiSelectWrapper';
import { fetchReports } from '../../actions/reportsActions';
import {
  breadcrumbSet,
  setReportSelectedDepartments,
  setReportSelectedRestaurants,
} from '../../actions/utilActions';
import { Input, Button, Table } from 'antd';
import _ from 'lodash';
import { Intent, Spinner } from '@blueprintjs/core';
import HighlightText from '../utils/HighlightText';
import Spaner from '../utils/Spaner';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';
import Icon, { SearchOutlined } from '@ant-design/icons';
class ReportView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      departmentsSelected: [], // Departments selected by the admin in the multi select
      restaurantsSelected: [], // Restaurants selected by the admin in the multi select
      //departmentsRelated: [],   // Deparments related with the admin
      restaurantsRelated: [], // Restaturant related with the admin
      reportUsers: [], //arrayFromKeyedObject(this.props.reportUsers), // Users belonging to the dep/res selected by the admin
      reportQuestionSets: arrayFromKeyedObject(this.props.reportQuestionSets), // Question sets belonging to the dep/res selected by the admin
      cleanReport: false, // Variable to know if the table has to be displayed or not
      checked: false,
      reportRunning: false,
      searchTextFirstName: '',
      searchTextLastName: '',
      queryMode: true,
    };
  }

  componentDidMount() {
    // If we come from grading view, I have to generate the report
    if (_.get(this.props, 'location.state.fromGrading', null)) {
      if (
        this.props.reportSelectedDepartments.length > 0 &&
        this.props.reportSelectedRestaurants.length > 0
      ) {
        this.generateReport();
      }
    }

    this.updatingDepartments(this.props.user.departments);
    this.updatingRestaurants(this.props.user.restaurants);

    this.props.breadcrumbSet([
      { name: 'Test', active: true, link: '/tests' },
      { name: 'Report View', active: false, link: null },
    ]);
  }

  clearReport = () => {
    //used to calculate new view height
    this.departmentsInput.handleClear();
    this.restaurantsInput.handleClear();
    this.setState({
      reportUsers: [],
      queryMode: true,
      restaurantsSelected: [],
      departmentsSelected: [],
    });
  };

  preparingUserReports = () => {
    let newUsersFormatted = arrayFromKeyedObject(this.props.reportUsers);

    // Mapping all the user obtained
    newUsersFormatted.map((user, index) => {
      if (user.question_set_instances.length === 0) {
        for (let i = 0; i < this.state.reportQuestionSets.length; i++) {
          user[this.state.reportQuestionSets[i].name.toString()] = ''; // Set to x when the user hasn't taken test
        }
      } else {
        for (let i = 0; i < this.state.reportQuestionSets.length; i++) {
          let user_question_set_instance = _.filter(
            user.question_set_instances,
            { question_set: this.state.reportQuestionSets[i].id }
          );
          user_question_set_instance = _.orderBy(
            user_question_set_instance,
            'createdAt',
            'desc'
          )[0];
          if (user_question_set_instance) {
            if (user_question_set_instance.closed) {
              let pending_grading = _.get(
                user_question_set_instance,
                'flex.meta_data.pending_grading',
                false
              );
              user[this.state.reportQuestionSets[i].name.toString()] =
                pending_grading
                  ? 'PG'
                  : safeToPercentStr(
                      _.get(
                        user_question_set_instance,
                        'flex.meta_data.percent_score',
                        0
                      )
                    ) + ' %';
            } else {
              user[this.state.reportQuestionSets[i].name.toString()] = 'IP';
            }
          } else {
            user[this.state.reportQuestionSets[i].name.toString()] = '';
          }
        }
      }
      return user;
    });
    this.setState({
      reportUsers: newUsersFormatted,
    });
    return;
  };

  /**********************************************************
   * Method to update the departments who belongs the admin  *
   ***********************************************************/

  updatingDepartments = (departments) => {
    let _departmentsRelated = {};
    let position = 0;

    for (
      let index = 0;
      index < arrayFromKeyedObject(this.props.departments).length;
      index++
    ) {
      if (position > departments.length) break;

      if (
        parseInt(departments[position], 10) ===
        parseInt(arrayFromKeyedObject(this.props.departments)[index].id, 10)
      ) {
        let key = departments[position].toString();

        _departmentsRelated[key] = arrayFromKeyedObject(this.props.departments)[
          index
        ];
        position += 1;
        index = 0;
      }
    }

    this.setState({
      departmentsRelated: _departmentsRelated,
    });
  };

  /**********************************************************
   * Method to update the departments who belongs the admin  *
   ***********************************************************/

  updatingRestaurants = (restaurants) => {
    let _restaurantsRelated = [];
    let position = 0;

    for (
      let index = 0;
      index < arrayFromKeyedObject(this.props.restaurants).length;
      index++
    ) {
      if (position > restaurants.length) break;

      if (
        parseInt(restaurants[position], 10) ===
        parseInt(arrayFromKeyedObject(this.props.restaurants)[index].id, 10)
      ) {
        let key = restaurants[position].toString();

        _restaurantsRelated[key] = arrayFromKeyedObject(this.props.restaurants)[
          index
        ];
        position += 1;
        index = 0;
      }
    }

    this.setState({
      restaurantsRelated: _restaurantsRelated,
    });
  };

  /**************************************************
   * Setting in a new array the departments selected *
   ***************************************************/

  setDepartments = (departments) => {
    let _departments = [];
    // There is more than one tag selected
    if (departments.length !== 0) {
      // Cycle to format just the restaurant's name and including into the array
      for (let index = 0; index < departments.length; index++) {
        _departments.push(departments[index].name);
      }

      // Setting the state to the restaurants selected
      // this.setState({
      //   departmentsSelected: _departments
      // })
      this.props.setSelectedDepartments(_departments);
    } else {
      this.setState({
        departmentsSelected: [],
      });
      this.props.setSelectedDepartments([]);
    }
  };

  /**************************************************
   * Setting in a new array the restaurants selected *
   ***************************************************/

  setRestaurants = (restaurants) => {
    let _restaurants = [];
    //debugger;
    // There is more than one tag selected
    if (restaurants.length !== 0) {
      // Cycle to format just the restaurant's name and including into the array
      for (let index = 0; index < restaurants.length; index++) {
        _restaurants.push(restaurants[index].name);
      }

      // Setting the state to the restaurants selected
      this.setState({
        restaurantsSelected: _restaurants,
      });
    } else {
      this.setState({
        restaurantsSelected: [],
      });
    }
    this.props.setSelectedRestaurants(_restaurants);
  };

  /*********************************************************************
   * Function to send the tags to generate the report for Admins        *
   * Inputs ::: (departmentsArray, restaurantsArray, onSuccess, onFail) *
   **********************************************************************/

  generateReport = () => {
    let _departments = this.props.reportSelectedDepartments; // Array of departments selected
    let _restaurants = this.props.reportSelectedRestaurants; // Array of restaurants selected

    let _departmentsDispatch = [];
    let _restaurantsDispatch = [];

    if (_restaurants.length !== 0) {
      _restaurantsDispatch = _restaurants;
    }

    if (_departments.length !== 0) {
      _departmentsDispatch = _departments;
    }

    this.props.fetchReports(
      _departmentsDispatch,
      _restaurantsDispatch,
      () => this.handleReportSuccess(),
      () => this.handleReportFail()
    );

    this.setState({
      queryMode: false,
      reportRunning: true,
    });
  };

  handleReportSuccess = () => {
    this.setState(
      {
        reportRunning: false,
        reportQuestionSets: arrayFromKeyedObject(this.props.reportQuestionSets),
      },
      () => {
        this.setState({ cleanReport: true });
        this.preparingUserReports();
      }
    );
    AppToaster.show({
      message: 'Report Generated',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  handleReportFail = () => {
    AppToaster.show({
      message: 'Report Failed',
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
    this.setState({
      reportRunning: false,
    });
  };

  getNumberOfAttempts = (questionSetInstances, questionSetId) => {
    let number_of_attempts = 0;
    _.forEach(questionSetInstances, (qsi) => {
      if (qsi.question_set === questionSetId) {
        number_of_attempts += 1;
      }
    });
    return number_of_attempts;
  };

  handleSearch = (key, selectedKeys, confirm) => () => {
    confirm();
    this.setState({ [key]: selectedKeys[0] });
  };

  handleReset = (key, clearFilters) => {
    clearFilters();
    this.setState({ [key]: '' });
  };

  render() {
    let columnWidth = 150;
    let questionSetColumns = [
      {
        title: 'First',
        dataIndex: 'first_name',
        // specify the condition of filtering result
        sorter: (a, b) => {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
            return -1;
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="First name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextFirstName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.first_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        width: columnWidth,
        render: (text) => {
          const { searchTextFirstName } = this.state;
          return searchTextFirstName ? (
            <HighlightText
              text={text}
              highlight={searchTextFirstName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Last',
        dataIndex: 'last_name',
        // specify the condition of filtering result
        sorter: (a, b) => {
          if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return -1;
          if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return 1;
          return 0;
        },

        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Last name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextLastName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? '#108ee9' : '#aaa' }}
          />
        ),
        onFilter: (value, record) =>
          record.last_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        width: columnWidth,
        render: (text) => {
          const { searchTextLastName } = this.state;
          return searchTextLastName ? (
            <HighlightText
              text={text}
              highlight={searchTextLastName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
    ];

    for (let i = 0; i < this.state.reportQuestionSets.length; i++) {
      //console.log(this.state.reportQuestionSets);
      let element = {
        title: this.state.reportQuestionSets[i].name, // Column title, in this case the question set name
        dataIndex: this.state.reportQuestionSets[i].name, // Data index for the table
        width: columnWidth,
        render: (text, record) => {
          let numberOfAttempts = this.getNumberOfAttempts(
            record.question_set_instances,
            this.state.reportQuestionSets[i].id
          );
          if (text === 'PG') {
            // render pending grading
            return (
              <span
                onClick={() => {
                  this.props.router.navigate(
                    '/grading/' + this.state.reportQuestionSets[i].id
                  );
                }}
              >
                <a>PG</a>
                <p>Attempts: {numberOfAttempts}</p>
              </span>
            );
          } else if (text === 'X') {
            //render not applicable / started
            return (
              <span>
                X<p>Attempts: {numberOfAttempts}</p>
              </span>
            );
          } else if (text === 'IP') {
            // render in progress
            return (
              <span>
                IP
                <p>Attempts: {numberOfAttempts}</p>
              </span>
            );
          } else {
            // render the score
            let date = '';
            let question_set = null;
            let showGreenCheck = false;
            let highestQuestionInstance = null;
            let question_set_instance = _.filter(
              record.question_set_instances,
              (qsi) => {
                return qsi.question_set === this.state.reportQuestionSets[i].id;
              }
            );

            // Finding the best score within the question set instances
            question_set_instance.forEach((qsi, index) => {
              if (index === 0) {
                highestQuestionInstance = qsi;
              } else {
                let percentHighest =
                  highestQuestionInstance.flex.meta_data.percent_score;
                let currentQsi = _.get(qsi, 'flex.meta_data.percent_score', 0);
                if (currentQsi > percentHighest) {
                  highestQuestionInstance = qsi;
                }
              }
            });

            if (question_set_instance.length !== 0) {
              let question_set_instance_latest = _.maxBy(
                question_set_instance,
                'createdAt'
              ); // Find the latest question set instance by createdAt
              if (!question_set_instance_latest.date_closed) {
                date = new Date(parseInt(highestQuestionInstance.end_time, 10));
              } else {
                date = new Date(
                  parseInt(highestQuestionInstance.date_closed, 10)
                );
              }
              question_set = _.find(this.props.questionSets, [
                'id',
                this.state.reportQuestionSets[i].id,
              ]);
              showGreenCheck = false;

              // I make the comparison related with the best score obtained
              if (
                highestQuestionInstance.flex.meta_data.percent_score * 100 >=
                question_set.passing_score
              ) {
                showGreenCheck = true;
              }
            }

            return (
              <span>
                {highestQuestionInstance
                  ? safeToPercentStr(
                      highestQuestionInstance.flex.meta_data.percent_score
                    )
                  : text}{' '}
                &nbsp;
                {showGreenCheck ? (
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer', color: 'green' }}
                    className="fa-lg"
                    icon={['far', 'check-circle']}
                  />
                ) : null}
                {!showGreenCheck && numberOfAttempts !== 0 ? (
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer', color: 'red' }}
                    className="fa-lg"
                    icon={['far', 'times']}
                  />
                ) : null}
                <br></br>
                Attempts:{' '}
                <b>
                  {this.getNumberOfAttempts(
                    record.question_set_instances,
                    this.state.reportQuestionSets[i].id
                  )}
                </b>
                <br></br>
                Date closed:{' '}
                <b>{date !== '' ? date.toLocaleDateString() : null}</b>
                <br></br>
                Time closed:{' '}
                <b>{date !== '' ? date.toLocaleTimeString() : null}</b>
              </span>
            );
          }
        },
        // specify the condition of filtering result
      };
      questionSetColumns.push(element);
    }

    // let reportUsers = this.preparingUserReports();
    //2 fixed fields then dynamic
    let totalColumnsWidth =
      this.state.reportQuestionSets.length * columnWidth + 2;

    let queryDisplay = 'block';

    if (this.state.queryMode === false) {
      queryDisplay = 'none';
    }

    return (
      <div>
        <div style={{ textAlign: 'left' }}>
          <br />
          <h3>
            <b>{this.state.name || 'Admin Report'}</b>
            {!this.state.queryMode && !this.state.reportRunning ? (
              <span>
                <Spaner width="sm" />
                <button
                  className="bp5-button bp5-small bp5-intent-primary"
                  onClick={() => {
                    this.clearReport();
                  }}
                >
                  New Query
                </button>
              </span>
            ) : null}
          </h3>
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          		<h1>{this.state.name || "Admin report"}</h1>
        		</div> */}
        <hr />

        <div style={{ color: 'black', textAlign: 'center', fontSize: 14 }}>
          <br />

          <div style={{ display: queryDisplay }}>
            <Cellbox pure_override="pure-u-1-2" label="Departments">
              <MultiSelectWrapper
                ref={(e) => (this.departmentsInput = e)}
                initialItems={[]}
                items={arrayFromKeyedObject(this.props.departments)}
                onChange={this.setDepartments}
              ></MultiSelectWrapper>
            </Cellbox>

            <Cellbox pure_override="pure-u-1-2" label="Restaurants">
              <MultiSelectWrapper
                ref={(e) => (this.restaurantsInput = e)}
                initialItems={this.props.restaurantsRelated}
                items={arrayFromKeyedObject(this.state.restaurantsRelated)}
                onChange={this.setRestaurants}
              ></MultiSelectWrapper>
            </Cellbox>

            <div
              className="flexcenter"
              style={{ textAlign: 'left', width: '100%', marginTop: 0 }}
            >
              {this.state.reportRunning ? (
                <button
                  disabled
                  onClick={() => {}}
                  className="bp5-button bp5-large bp5-intent-primary"
                >
                  Generating...
                </button>
              ) : (
                <button
                  className="bp5-button bp5-large bp5-intent-primary"
                  style={{ cursor: 'pointer', position: 'relative', top: 0 }}
                  onClick={() => this.generateReport()}
                  disabled={
                    (!this.state.departmentsSelected &&
                      !this.state.restaurantsSelected) ||
                    (this.state.departmentsSelected.length === 0 &&
                      this.state.restaurantsSelected.length === 0)
                  }
                >
                  Generate Report
                </button>
              )}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>

          {/* <div style={{ textAlign: 'left' }}>
            <br />
            <h3><b>{this.state.name || "Report"}</b></h3>
          </div> */}
          {/* <div style={{ textAlign: 'center' }}>
          		<h1>{this.state.name || "Report"}</h1>
            </div>
            <hr />
            */}

          {this.state.reportRunning ? <Spinner /> : null}
          {this.state.cleanReport ? (
            <div style={{ color: '#006666', position: 'relative', top: 30 }}>
              <Table
                rowKey={(record) => record.id}
                columns={questionSetColumns}
                dataSource={this.state.reportUsers}
                pagination={{ pageSize: 50 }}
                scroll={{ x: totalColumnsWidth, y: 600 }}
              />
              <br />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

// Function to convert state as props
const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    users: state.data.users,
    questionSets: state.data.questionSets,
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    reportQuestionSets: state.data.reportQuestionSets,
    reportUsers: state.data.reportUsers,
    toaster: state.data.toaster,
    reportSelectedDepartments: state.data.reportSelectedDepartments,
    reportSelectedRestaurants: state.data.reportSelectedRestaurants,
  };
};

// The dispatchers to call the Redux saga
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReports: (departments, restaurants, onSuccess, onFail) => {
      dispatch(fetchReports(departments, restaurants, onSuccess, onFail));
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
    setSelectedRestaurants: (restaurants) => {
      dispatch(setReportSelectedRestaurants(restaurants));
    },
    setSelectedDepartments: (departments) => {
      dispatch(setReportSelectedDepartments(departments));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportView));
