// // import {createLogger} from "redux-logger";
// import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga';
//
// import appReducer from '../reducers';
// // import {fetchData} from "../actions/user-actions";
// import rootSaga from '../sagas/rootSaga';
//
// // const logger = createLogger({
// //   collapsed: true,
// //   duration: true
// // });
//
// const sagaMiddleware = createSagaMiddleware();
// const enhancer = compose(applyMiddleware(sagaMiddleware));
//
// const store = createStore(appReducer, enhancer);
// sagaMiddleware.run(rootSaga);
// // sagaMiddleware.run(fetchData);
//
// export default store;

// --------------------------------------------------------------------------

// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
// import appReducer from '../reducers'; // Your existing root reducer
// import rootSaga from '../sagas/rootSaga';
//
// const sagaMiddleware = createSagaMiddleware();
//
// // Configure the store
// // const store = configureStore({
// //   reducer: appReducer, // Use the existing root reducer for both old and new code
// //   middleware: (getDefaultMiddleware) =>
// //     getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
// //   devTools: process.env.NODE_ENV !== 'production', // Enable DevTools in development
// // });
//
// const store = configureStore({
//   reducer: appReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       thunk: false,
//       serializableCheck: {
//         // Ignore actions that include non-serializable values
//         ignoredActionPaths: ['onSuccess', 'onFail'],
//       },
//     }).concat(sagaMiddleware),
//   devTools: process.env.NODE_ENV !== 'production',
// });
//
// // Run saga middleware
// sagaMiddleware.run(rootSaga);
//
// export default store;

// --------------------------------------------------------------------------

// import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import appReducer from '../reducers';
// import rootSaga from '../sagas/rootSaga';
//
// const sagaMiddleware = createSagaMiddleware();
//
// // Enable Redux DevTools if available, otherwise use the basic compose function
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//
// const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
//
// const store = createStore(appReducer, enhancer);
//
// // Run the root saga
// sagaMiddleware.run(rootSaga);
//
// export default store;

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import appReducer from '../reducers';
import rootSaga from '../sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

// Setup Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const store = createStore(appReducer, enhancer);
sagaMiddleware.run(rootSaga);

export default store;
