import React, { useState, useEffect } from 'react';
import {
  LaptopOutlined,
  MenuOutlined,
  NodeExpandOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, Button, Popover, Spin, Drawer } from 'antd';
import { Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { userGetData } from '../actions/userActions';
import Home from '../components/site/Home';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import EmployeeDashboard from '../components/dashboard/EmployeeDashboard';
import AdminDashboard from '../components/dashboard/AdminDashboard';
import UserPage from '../components/dashboard/UserPage';
import TestPage from '../components/dashboard/TestPage';
import DepartmentPage from '../components/dashboard/DepartmentPage';
import ManagerDashboard from '../components/dashboard/ManagerDashboard';
import Test1 from '../components/test/Test1';
import { getCSRF } from '../actions/authActions';
import Register from '../components/auth/Register';
import ReleaseNotes from '../components/site/ReleaseNotes';
import Grading from '../components/question/Grading';
import UtilityContainer from '../containers/UtilityContainer';
import TemporaryPasswordReset from '../components/auth/TemporaryPasswordReset';
import Test2 from '../components/test/Test2';
import { COLORS } from '../constants/app.constants';
import UserSettings from '../components/user/UserSettings';
import WithAdminData from './WithAdminData';
import Breadcrumbs from '../components/utils/Breadcrumbs';
import UpsertQuestionSet from '../components/question/UpsertQuestionSet';
import UpsertQuestion from '../components/question/UpsertQuestion';
import TestingContainer from './TestingContainer';
import TestWrapper from '../components/question/TestWrapper';
import QuestionSetInstanceTable from '../components/test/QuestionSetInstanceTable';
import QuestionInstances from '../components/question/QuestionInstances';
import UpsertUser from '../components/user/UpsertUser';
import ViewUser from '../components/user/ViewUser';
import ReportView from '../components/reports/ReportView';
import ReportOverview from '../components/reports/ReportOverview';
import SelectTest from '../components/test/SelectTest';
import MultiSelectTest from '../components/test/MultiSelectTest';
import useGrantedRoutes from '../hooks/useGrantedRoutes';

const { Header, Content, Footer: AntFooter, Sider } = Layout;

const RouterContainer = () => {
  const [initialized, setInitialized] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.data.user);
  const roles = useSelector((state) => state.data.roles);
  const { getDashboardLink } = useGrantedRoutes();

  const userRolesState = useSelector((state) => state.data.user.roles);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const tempRoles = [];
    const availableRoleArray = Array.isArray(userRolesState)
      ? userRolesState
      : Object.values(userRolesState);

    for (const roleId of availableRoleArray) {
      if (typeof roleId === 'number') {
        if (roles[roleId]) {
          tempRoles.push(roles[roleId].name);
        }
      } else {
        tempRoles.push(roleId.name);
      }
    }
    setUserRoles(tempRoles);
  }, [userRolesState]);

  const showSidebar = _.some(
    userRoles,
    (role) => role === 'sysadmin' || role === 'admin' || role === 'manager'
  );

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleResize);

    dispatch(getCSRF());
    dispatch(
      userGetData({}, () => {
        setInitialized(true);
        jumpToDashboard();
      })
    );

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const jumpToDashboard = () => {
    if (_.get(user, 'temporary_password')) navigate('/temporarypasswordreset');
    else if (
      _.some(userRoles, (role) => role === 'sysadmin' || role === 'admin')
    )
      navigate('/admindashboard');
    else if (_.some(userRoles, (role) => role === 'manager'))
      navigate('/managerdashboard');
    else if (_.some(userRoles, (role) => role === 'employee'))
      navigate('/dashboard');
    else navigate('/');
  };

  const AuthCheck = ({ allowedRoles, children }) => {
    console.log('userRoles: ', userRoles);
    console.log('allowedRoles: ', allowedRoles);
    const isAuthenticated = _.intersection(userRoles, allowedRoles).length > 0;
    return isAuthenticated ? children : null;
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="settings">
        <Link to="/usersettings">
          <SettingOutlined /> Settings
        </Link>
      </Menu.Item>
      {_.some(userRoles, (role) => role === 'admin' || role === 'manager') && (
        <Menu.Item key="release">
          <Link to="/release">
            <NodeExpandOutlined /> Release Notes
          </Link>
        </Menu.Item>
      )}
      {_.some(userRoles, (role) => role === 'sysadmin') && (
        <Menu.Item key="utilities">
          <Link to="/utilities">
            <LaptopOutlined /> Utilities
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="logout">
        <Link to="/logout">
          <LogoutOutlined /> Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const menuItems = (
    <Menu mode="inline" defaultSelectedKeys={['1']} style={{ height: '100%' }}>
      <Menu.Item key="1">
        <Link to={getDashboardLink()}>Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/tests">Tests</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/departments">Departments</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/usersettings">User Settings</Link>
      </Menu.Item>
    </Menu>
  );

  if (!initialized) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          backgroundColor: COLORS.PRIMARY,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isMobile && showSidebar && (
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={() => setDrawerVisible(true)}
            style={{ color: '#fff' }}
          />
        )}
        {/* Logo */}
        <div
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={jumpToDashboard}
        >
          <h1 style={{ color: '#fff', margin: 0 }}>HCR</h1>
        </div>

        {/* Breadcrumbs */}

        <Breadcrumbs user={user} roles={roles} />

        {/* User Menu */}
        <Popover content={userMenu} trigger="click">
          <Button type="text" style={{ color: '#fff' }}>
            {_.get(user, 'first_name', 'User')}
          </Button>
        </Popover>
      </Header>
      <Content>
        <Layout
          style={{
            padding: '8px 0',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {!isMobile ? (
            showSidebar && (
              <Sider style={{ background: colorBgContainer }} width={200}>
                {menuItems}
              </Sider>
            )
          ) : (
            <Drawer
              title="Menu"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
            >
              {menuItems}
            </Drawer>
          )}
          <Content style={{ paddingTop: 16, minHeight: 280 }}>
            <Routes>
              <Route path="/" element={<Navigate to={getDashboardLink()} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/dashboard"
                element={
                  <AuthCheck
                    allowedRoles={['employee', 'manager', 'admin', 'sysadmin']}
                  >
                    <EmployeeDashboard />
                  </AuthCheck>
                }
              />
              <Route
                path="/admindashboard"
                element={
                  <AuthCheck allowedRoles={['admin', 'sysadmin']}>
                    <AdminDashboard />
                  </AuthCheck>
                }
              />
              <Route
                path="/users"
                element={
                  <AuthCheck allowedRoles={['admin', 'sysadmin']}>
                    <WithAdminData>
                      <UserPage />
                    </WithAdminData>
                  </AuthCheck>
                }
              />
              <Route
                path="/tests"
                element={
                  <AuthCheck allowedRoles={['admin', 'sysadmin']}>
                    <WithAdminData>
                      <TestPage />
                    </WithAdminData>
                  </AuthCheck>
                }
              />
              <Route
                path="/departments"
                element={
                  <AuthCheck allowedRoles={['admin', 'sysadmin']}>
                    <WithAdminData>
                      <DepartmentPage />
                    </WithAdminData>
                  </AuthCheck>
                }
              />
              <Route
                path="/managerdashboard"
                element={
                  <AuthCheck allowedRoles={['manager', 'admin', 'sysadmin']}>
                    <ManagerDashboard />
                  </AuthCheck>
                }
              />
              <Route
                path="/temporarypasswordreset"
                element={
                  <AuthCheck
                    allowedRoles={['sysadmin', 'admin', 'manager', 'employee']}
                  >
                    <TemporaryPasswordReset />
                  </AuthCheck>
                }
              />
              {/* <Route
    path="/dashboardjump"
    element={
      <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager', 'employee']}>
        <DashboardJump />
      </AuthCheck>
    }
  /> */}
              <Route
                path="/usersettings"
                element={
                  <AuthCheck
                    allowedRoles={['sysadmin', 'admin', 'manager', 'employee']}
                  >
                    <UserSettings />
                  </AuthCheck>
                }
              />
              <Route
                path="/grading/:questionSetId"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <Grading />
                  </AuthCheck>
                }
              />
              <Route
                path="/release"
                element={
                  <AuthCheck allowedRoles={['admin', 'manager']}>
                    <ReleaseNotes />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/new"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <UpsertQuestionSet />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/:questionSetId"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <UpsertQuestionSet />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/:questionSetId/question/new"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <UpsertQuestion />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/:questionSetId/question/:questionId"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <UpsertQuestion />
                  </AuthCheck>
                }
              />
              <Route
                path="/testing/:questionSetId"
                element={
                  <AuthCheck
                    allowedRoles={['sysadmin', 'admin', 'manager', 'employee']}
                  >
                    <TestingContainer />
                  </AuthCheck>
                }
              />
              <Route
                path="/testing/:questionSetId/question/:questionId"
                element={
                  <AuthCheck
                    allowedRoles={['sysadmin', 'admin', 'manager', 'employee']}
                  >
                    <TestWrapper />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/:questionSetId/question_set_instances"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin']}>
                    <QuestionSetInstanceTable />
                  </AuthCheck>
                }
              />
              <Route
                path="/test/:questionSetId/question_set_instances/:questionSetInstanceId/questions"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin']}>
                    <QuestionInstances />
                  </AuthCheck>
                }
              />
              <Route
                path="/user/new"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin']}>
                    <UpsertUser />
                  </AuthCheck>
                }
              />
              <Route
                path="/user/:userId"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin']}>
                    <UpsertUser />
                  </AuthCheck>
                }
              />
              <Route
                path="/user/view/:userId"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin']}>
                    <ViewUser />
                  </AuthCheck>
                }
              />
              <Route
                path="/admin/report"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <ReportView />
                  </AuthCheck>
                }
              />
              <Route
                path="/admin/reportoverview"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <ReportOverview />
                  </AuthCheck>
                }
              />
              <Route
                path="/select"
                element={
                  <AuthCheck allowedRoles={['admin']}>
                    <SelectTest />
                  </AuthCheck>
                }
              />
              <Route
                path="/test1"
                element={
                  <AuthCheck allowedRoles={['admin']}>
                    <Test1 />
                  </AuthCheck>
                }
              />
              <Route
                path="/test2"
                element={
                  <AuthCheck allowedRoles={['admin']}>
                    <Test2 />
                  </AuthCheck>
                }
              />
              <Route
                path="/multiselect"
                element={
                  <AuthCheck allowedRoles={['sysadmin', 'admin', 'manager']}>
                    <MultiSelectTest />
                  </AuthCheck>
                }
              />
              <Route path="*" element={<div>Not Found</div>} />
            </Routes>
          </Content>
        </Layout>
      </Content>
      <AntFooter style={{ textAlign: 'center' }}>
        Handcrafted Restaurants ©{new Date().getFullYear()}
      </AntFooter>
    </Layout>
  );
};

export default RouterContainer;
