import React from 'react';
import { Layout, Button } from 'antd';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Home from '../components/site/Home';
import Login from '../components/auth/Login';
// eslint-disable-next-line import/namespace
import Register from '../components/auth/Register';
import { COLORS } from '../constants/app.constants';
const { Header, Content, Footer } = Layout;

const AppPublic = () => {
  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Header */}
      <Header
        style={{
          backgroundColor: COLORS.PRIMARY,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{ flex: 1, cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
          }}
        >
          <h1 style={{ color: '#fff' }}>HCR</h1>
        </div>
        <div>
          <Link to="/login">
            <Button type="link" style={{ color: '#fff', marginRight: '16px' }}>
              Login
            </Button>
          </Link>
          <Link to="/register">
            <Button type="link" style={{ color: '#fff' }}>
              Register
            </Button>
          </Link>
        </div>
      </Header>

      {/* Content */}
      <Content style={{ padding: '16px' }}>
        <div
          style={{
            background: '#fff',
            padding: '24px',
            borderRadius: '8px',
            minHeight: '280px',
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<div>Page Not Found</div>} />
          </Routes>
        </div>
      </Content>

      {/* Footer */}
      <Footer style={{ textAlign: 'center' }}>
        Handcrafted Restaurants ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default AppPublic;
