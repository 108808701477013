import React, { Component } from 'react';
import { connect } from "react-redux";
// import TrueFalse from './TrueFalse';
import { Button, MenuItem } from "@blueprintjs/core"; //MenuDivider
// import Spaner from '../utils/Spaner';
import PureGridBox24th from '../utils/PureGridBox24th';
import { MultiSelect } from '@blueprintjs/select';
import _ from 'lodash';

const items = [{ id: 0, value: 'True / False' }, { id: 1, value: 'Multichoice' }, { id: 2, value: 'Fill in the Blanks' }, { id: 3, value: 'Slider' }, { id: 4, value: 'Image Match' }]
class UpsertQuestionSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            types: []
        };
    }

    CellBox = (label, contents) => {
        return (
            <div className="pure-u-1 pure-u-md-12-24 pure-u-xl-6-24" style={{ textAlign: 'left' }}>
                <span>{label}</span><br />
                {contents}
            </div>
        )
    }

    handleStateChange(event, attribute) {
        this.setState({
            [attribute]: event.target.value
        })
    }

    handleSubmit() {
        console.log(this.state);
    }

    renderTag = (item) => {
        return (
           item.value
            // <div key={item.id}>{item.value}</div>
        );
    }

    handleTagRemove = (tag) => {
        let types = this.state.types;
        let index = _.findIndex(this.state.types, {value: tag})
        if (index!==-1){
            types.splice(index, 1);
            this.setState({
                types: types
            })
        }
        console.log(types);
    }

    renderItem = (item) => {
        return (
            <MenuItem
                active={_.findIndex(this.state.types, {id: item.id}) !== -1}
                key={item.id}
                onClick={() => this.handleValueChange(item)}
                text={item.value}
                shouldDismissPopover={false}
            />
            // <div key={item.id}>{item.value}</div>
        );
    }

    handleValueChange = (item) => {

        let types = this.state.types;
        let index = _.findIndex(this.state.types, {id: item.id})
        if (index!==-1){
            types.splice(index, 1);
        }
        else{
            types.push(item);
        }

        this.setState({
            types: types
        })
        console.log(types);
    }

    // filterItem = (query, item) => {
    //     return item.value.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    // };

    render() {
        return (



            <PureGridBox24th>
                <div style={{ color: 'blue', textAlign: 'center', fontSize: 16 }}>
                    <br />

                    {this.CellBox('Type',

                        <MultiSelect
                            className="bp5-large"
                            filterable={false}
                            selectedItems={this.state.types}
                            items={items}
                            itemRenderer={(data) => this.renderItem(data)}
                            noResults={<MenuItem disabled={true} text="No results." />}
                            onItemSelect={(data) => this.handleValueChange(data)}
                            tagRenderer={(data) => this.renderTag(data)}
                            tagInputProps={{ onRemove: (tag) => this.handleTagRemove(tag), placeholder: '' }}
                        >
                            <Button text={this.state.type} rightIcon="double-caret-vertical" />
                        </MultiSelect>

                    )}
                    <div className="flexcenter" style={{ textAlign: 'left', width: '100%', marginTop: 16 }}>
                        <Button onClick={() => this.handleSubmit()} intent="primary" large>Submit</Button>
                    </div>
                </div>
            </ PureGridBox24th>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(null, mapDispatchToProps)(UpsertQuestionSet);