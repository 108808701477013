import { put } from "redux-saga/effects"; //call
import _ from "lodash";

const utilSaga = {
  breadcrumbSet: function* (action) {
    let success = false;
    try {
        yield put({ type: "BREADCRUMB_SET_SUCCESS", payload: { breadcrumbs: action.payload.breadcrumbs } }); 
        success = true;
        if (action.onSuccess) {
          action.onSuccess();
        }
    } catch (e) {

    }
    if (!success) {
      if (action.onFail) {
        action.onFail()
      }
      yield put({ type: "BREADCRUMB_SET_FAIL", payload: { breadcrumbs: action.payload.breadcrumbs } });
    }
  },

}

export default utilSaga