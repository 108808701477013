import React, { Component } from 'react';
import { MenuItem, Button } from "@blueprintjs/core";
import { MultiSelect } from '@blueprintjs/select';
import _ from 'lodash';

class MultiSelectWrapper extends Component {

  constructor(props) {
    super(props);

    let initialItems = this.props.initialItems || [];
    this.state = {
      items: initialItems
    };
  }

  renderTag = (item) => {
    return (
      item.name
    );
  }

  handleTagRemove = (tag) => {
    let items = this.state.items;
    let index = _.findIndex(this.state.items, { name: tag });
    if (index !== -1) {
      items.splice(index, 1);
      this.setState({
        items: items
      });
    }
  }

  renderItem = (item) => {
    return (
      <MenuItem
        active={_.findIndex(this.state.items, { id: item.id }) !== -1}
        key={item.id}
        onClick={() => this.handleValueChange(item)}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  }

  handleValueChange = (item) => {
    let items = this.state.items;
    let index = _.findIndex(this.state.items, { id: item.id });
    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(item);
    }

    this.setState({
      items: items
    });
    this.props.onChange(items);
  }

  handleClear = () => {
    this.setState({ items: [] })
    this.props.onChange([]);
  };

  itemListPredicate = (query, item) => {
    let items = _.get(this.props, 'items', []);
    items = _.filter(items, item => {
      return item.name.includes(query);
    })
    return items;
  }

  render() {

    const clearButton = this.state.items.length > 0 ? <Button icon="cross" minimal={true} onClick={this.handleClear} /> : null;

    return (
      <MultiSelect
        className="bp5-large"
        selectedItems={this.state.items}
        items={_.get(this.props, 'items', [])}
        itemListPredicate={this.itemListPredicate}
        itemRenderer={(data) => this.renderItem(data)}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={(data) => this.handleValueChange(data)}
        tagRenderer={(data) => this.renderTag(data)}
        tagInputProps={{ onRemove: (tag) => this.handleTagRemove(tag), placeholder: '', rightElement: clearButton }}
      >
      </MultiSelect>
    )
  }
}

export default MultiSelectWrapper;