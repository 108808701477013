//import { Upload, message, Button, Icon } from 'antd';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { getFileType, getFileIcon } from '../utils/sharedUtils';
import React, { Component } from 'react';
import cfg from '../../config';

class SingleUpload extends Component {
  constructor(props) {
    super(props);

    const { file, textField, textFieldName, handleRank, textFieldValue  } = this.props;
    this._file = null;
    this._inputFileRef = null;
    this._inputTextRef = null;

    let fileThumb = "/images/icons/empty.png";
    let startDisabled = true;
    let status = "none";
    let server = cfg.server_url;

    if (file) {
      if (file.asset && file.asset.name) {
        let fileType = getFileType(file.asset.name);
        if (fileType !== "image") {
          fileThumb = getFileIcon(file.asset.name);
        } else {
          fileThumb =  server + "/v1/protectedfile/download?subdir=question_set&file=" + file.asset.name;
        }
      }
    }

    if (textField === undefined) {
      //let textField = false;
    }

    if (handleRank === undefined) {
      //let handleRank = false;
    }

    if (textFieldName === undefined) {
      //let textFieldName = null;
    }
    
    this.state = {
      file: file,
      percentCompleted: 0,
      fileThumb: fileThumb,
      textField: textField,
      textFieldName: textFieldName,
      handleRank: handleRank,
      startDisabled: startDisabled,
      status: status,
      textFieldValue: textFieldValue
    }
  }

  componentDidMount() {
    this._inputTextRef.value = this.state.file.asset.textField || "";
  }

  handleChange = (e) => {
    let file = this._file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let fileType = getFileType(file.name);
      let fileThumb = null;
      if (fileType === "image") {
        fileThumb = e.target.result;
      } else {
        fileThumb = getFileIcon(file.name);
      }
      this.setState({ startDisabled: false, fileThumb: fileThumb });
    }
    reader.readAsDataURL(file);
  }

  handleUpload = () => {
    const onUploadProgress = (obj) => this.handleProgress(obj);
    const onFinished = (status) => this.handleFinished(status);

    let id = null;
    if (this.state.file.id !== undefined) {
      id = this.state.file.id;
    }

    this.props.action({
      file: this._file,
      onProgress: onUploadProgress,
      onFinished: onFinished,
      data: {
        textFieldValue: this.state.textFieldValue,
        resourceId: id,
      }
    });

    this.setState({ status: "uploading", startDisabled: true });
  }

  handleFinished = (status) => {
    if (status === "success") {
      this.setState({ status: "finished" });
    } else if (status === "error") {
      this.setState({ status: "error" });
    }

    this._file = null;
    if (this._inputFileRef){
      this._inputFileRef.value = "";
    }
    this.props.onSuccess()
  }

  handleProgress = (obj) => {
    this.setState({ percentCompleted: obj.percent });
  }

  handleRankUp = () => {
    this.props.handleRankUp(this.props.index);
  }

  handleRankDown = () => {
    this.props.handleRankDown(this.props.index);
  }

  handleRemove = () => {
    if (this.props.id && !isNaN(this.props.id)) {
      this.props.handleRemove(this.props.id, true);
    } else {
      if (this.props.arrayIndex !== null) {
        this.props.handleRemove(this.props.arrayIndex, false);
      }
    }
  }

  handleText = (e) => {
    if (e.target.value === this.state.file.asset.textField) {
      this.setState({ 
        textFieldValue: e.target.value,
        startDisabled: true
      });
    } else {
      this.setState({ 
        textFieldValue: e.target.value,
        startDisabled: false
      });
    }
  }

  render() {
    return (
      <div style={{ marginBottom:"15px" }} className="pure-u-1">
       
          <div className="pure-u-7-24">
              <label style={{ padding:"5px" }} htmlFor="name">Name</label>
              <input ref={el => this._inputTextRef = el} onChange={e => this.handleText(e)} id="name" className="bp5-input" placeholder="Material Name" type="text"/>
          </div>
          <div className="pure-u-7-24">
              <label className="bp5-file-input">
                <input ref={el => this._inputFileRef = el} onChange={e => this.handleChange(e)} type="file" />
                <span className="bp5-file-upload-input">Choose file...</span>
              </label>
          </div>
          <div className="pure-u-4-24">
            <img style={{ width:"32px" }} src={this.state.fileThumb} alt=""/>
          </div>
          <div className="pure-u-2-24">
            {this.props.handleRank ?
              <div>
                <div className="pure-u-1-3">
                  <FontAwesomeIcon onClick={this.handleRankUp} style={{cursor: 'pointer'}} className="fa-lg" icon={['far', 'arrow-circle-up']} />
                </div>
                <div className="pure-u-1-3">
                  <FontAwesomeIcon onClick={this.handleRankDown} style={{cursor: 'pointer'}} className="fa-lg" icon={['far', 'arrow-circle-down']} />
                </div>
                <div className="pure-u-1-3">
                  <FontAwesomeIcon onClick={this.handleRemove} style={{cursor: 'pointer'}} className="fa-lg" icon={['far', 'times']} />
                </div>
              </div>
              :
              <div className="pure-u-1-3">
                <FontAwesomeIcon style={{cursor: 'pointer'}} className="fa-lg" icon={['far', 'times']} />
              </div>
            }
          </div>
          <div className="pure-u-2-24">
          {this.state.startDisabled? null:
          <button type="button" onClick={this.handleUpload} className="bp5-intent-primary bp5-button bp5-icon-upload disabled" disabled={this.state.startDisabled}>Save</button>
          }    
          </div>
          <div className="pure-u-2-24">
            <span>{this.state.percentCompleted===0 ? <span /> : <span>{this.state.percentCompleted} %</span>}</span>
          </div>
      
      </div>
    );
  }
}

export default SingleUpload;