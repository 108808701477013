import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { userUpdate } from '../../actions/userActions';
// import { arrayFromKeyedObject, arrayFromKeys } from '../utils/sharedUtils';
import { getIntent } from '../utils/validate';
import PureGridBox24th from '../utils/PureGridBox24th';
import Cellbox from '../utils/Cellbox';
import { breadcrumbSet } from '../../actions/utilActions';
import { AppToaster } from '../common/AppToaster';

class UserSettings extends Component {
  constructor(props) {
    super(props);
    let id = null;
    let first_name = '';
    let last_name = '';
    let email = '';

    //create a comma seperated string of user restaurants
    this._userRestaurants = [];
    _.forEach(this.props.restaurants, (restaurant) => {
      if (this.props.user.restaurants.includes(restaurant.id)) {
        this._userRestaurants.push(restaurant.name);
      }
    });
    this._userRestaurants = this._userRestaurants.join(' , ');

    //create a comma seperated string of user departments
    this._userDepartments = [];
    _.forEach(this.props.departments, (department) => {
      if (this.props.user.departments.includes(department.id)) {
        this._userDepartments.push(department.name);
      }
    });
    this._userDepartments = this._userDepartments.join(',');

    let user = this.props.user;
    id = user.id;
    first_name = user.first_name;
    last_name = user.last_name;
    email = user.email;

    this.state = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      current_password: '',
      new_password: '',
      confirm_password: '',
      popoverMinimal: true,
      tagMinimal: false,
      validation: {
        first_name: { rules: ['isString'], isValid: true, errors: [] },
        last_name: { rules: ['isString'], isValid: true, errors: [] },
      },
    };
  }

  componentDidMount() {
    this.props.breadcrumbSet([
      { name: 'User Settings', active: true, link: '/usersettings' },
    ]);
  }

  handleSubmit = (event) => {
    const onSuccess = (user) => this.handleSuccess(user);
    const onFail = (message) => this.handleFail(message);

    let new_password = null;
    let current_password = null;
    if (this.state.new_password !== '') {
      if (this.state.new_password !== this.state.confirm_password) {
        this.handleFail('Password confirmation does not match');
        return;
      } else if (this.state.current_password === '') {
        this.handleFail('Current password is required');
        return;
      } else {
        //TODO: logic to confirm password is secure enough regex
        new_password = this.state.new_password;
        current_password = this.state.current_password;
      }
    }
    this.props.userUpdate(
      this.state.id,
      this.state.first_name,
      this.state.last_name,
      current_password,
      new_password,
      onSuccess,
      onFail
    );
  };

  handleSuccess = (user) => {
    console.log('user', user);
    // this.setState({
    //   first_name: user.first_name,
    //   last_name: user.last_name,
    //   email: user.email,
    // });

    AppToaster.show({
      message: 'Settings updated successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
    // this.props.router.navigate('/usersettings');
  };

  handleFail = (custom_message) => {
    let message = 'User update failed.';
    if (custom_message) {
      message = custom_message;
    }
    AppToaster.show({
      message: message,
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
  };

  getTagProps = (_value, index) => ({
    minimal: this.state.tagMinimal,
  });

  handleTagRemove = (tag) => {
    let _restaurants = this.state.restaurants;
    let index = _.findIndex(
      this.state.restaurants,
      (restaurant) => restaurant.name === tag
    );
    if (index !== -1) {
      _restaurants.splice(index, 1);
      this.setState({
        restaurants: _restaurants,
      });
    }
  };

  handleStateChange(event, attribute) {
    this.setState({
      [attribute]: event.target.value,
    });
  }

  render() {
    return (
      <PureGridBox24th>
        <div style={{ color: 'black', textAlign: 'center', fontSize: 14 }}>
          <br />

          <Cellbox label="Email">{this.state.email}</Cellbox>

          <br />
          <Cellbox label="Restaurants">{this._userRestaurants}</Cellbox>

          <br />
          <Cellbox label="Departments">{this._userDepartments}</Cellbox>

          <br />
          <Cellbox
            label="First name"
            validation={this.state.validation.first_name}
          >
            <input
              className={
                'bp5-input bp5-large ' +
                getIntent(this.state.validation, 'first_name')
              }
              type="text"
              value={this.state.first_name}
              onChange={(e) => this.handleStateChange(e, 'first_name')}
            />
          </Cellbox>
          <br />
          <Cellbox
            label="Last name"
            validation={this.state.validation.last_name}
          >
            <input
              className={
                'bp5-input bp5-large ' +
                getIntent(this.state.validation, 'last_name')
              }
              type="text"
              value={this.state.last_name}
              onChange={(e) => this.handleStateChange(e, 'last_name')}
            />
          </Cellbox>
          <br />

          <Cellbox label="Current password">
            <input
              className={'bp5-input bp5-large '}
              type="password"
              value={this.state.current_password}
              onChange={(e) => this.handleStateChange(e, 'current_password')}
            />
          </Cellbox>
          <br />

          <Cellbox label="New password">
            <input
              className={'bp5-input bp5-large '}
              type="password"
              value={this.state.new_password}
              onChange={(e) => this.handleStateChange(e, 'new_password')}
            />
          </Cellbox>
          <br />

          <Cellbox label="Confirm new password">
            <input
              className={'bp5-input bp5-large '}
              type="password"
              value={this.state.confirm_password}
              onChange={(e) => this.handleStateChange(e, 'confirm_password')}
            />
          </Cellbox>
          <br />

          <div
            className="flexcenter"
            style={{ textAlign: 'center', width: '100%', marginTop: 14 }}
          >
            <button
              className="bp5-button bp5-large bp5-intent-primary"
              style={{ cursor: 'pointer', position: 'relative', top: 15 }}
              onClick={() => this.handleSubmit()}
            >
              <span>Update</span>
            </button>
          </div>
        </div>
      </PureGridBox24th>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    breadcrumbs: state.data.breadcrumbs || [],
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userUpdate: (
      id,
      first_name,
      last_name,
      current_password,
      new_password,
      onSuccess,
      onFail
    ) => {
      dispatch(
        userUpdate(
          id,
          first_name,
          last_name,
          null,
          null,
          null,
          current_password,
          new_password,
          null,
          null,
          onSuccess,
          onFail
        )
      );
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
