import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
          <div>
            <div className="flexcenter" style={{ color: '#006666' }}>
            <div>
              <br /><br />
            <b>Release Notes</b>
            <hr />
            V 0.5<br/>
              2024-02-17<br/>
                <ul>
                <li>General
                    <ul>
                      <li>Added Ulu kitchen as a new restaurant.</li>
                    </ul>
                  </li>
                <li>Infrastructure
                    <ul>
                      <li>Updated sails and node dependencies to latest versions.</li>
                    </ul>
                    <ul>
                      <li>Updated ubuntu server from 18.x to 20.x.</li>
                    </ul>
                    <ul>
                      <li>Updated postgres from 11 to 16.</li>
                    </ul>
                    <ul>
                      <li>Granular params added to seed to allow seeding of subsets of data.</li>
                    </ul>
                  </li>
                  <li>Frontend
                    <ul>
                      <li>Added docker configurations for development.</li>
                    </ul>
                  </li>    
                </ul>
            <hr />
            V 0.4<br/>
              2019-05-27<br/>
                <ul>
                <li>Report Overview
                    <ul>
                      <li>Fixed issue where report overview would not properly summarize reports.</li>
                    </ul>
                  </li>
                  <li>Question Taking
                    <ul>
                      <li>Confirmation modal for retaking a test</li>
                      <li>Fixed bug where multiple users could not take test at the same time</li>
                    </ul>
                  </li>
                  <li>Manager Dashboard
                    <ul>
                      <li>Manager login now shows employees and tests that are assigned to them</li>
                      <li>Fixed bug where multiple users could not take test at the same time</li>
                    </ul>
                  </li>
                  <li>Admin / Manager Dashboard
                    <ul>
                      <li>Paged results increased to 25 from 10 for employees and tests</li>
                    </ul>
                  </li>    
                </ul>
            <hr />
            V 0.3.8<br/>
              2019-04-24<br/>
                <ul>
                  <li>Question Sets
                    <ul>
                      <li>Filters added by restaurant and department.</li>
                    </ul>
                  </li>
                  <li>Question Taking
                    <ul>
                      <li>Question number added to progress indicator.</li>
                    </ul>
                  </li>
                  <li>Reports
                    <ul>
                      <li>Deactivated tests are no longer displayed.</li>
                    </ul>
                  </li>
                  <li>Grading
                    <ul>
                      <li>Back button added in grading view to return to previous report.</li>
                      <li>Fixed bug where an essay question would need to be graded twice.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
            V 0.3.7<br/>
              2019-03-25<br/>
                <ul>
                  <li>Question Instances View
                    <ul>
                      <li>New view to see all question instances for a given test and employee.</li>
                    </ul>
                  </li>
                  <li>Grading
                    <ul>
                      <li>Question name is now displayed along with answer.</li>
                      <li>Fixed bug where you would have to grade a question twice.</li>
                    </ul>
                  </li>    
                  <li>General
                    <ul>
                      <li>Question Table: Rank now starts at 1 instead of 0.</li>
                      <li>Report View: Improved styling highlights success and fail on tests.</li>
                    </ul>
                  </li>   
                </ul>
            <hr />
            V 0.3.5<br/>
              2019-03-10<br/>
                <ul>
                  <li>Reports View
                    <ul>
                      <li>Horizontal scrolling added to handle more tests then viewable on screens.</li>
                      <li>The query area will now hide after a report has been generated.</li>
                      <li>Removed redundant (to attempt count) X when a user has not taken a test.</li>
                      <li>General UI improvements.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
            V 0.3.4<br/>
              2019-01-28<br/>
                <ul>
                  <li>Test Table
                    <ul>
                      <li>Added Rank column, sortable.</li>
                    </ul>
                  </li>
                  <li>General
                    <ul>
                      <li>Optimization and workflow improvements for taking tests and admin views.</li>
                      <li>Fixed search crash bug, and optimized.</li>
                      <li>Fixed issue where in progress tests would not close.</li>
                      <li>Fixed issue where zero time limit tests would not properly close.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
            V 0.3.3<br/>
              2019-01-14<br/>
                <ul>
                  <li>User Table
                    <ul>
                      <li>Added filter by user role.</li>
                      <li>Added activate / deactivate functionality.</li>
                    </ul>
                  </li>
                  <li>Create Question
                    <ul>
                      <li>Increased the size of the question text field.</li>
                    </ul>
                  </li>
                  <li>Reports
                    <ul>
                      <li>New Overview Report shows a table of restaurants and departments and which have completed all required tests by employees.</li>
                      <li>Only Active users who are employees will show up in the reports.</li>
                      <li>Improved breadcrumb.</li>
                    </ul>
                  </li>   
                  <li>General
                    <ul>
                      <li>Fixed bug where searches and filters were not visible / working properly.</li>
                      <li>Added notification when activating / deactivating a test.</li>
                      <li>Improved client side data normalization for user update and user create.</li>
                      <li>Fixed bug where creating a user would not route back to the admin dashboard.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
            V 0.3.2<br/>
              2019-01-06<br/>
                <ul>
                  <li>Report View
                    <ul>
                      <li>Added in progress indicator.</li>
                      <li>Added block on running the report while another is in progress.</li>
                      <li>First and Last name search added.</li>   
                      <li>Record count on pagination increased to 50</li>   
                      <li>Back to top UI element added when scrolled.</li>
                      <li>Improved styling when answering multiple choice questions.</li>   
                    </ul>
                  </li>
                </ul>
            <hr />
            V 0.3.1<br/>
              2019-01-04<br/>
                <ul>
                  <li>Question Taking
                    <ul>
                      <li>Fixed bug where question text was not appearing when taking tests.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
              V 0.3.0<br/>
              2018-12-28<br/>
                <ul>
                  <li>Question Set Table
                    <ul>
                      <li>Admins can now see all tests regardless if they are assigned department and restaurants to said tests.</li>
                      <li>Managers will still see only tests for restaurant and departments they belong to.</li>
                      <li>Test name search added.</li>
                      <li>Active / Inactive filter, defaulting to active, added.</li>
                    </ul>
                  </li>
                  <li>Upsert Question
                  <ul>
                      <li>Questions default to a point value of 1.</li>
                    </ul>
                  </li>
                  <li>Employee Dashboard
                  <ul>
                      <li>Other tests now are only shown for restaurants and departments the employee belongs to.</li>
                    </ul>
                  </li>
                  <li>Report View
                  <ul>
                      <li>Fixed issue where all departments would not be displayed.</li>
                      <li>Fixed issue where all users would be displayed when filtering by restaurant and department.</li>
                      <li>Fixed issue where all tests would be displayed when filtering by restaurant and department.</li>
                      <li>Last name added.</li>
                    </ul>
                  </li>
                </ul>
            <hr />
              V 0.2.0<br/>
              2018-11-22<br/>
              <ul>
                  <li>General
                  <ul>
                      <li>Added additional departments.</li>
                      <li>Fixed issue where toasters (notifications) would overlap rather then stack.</li>
                      <li>Added release note section.</li>
                    </ul>
                  </li>
                </ul>
            </div>
            </div>

          </div>
        )
    }
}

export default Home