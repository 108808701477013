import {
  BREADCRUMB_SET,
  SHOW_TOASTER,
  SET_REPORT_SELECTED_DEPARTMENTS,
  SET_REPORT_SELECTED_RESTAURANTS
} from './actionTypes';

export const breadcrumbSet = (breadcrumbs, onSuccess, onFail) => ({
  type: BREADCRUMB_SET,
  payload: {
    breadcrumbs: breadcrumbs
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const setReportSelectedDepartments = (departments) => ({
  type: SET_REPORT_SELECTED_DEPARTMENTS,
  payload: {
    departments
  }
});

export const setReportSelectedRestaurants = (restaurants) => ({
  type: SET_REPORT_SELECTED_RESTAURANTS,
  payload: {
    restaurants
  }
});