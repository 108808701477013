import {
  DEPARTMENT_CREATE,
  DEPARTMENT_FETCH,
  DEPARTMENT_UPDATE,
} from './actionTypes';

export const departmentFetch = (onSuccess, onFail) => ({
  type: DEPARTMENT_FETCH,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateDepartment = (payload, onSuccess, onFail) => ({
  type: DEPARTMENT_UPDATE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createDepartment = (payload, onSuccess, onFail) => ({
  type: DEPARTMENT_CREATE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
