import request from '../utils/api/ServerRequest';
import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';

const questionSchema = new schema.Entity('questions');
const questionInstanceSchema = new schema.Entity('questionInstances');
const questionSetInstanceSchema = new schema.Entity('questionSetInstances');

async function createQuestion(payload) {
  try {
    return await request.post('/v1/question/create', {
      question_text: payload.question_text,
      type: payload.type,
      flex: payload.flex,
      questionSetId: payload.questionSetId,
      point_value: payload.point_value,
    });
  } catch (e) {
    return e;
  }
}

async function updateQuestion(payload) {
  try {
    return await request.put('/v1/question/update', {
      question_text: payload.question_text,
      type: payload.type,
      flex: payload.flex,
      id: payload.id,
      point_value: payload.point_value,
    });
  } catch (e) {
    return e;
  }
}

async function fetchQuestionTypes(payload) {
  try {
    return await request.get('/v1/question/fetch_types');
  } catch (e) {
    return e;
  }
}

async function moveQuestionUp(payload) {
  try {
    return await request.put('/v1/question/move_question_up', {
      questionId: payload.questionId,
    });
  } catch (e) {
    return e;
  }
}

async function moveQuestionDown(payload) {
  try {
    return await request.put('/v1/question/move_question_down', {
      questionId: payload.questionId,
    });
  } catch (e) {
    return e;
  }
}

async function questionDeleteAPI(payload) {
  try {
    return await request.put('/v1/question/delete_question', {
      questionId: payload.questionId,
    });
  } catch (e) {
    return e;
  }
}

async function uploadQuestionFile(payload) {
  try {
    let formData = new FormData();
    formData.append('questionId', payload.request.data.questionId);
    formData.append('file', payload.request.file);
    let response = await request.post('/v1/question/upload_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        payload.request.onProgress({ percent: percentCompleted });
      },
    });

    if (response.data.status === 'success') {
      payload.request.onSuccess();
    }

    return response;
  } catch (e) {
    return e;
  }
}

async function removeQuestionFile(payload) {
  try {
    return await request.post('/v1/question/remove_file', {
      questionId: Number(payload.request.questionId),
      resourceId: Number(payload.request.uid),
    });
  } catch (e) {
    return e;
  }
}

async function upsertQuestionInstanceAPI(payload) {
  try {
    return await request.post('/v1/questioninstance/upsert', {
      question: payload.question,
      user: payload.user,
      flex: payload.flex,
      question_id_set: payload.question_set,
      last_question: payload.last_question,
    });
  } catch (e) {
    return e;
  }
}

async function closeQuestionSetInstance(payload) {
  try {
    return await request.put('/v1/questionset/closed_finished_test', {
      question_set_instance: payload.question_set_instance,
    });
  } catch (e) {
    return e;
  }
}

async function gradeQuestionInstance(payload) {
  try {
    return await request.post('/v1/questioninstance/grade', {
      id: payload.question_instance_id,
      grade: payload.grade,
    });
  } catch (e) {
    return e;
  }
}

async function getQuestionInstanceFromQsiAPI(payload) {
  try {
    return await request.post(
      '/v1/questionsetinstance/get_questions_from_question_set_instance',
      {
        question_set_instance: payload.question_set_instance,
      }
    );
  } catch (e) {
    return e;
  }
}

const questionSaga = {
  questionCreate: function* (action) {
    try {
      const response = yield call(createQuestion, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_CREATE_SUCCESS',
          payload: {
            question: response.data.question,
          },
          // normalized: normalize(response.data.question, questionSchema),
          // pushAssociatedKeys: [
          //   {entity: 'questionSets', associatedEntity: 'questions', entityKey: response.data.question.question_set, associatedKeys: [response.data.question.id]}
          // ]
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_CREATE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionUpdate: function* (action) {
    try {
      const response = yield call(updateQuestion, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({ type: 'QUESTION_UPDATE_SUCCESS', payload: response.data });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_UPDATE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      //console.log("A CATCH HERE?", e);
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionTypeFetch: function* (action) {
    try {
      const response = yield call(fetchQuestionTypes, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_TYPE_FETCH_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'QUESTION_TYPE_FETCH_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  closeQuestionSetInstance: function* (action) {
    try {
      const response = yield call(closeQuestionSetInstance, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'CLOSED_QUESTION_SET_INSTANCE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'CLOSED_QUESTION_SET_INSTANCE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionMoveUp: function* (action) {
    try {
      const response = yield call(moveQuestionUp, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({ type: 'QUESTION_MOVE_UP_SUCCESS', payload: response.data });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'QUESTION_MOVE_UP_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionMoveDown: function* (action) {
    try {
      const response = yield call(moveQuestionDown, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_MOVE_DOWN_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'QUESTION_MOVE_DOWN_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionDelete: function* (action) {
    try {
      const response = yield call(questionDeleteAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_DELETE_SUCCESS',
          removeEntityKeys: [
            { entity: 'questions', entityKeys: [response.data.questionId] },
          ],
          removeAssociatedKeys: [
            {
              entity: 'questionSets',
              associatedEntity: 'questions',
              entityKey: response.data.questionSetId,
              associatedKeys: [response.data.questionId],
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.data);
        }
      } else {
        yield put({ type: 'QUESTION_DELETE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionUploadFile: function* (action) {
    try {
      const response = yield call(uploadQuestionFile, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_UPLOAD_FILE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_UPLOAD_FILE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionRemoveFile: function* (action) {
    try {
      const response = yield call(removeQuestionFile, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_REMOVE_FILE_SUCCESS',
          payload: {
            resource: action.payload.request.uid,
            question: action.payload.request.questionId,
          },
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: 'QUESTION_REMOVE_FILE_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  questionInstanceUpsert: function* (action) {
    try {
      const response = yield call(upsertQuestionInstanceAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        //yield put({ type: "QUESTION_INSTANCE_UPSERT_SUCCESS", payload: response.data });
        yield put({
          type: 'QUESTION_INSTANCE_UPSERT_SUCCESS',
          normalized: normalize(
            response.data.questionInstance,
            questionInstanceSchema
          ),
        });
        yield put({
          type: 'QUESTION_SET_INSTANCE_UPSERT_SUCCESS',
          normalized: normalize(
            response.data.questionSetInstance,
            questionSetInstanceSchema
          ),
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({ type: 'QUESTION_INSTANCE_UPSERT_FAIL', payload: null });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },

  questionInstanceGrade: function* (action) {
    try {
      const response = yield call(gradeQuestionInstance, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'QUESTION_INSTANCE_GRADE_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({ type: 'QUESTION_INSTANCE_GRADE_FAIL', payload: null });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },

  getQuestionInstancesFromQsi: function* (action) {
    try {
      const response = yield call(
        getQuestionInstanceFromQsiAPI,
        action.payload
      );
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'GET_QUESTION_INSTANCES_FROM_QSI_SUCCESS',
          normalized: normalize(response.data.questionInstances, [
            questionInstanceSchema,
          ]),
        });
        if (action.onSuccess) action.onSuccess(response.data);
      } else {
        yield put({
          type: 'GET_QUESTION_INSTANCES_FROM_QSI_FAIL',
          payload: null,
        });
        if (action.onFail) action.onFail(response.data.errors);
      }
    } catch (e) {
      if (action.onFail) action.onFail(e);
    }
  },
};

export default questionSaga;
