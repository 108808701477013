import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { breadcrumbSet } from '../../actions/utilActions';
//import faCoffee from '@fortawesome/fontawesome-free-solid/faCoffee'
//import faAddressBook from '@fortawesome/fontawesome-pro-solid/faAddressBook'
import { Intent } from '@blueprintjs/core';
import {
  fetchReportOverview,
  fetchReportOverviewResults,
} from '../../actions/reportsActions';
import { Tooltip, Table } from 'antd';
import { arrayFromKeyedObject } from '../utils/sharedUtils';
// import { Spinner } from "@blueprintjs/core";
import Moment from 'react-moment';

import _ from 'lodash';
import { AppToaster } from '../common/AppToaster';

class ReportOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    this.props.breadcrumbSet([
      { name: 'Test', active: true, link: '/tests' },
      { name: 'Report Overview', active: false, link: null },
    ]);
    this.props.fetchReportOverviewResults(() => {
      this.setState({ initialized: true });
    });
  }

  render() {
    let overviewColumns = [
      {
        title: 'Restaurant',
        dataIndex: 'restaurant',
        render: (_text, record) => {
          console.log('this.props?.restaurants', this.props?.restaurants);
          if (!this.props?.restaurants) {
            return null;
          }
          return this.props?.restaurants[record.id]?.name;
        },
      },
    ];

    this.props.departmentsArr.map((dept) => {
      overviewColumns.push({
        title: dept.name,
        dataIndex: dept.name,
        render: (text, record) => {
          let entry = _.find(record.values, { department: dept.id });
          if (!entry) {
            return null;
          }
          return (
            <Tooltip
              placement="top"
              title={entry.users_passed + '/' + entry.total_users}
            >
              {entry.all_passed ? (
                <FontAwesomeIcon
                  style={{ cursor: 'pointer', color: 'green' }}
                  className="fa-lg"
                  icon={['far', 'check-circle']}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ cursor: 'pointer', color: 'red' }}
                  className="fa-lg"
                  icon={['far', 'times']}
                />
              )}
            </Tooltip>
          );
        },
      });
      return dept;
    });

    return (
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={overviewColumns}
          dataSource={this.props.tableData}
          pagination={{ pageSize: 50 }}
          title={() => (
            <div style={{ textAlign: 'right', minHeight: 60 }}>
              <h2 style={{ float: 'left', textAlign: 'left' }}>
                Test Completion Overview <br />
                {this.props.createdDate && (
                  <small>
                    Requested on:{' '}
                    <Moment format="LLL">{this.props.createdDate}</Moment>
                  </small>
                )}
              </h2>
              <button
                className="bp5-button bp5-large bp5-intent-primary"
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={() =>
                  this.props.fetchReportOverview(() =>
                    AppToaster.show({
                      message:
                        'Please allow a few minutes to generate the report and refresh the page',
                      intent: Intent.SUCCESS,
                      icon: 'tick',
                    })
                  )
                }
              >
                Request Report
              </button>
            </div>
          )}
          bordered={true}
          // loading={!this.state.initialized}
          footer={() =>
            'Hover over an entry to see number of users that have passed all required tests and the total users for the given rest/dept.'
          }
        />
      </div>
    );
  }
}

// Function to convert state as props
const mapStateToProps = (state) => {
  let tableData = [];
  let createdDate = null;
  for (var property in state.data.reportOverview) {
    if (state.data.reportOverview.hasOwnProperty(property)) {
      if (state.data.reportOverview[property][0] && !createdDate) {
        createdDate = state.data.reportOverview[property][0].createdAt;
      }
      tableData.push({
        id: property,
        values: state.data.reportOverview[property],
      });
    }
  }
  console.log('table data', tableData);
  return {
    createdDate,
    toaster: state.data.toaster,
    tableData: tableData,
    restaurants: state.data.restaurants,
    departmentsArr: arrayFromKeyedObject(state.data.departments),
    restaurantsArr: arrayFromKeyedObject(state.data.restaurants),
  };
};

// The dispatchers to call the Redux saga
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
    fetchReportOverview: (onSuccess, onFail) => {
      dispatch(fetchReportOverview(onSuccess, onFail));
    },
    fetchReportOverviewResults: (onSuccess, onFail) => {
      dispatch(fetchReportOverviewResults(onSuccess, onFail));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportOverview);
