import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

import { userFetch } from '../../actions/userActions';
import { restaurantFetch } from '../../actions/restaurantActions';
import { breadcrumbSet } from '../../actions/utilActions';
import { userHasRole } from '../utils/sharedUtils';
import UserTable from '../user/UserTable';

const UserPage = () => {
  const [state, setState] = useState({
    size: undefined,
    hasValue: undefined,
    intent: undefined,
    value: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.data.user);
  const roles = useSelector((state) => state.data.roles);
  const restaurants = useSelector((state) => state.data.restaurants);

  useEffect(() => {
    console.log('should set user breadcrumb');
    dispatch(breadcrumbSet([{ name: 'Users', active: true, link: '/users' }]));
  }, [dispatch]);

  const goNewManager = () => {
    navigate('/user/new');
  };

  const { size, hasValue, intent, value } = state;

  if (!restaurants) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spinner intent={intent} size={size} value={hasValue ? value : null} />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ textAlign: 'left', color: '#006666', position: 'relative' }}
      >
        {userHasRole('admin', user.roles, roles) ||
        userHasRole('sysadmin', user.roles, roles) ? (
          <button
            className="bp5-button bp5-large bp5-intent-primary"
            style={{ cursor: 'pointer', position: 'relative' }}
            onClick={goNewManager}
          >
            Add User
          </button>
        ) : null}
      </div>
      <div
        className="flexcenter"
        style={{ color: '#006666', position: 'relative', marginTop: 20 }}
      >
        <UserTable />
      </div>
    </div>
  );
};

export default UserPage;
