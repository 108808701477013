import React, { Component } from 'react';
import { connect } from "react-redux";
import { questionSetInstanceCreate, getQuestionSetInstances } from "../actions/questionSetActions";
import { arrayFromKeyedObject, safeToPercentStr } from '../components/utils/sharedUtils';
import { ProgressBar, Intent, Alert } from "@blueprintjs/core";
import { breadcrumbSet } from "../actions/utilActions";
import _ from 'lodash';
import PureGridBox24th from '../components/utils/PureGridBox24th';
import Spaner from '../components/utils/Spaner';
import cfg from '../config';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import faBadgeCheck from '@fortawesome/fontawesome-pro-regular/faBadgeCheck';
import faTimesCircle from '@fortawesome/fontawesome-pro-regular/faTimesCircle';
import withRouter from '../components/common/withRouter';
class TestingContainer extends Component {

  constructor(props) {
    super(props);

    let questionSetResources = [];
    let questionSet = this.props.questionSets[this.props.router.params.questionSetId];
    if (questionSet.questionSetResources) {
      let qsResources = _(arrayFromKeyedObject(this.props.questionSetResources))
        .filter((qs) => { return questionSet.questionSetResources.indexOf(qs.id) > -1; })
        .orderBy(['rank'], ['asc'])
        .value();
      if (qsResources && qsResources.length > 0) {
        questionSetResources = qsResources.map(qsr => { return this.props.resources[qsr.resource]; });
      }
    }

    this.state = {
      questionSet: questionSet,
      questionSetResources: questionSetResources,
      alertOpen: false,
      confirmButtonText: 'Accept'
    };
  }

  componentWillMount() {
    this.getNumberOfAttempts();
  }

  componentDidMount() {
    console.log("testing container mounted?")
    const formattingQuestionSetInstances = (questionSet) => {
      let _questionSet = questionSet;
      let questionSetInstances = arrayFromKeyedObject(this.props.questionSetInstances);
      let filteredQSInstances = _.filter(questionSetInstances, { question_set: parseInt(this.props.router.params.questionSetId, 10) });

      if (filteredQSInstances.length) {
        _questionSet.questionSetInstance = _.find(filteredQSInstances, { createdAt: _.maxBy(filteredQSInstances, 'createdAt').createdAt });
      }

      this.setState({
        questionSet: _questionSet
      });
    };

    this.props.breadcrumbSet([{ name: 'dashboard', active: true, link: '/dashboard' }, { name: this.state.questionSet.name, active: false, link: null }]);
    // Fetching the questiion instances
    this.props.getQuestionSetInstances(
      this.state.questionSet.id,
      () => {
        formattingQuestionSetInstances(this.state.questionSet);
      },
      null
    );
  }

  getNumberOfAttempts() {
    return () => {
      let questionSetInstances = arrayFromKeyedObject(this.props.questionSetInstances);
      let total_of_attempts = 0;

      questionSetInstances.forEach(qsi => {
        if (qsi.question_set === parseInt(this.props.router.params.questionSetId, 10)) {
          total_of_attempts += 1;
        }
      });
      return total_of_attempts;
    };
  }

  render() {

    const newQuestionSetInstance = () => {
      this.setState({
        alertOpen: true
      });
    };

    const goToQuestionSet = () => {
      if (this.props.openQuestionSetInstance) {let lastQuestionInstances = arrayFromKeyedObject(this.props.questionInstances);
        lastQuestionInstances = _.filter(lastQuestionInstances, { question_set_instance: this.props.openQuestionSetInstance.id });
        if (lastQuestionInstances.length === 0) {
          this.props.router.navigate('/' + this.state.questionSet.id + '/question/' + this.props.questionSets[this.props.router.params.questionSetId].questions[0]);
        }
        lastQuestionInstances = _.orderBy(lastQuestionInstances, ['createdAt', 'asc']);
        if (lastQuestionInstances.length === 0) {
          return this.props.router.navigate('/testing/' + this.state.questionSet.id + '/question/' + this.props.questionSets[this.props.router.params.questionSetId].questions[0]);
        }
        let lastQuestionId = lastQuestionInstances[lastQuestionInstances.length - 1].question; // Finding the last question instance answered by the user
        this.props.router.navigate('/testing/' + this.state.questionSet.id + '/question/' + lastQuestionId);
      }
      else { //first question
        this.props.router.navigate('/testing/' + this.state.questionSet.id + '/question/' + this.props.questionSets[this.props.router.params.questionSetId].questions[0]);
      }
    };

    const handleCancelStart = () => {
      this.setState({
        alertOpen: false
      });
    };

    const handleStartTest = () => {
      this.setState({
        alertOpen: false
      });
      this.props.questionSetInstanceCreate(
        this.props.user.id,
        parseInt(this.props.router.params.questionSetId, 10),
        () => {
          this.props.router.navigate('/testing/' + this.state.questionSet.id + '/question/' + this.state.questionSet.questions[0]);
        }
      );
    };

    let alertText = (
      <p>Are you sure you want to redo the test &nbsp;
        <b>{this.state.questionSet.name}?</b>
      </p>
    );
    let resourceColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return -1;
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return 1;
          return 0;
        },
        render: (text, record) => (
          <span
            style={{ cursor: 'pointer' }}
          >
            <a target="_blank" href={cfg.server_url + "/v1/protectedfile/download?subdir=question_set&file=" + record.asset.name}>{record.asset.textField || 'Study Resource'}</a>
          </span>
        )
      }
    ];

    let isClosed = _.get(this.state.questionSet, 'questionSetInstance.closed', false);
    let isPending = _.get(this.state.questionSet, 'questionSetInstance.flex.meta_data.pending_grading', false);
    let progress;
    let hasMultipleScores = this.state.questionSet.bestScoreInstance !== undefined;
    if (hasMultipleScores) {
      progress = _.get(this.state.questionSet, 'bestScoreInstance.flex.meta_data.progress', 0);
    } else {
      progress = _.get(this.state.questionSet, 'questionSetInstance.flex.meta_data.progress', 0);
    }

    const renderScore = (isClosed, isPending, hasMultipleScores) => {
      if (isClosed && !isPending) {
        if (hasMultipleScores) {
          return <div>
            <b>Best : {safeToPercentStr(_.get(this.state.questionSet, 'bestScoreInstance.flex.meta_data.percent_score', 0)) + "%"}</b> &nbsp;
            <b>Latest : {safeToPercentStr(_.get(this.state.questionSet, 'questionSetInstance.flex.meta_data.percent_score', 0)) + "%"}</b>
          </div>;
        } else {
          return <b> Score: {safeToPercentStr(_.get(this.state.questionSet, 'questionSetInstance.flex.meta_data.percent_score', 0)) + " %"}<br /> <br /></b>;
        }
      }
    };

    const renderGrading = (isClosed, isPending, hasMultipleScores) => {
      let passingScore = _.get(this.state.questionSet, 'passing_score', 0);
      let score;
      if (hasMultipleScores) {
        score = _.get(this.state.questionSet, 'bestScoreInstance.flex.meta_data.percent_score', 0);
      } else {
        score = _.get(this.state.questionSet, 'questionSetInstance.flex.meta_data.percent_score', 0);
      }
      if (isClosed) {
        if (score * 100 >= passingScore) {
          return (<b style={{ color: 'green' }}>
            <span style={{ position: 'relative', bottom: 20 }}>Passed</span>&nbsp;&nbsp;
            <FontAwesomeIcon icon={faBadgeCheck} size="5x" style={{ color: 'green' }} />
          </b>);
        } else {
          if (score < passingScore && !isPending) {
            return (<b style={{ color: 'red' }}>
              <span style={{ position: 'relative', bottom: 20 }}>Failed</span>&nbsp;&nbsp;
              <FontAwesomeIcon icon={faTimesCircle} size="5x" style={{ color: 'red' }} />
            </b>);
          } else {
            return (<b>"PENDING GRADING"</b>);
          }
        }
      }
    };

    return (
      <div>
        <div>
          <PureGridBox24th>
            <div style={{ fontSize: 14, width: '100%', marginTop: 18 }}>
              <h3><b>{this.state.questionSet.name}</b></h3>
              <hr />
            </div>
            <div style={{ textAlign: 'center' }}>
              <br /><br />
              <div>
                <h4>
                  Description:
                  <span><b> {this.state.questionSet.description} </b></span>
                </h4>
                <h4>
                  Number of attempts:
                  <span><b> {this.getNumberOfAttempts()} </b></span>
                </h4>
                Progress:
                <span style={{ width: '50%', display: 'inline-block' }}>
                  <ProgressBar
                    intent={Intent.PRIMARY}
                    stripes={false} animate={false}
                    value={progress}
                  />
                </span><br />
                {renderScore(isClosed, isPending, hasMultipleScores)}
                {renderGrading(isClosed, isPending, hasMultipleScores)}
              </div>
              <br />
              {
                this.props.questionSets[this.props.router.params.questionSetId].questions.length > 0 && !this.props.openQuestionSetInstance && !this.props.closedQuestionSetInstance ?
                  <button className="bp5-button bp5-large bp5-intent-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={handleStartTest} >
                    Start Test
                  </button>
                  :
                  this.props.questionSets[this.props.router.params.questionSetId].questions.length > 0 && this.props.openQuestionSetInstance ?
                    <button className="bp5-button bp5-large bp5-intent-primary"
                      style={{ cursor: 'pointer' }}
                      onClick={goToQuestionSet} >
                      Resume Test
                    </button>
                    :
                    this.props.questionSets[this.props.router.params.questionSetId].questions.length > 0 && this.props.closedQuestionSetInstance ?
                      <button className="bp5-button bp5-large bp5-intent-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={newQuestionSetInstance} >
                        Redo the test
                      </button>
                      :
                      null
              }

              <Spaner width={'sm'} />
            </div>
            <br /><br />
            <Table
              rowKey={record => record.id}
              columns={resourceColumns}
              dataSource={this.state.questionSetResources}
              title={() => <h2>Study Resources</h2>}
              bordered={true}
            />

          </PureGridBox24th>
          <Alert
            cancelButtonText="Cancel"
            confirmButtonText={this.state.confirmButtonText}
            icon="changes"
            intent={Intent.PRIMARY}
            isOpen={this.state.alertOpen}
            onCancel={handleCancelStart}
            onConfirm={handleStartTest}>
            {alertText}
          </Alert>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  let questionSetInstances = arrayFromKeyedObject(state.data.questionSetInstances);
  let openQuestionSetInstance = _.find(questionSetInstances, { question_set: parseInt(ownProps.router.params.questionSetId, 10), closed: false });
  let closedQuestionSetInstance = _.find(questionSetInstances, { question_set: parseInt(ownProps.router.params.questionSetId, 10), closed: true });
  let questionInstances = [];
  if (openQuestionSetInstance) {
    questionInstances = _.filter(state.data.questionInstances, { question_set_instance: openQuestionSetInstance.id });
  }

  return {
    user: state.data.user,
    questionSet: state.data.questionSets[parseInt(ownProps.router.params.questionSetId, 10)],
    questionSets: state.data.questionSets,
    questionSetResources: state.data.questionSetResources,
    questionInstances: questionInstances,
    questionSetInstances: state.data.questionSetInstances,
    openQuestionSetInstance: openQuestionSetInstance, //represents an open question set in progress
    closedQuestionSetInstance: closedQuestionSetInstance,
    questions: state.data.questions,
    resources: state.data.resources
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuestionSetInstances: (question_set, onSuccess, onFail) => {
      dispatch(getQuestionSetInstances(question_set, true, onSuccess, onFail));
    },
    questionSetInstanceCreate: (userId, questionSetId, onSuccess, onFail) => {
      dispatch(questionSetInstanceCreate(userId, questionSetId, onSuccess, onFail));
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestingContainer));
