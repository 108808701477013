import { Upload, Modal } from 'antd';
import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
class PicturesWallUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: this.props.accept,
      previewVisible: false,
      previewImage: '',
      fileList: this.props.fileList,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        fileList: this.props.fileList,
      });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { maxFiles } = this.props;

    const uploadButton = (
      <div>
        <PlusOutlined style={{ fontSize: '16px', color: '#108ee9' }} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div style={this.props.style} className="clearfix">
        <Upload
          accept={this.props.accept}
          customRequest={this.props.uploadFile}
          listType="picture-card"
          fileList={fileList}
          data={this.props.data}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.props.remove}
          disabled={this.props.disabled}
        >
          {fileList.length >= maxFiles ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PicturesWallUpload;
