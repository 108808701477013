import { call, put } from "redux-saga/effects";
import request from '../utils/api/ServerRequest';


async function runTest() {
    return await request.get('/v1/test/connection');
}

const testSaga = {

    testConnection: function* (action) {
        try {
            const response = yield call(runTest);
            yield put({ type: "TEST_CONNECTION_SUCCESS", payload: response });
            if (action.onSuccess) {
                action.onSuccess()
            }
        } catch (e) {

        }
    }

}


export default testSaga