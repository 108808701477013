import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Intent, MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { userCreate, userUpdate, userGetData } from '../../actions/userActions';
import { arrayFromKeyedObject, arrayFromKeys } from '../utils/sharedUtils';
import { getIntent } from '../utils/validate';
import PureGridBox24th from '../utils/PureGridBox24th';
import Cellbox from '../utils/Cellbox';
import MultiSelectWrapper from '../utils/MultiSelectWrapper';
import { breadcrumbSet } from '../../actions/utilActions';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';
class UpsertUser extends Component {
  constructor(props) {
    super(props);

    let editMode = false;
    let id = null;
    let first_name = '';
    let last_name = '';
    let email = '';
    // let roles_default = []; // Roles to use inside the creation
    let roles = [];
    let departments = [];
    let restaurants = [];

    if (this.props.router.params.userId) {
      let user = this.props.users[this.props.router.params.userId];
      id = user.id;
      first_name = user.first_name;
      last_name = user.last_name;
      departments = arrayFromKeys(this.props.departments, user.departments);
      restaurants = arrayFromKeys(this.props.restaurants, user.restaurants);
      roles = arrayFromKeys(this.props.roles, user.roles);
      editMode = true;
    }

    this.state = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      departments: departments,
      restaurants: restaurants,
      roles: roles,
      temp_password: '',
      editMode: editMode,
      popoverMinimal: true,
      tagMinimal: false,
      validation: {
        first_name: {
          rules: [{ name: 'isString' }],
          isValid: true,
          errors: [],
        },
        last_name: { rules: [{ name: 'isString' }], isValid: true, errors: [] },
        email: { rules: [{ name: 'isString' }], isValid: true, errors: [] },
      },
    };
  }

  componentDidMount() {
    this.props.breadcrumbSet([
      { name: 'Users', active: true, link: '/users' },
      {
        name: `${this.state.editMode ? 'Edit' : 'New'} user`,
        active: false,
        link: null,
      },
    ]);
    // this.props.breadcrumbSet([
    //   { name: 'dashboard', active: true, link: '/admindashboard' },
    //   { name: 'user', active: false, link: null },
    // ]);
  }

  handleSubmit = (event) => {
    const onSuccess = (user) => this.handleSuccess(user);
    const onFail = () => this.handleFail();
    if (this.state.editMode === false) {
      this.props.userCreate(
        this.state.first_name,
        this.state.last_name,
        this.state.email,
        _.map(this.state.restaurants, 'id'),
        _.map(this.state.departments, 'id'),
        _.map(this.state.roles, 'name'),
        onSuccess,
        onFail
      );
    } else {
      let temp_password = '';
      if (this.state.temp_password !== '') {
        if (this.state.temp_password !== this.state.confirm_temp_password) {
          this.handleFail('Password confirmation does not match');
          return;
        }
        temp_password = this.state.temp_password;
      }
      this.props.userUpdate(
        this.state.id,
        this.state.first_name,
        this.state.last_name,
        _.map(this.state.restaurants, 'id'),
        _.map(this.state.departments, 'id'),
        _.map(this.state.roles, 'id'),
        temp_password,
        onSuccess,
        onFail
      );
    }
  };

  handleSuccess = (user) => {
    let actionSpecific = this.state.editMode ? 'updated' : 'created';
    AppToaster.show({
      message: 'User ' + actionSpecific + ' successfully',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
    if (user && user.id === this.props.user.id) {
      this.props.userGetData();
    }
    console.log('push to dash now!');
    this.props.router.navigate('/users');
  };

  handleFail = () => {
    this.state.editMode
      ? AppToaster.show({
          message: 'User update failed',
          intent: Intent.DANGER,
          icon: 'warning-sign',
        })
      : AppToaster.show({
          message: 'User update failed',
          intent: Intent.DANGER,
          icon: 'warning-sign',
        });
  };

  getTagProps = (_value, index) => ({
    minimal: this.state.tagMinimal,
  });

  handleTagRemove = (tag) => {
    let _restaurants = this.state.restaurants;
    let index = _.findIndex(
      this.state.restaurants,
      (restaurant) => restaurant.name === tag
    );
    if (index !== -1) {
      _restaurants.splice(index, 1);
      this.setState({
        restaurants: _restaurants,
      });
    }
  };

  setDepartments = (departments) => {
    this.setState({
      departments: departments,
    });
  };

  setRestaurants = (restaurants) => {
    this.setState({
      restaurants: restaurants,
    });
  };

  handleStateChange(event, attribute) {
    this.setState({
      [attribute]: event.target.value,
    });
  }

  setRoles = (role) => {
    //  let role = _.find(this.props.assignable_roles, {id: role.id})
    this.setState({
      roles: [role],
    });
    //   let role = roles[roles.length - 1]; // Capturing the last role selected due to it sould select one
    //   let _roles = []; // Local variable to save the role
    //   _roles.push(role); // Pushing the role selected
    //   this.setState({
    //       roles: _roles
    //   })
  };

  renderItem = (item) => {
    return (
      <MenuItem
        active={_.findIndex(this.state.roles, { id: item.id }) !== -1}
        key={item.id}
        onClick={(e) => this.setRoles(item)}
        text={item.name}
        shouldDismissPopover={false}
      />
      // <div key={item.id}>{item.value}</div>
    );
  };

  render() {
    return (
      <PureGridBox24th>
        <div style={{ color: 'black', textAlign: 'center', fontSize: 14 }}>
          <br />
          <Cellbox
            label="First name"
            validation={this.state.validation.first_name}
          >
            <input
              className={
                'bp5-input bp5-large  ' +
                getIntent(this.state.validation, 'first_name')
              }
              type="text"
              value={this.state.first_name}
              onChange={(e) => this.handleStateChange(e, 'first_name')}
            />
          </Cellbox>
          <br />
          <Cellbox
            label="Last name"
            validation={this.state.validation.last_name}
          >
            <input
              className={
                'bp5-input bp5-large ' +
                getIntent(this.state.validation, 'last_name')
              }
              type="text"
              value={this.state.last_name}
              onChange={(e) => this.handleStateChange(e, 'last_name')}
            />
          </Cellbox>

          <br />
          {this.state.editMode === false ? (
            <Cellbox label="Email" validation={this.state.validation.email}>
              <input
                className={
                  'bp5-input bp5-large ' +
                  getIntent(this.state.validation, 'email')
                }
                type="text"
                value={this.state.email}
                onChange={(e) => this.handleStateChange(e, 'email')}
              />
            </Cellbox>
          ) : null}

          <br />

          <Cellbox
            label="Departments"
            validation={this.state.validation.departments}
          >
            <MultiSelectWrapper
              initialItems={this.state.departments}
              items={arrayFromKeyedObject(this.props.departments)}
              onChange={this.setDepartments}
            ></MultiSelectWrapper>
          </Cellbox>
          <br />

          <Cellbox
            label="Restaurants"
            validation={this.state.validation.restaurants}
          >
            <MultiSelectWrapper
              initialItems={this.state.restaurants}
              items={arrayFromKeyedObject(this.props.restaurants)}
              onChange={this.setRestaurants}
            ></MultiSelectWrapper>
          </Cellbox>
          <br />

          <Cellbox label="Role">
            <Select
              className="bp5-large"
              filterable={false}
              item={this.state.roles[0] || null}
              items={this.props.assignable_roles}
              itemRenderer={(data) => this.renderItem(data)}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={(e) => {}}
              closeOnSelect={true}
            >
              <Button
                text={_.get(this.state, 'roles[0].name', 'select a role')}
                rightIcon="double-caret-vertical"
              />
            </Select>
            {/* <MultiSelectWrapper
                            initialItems={this.state.roles || []}
                            items={this.props.assignable_roles}
                            onChange={(e) => {
                                e.length > 1 ? this.setRoles(e.splice(0, 1)) : this.setRoles(e); 
                            }}
                        >
                        </MultiSelectWrapper> */}
          </Cellbox>
          <br />

          {this.state.editMode ? (
            <Cellbox label="New temporary password">
              <input
                className={'bp5-input bp5-large '}
                type="password"
                value={this.state.temp_password}
                onChange={(e) => this.handleStateChange(e, 'temp_password')}
              />
            </Cellbox>
          ) : null}
          <br />

          {this.state.editMode ? (
            <Cellbox label="Confirm temporary password">
              <input
                className={'bp5-input bp5-large '}
                type="password"
                value={this.state.confirm_temp_password}
                onChange={(e) =>
                  this.handleStateChange(e, 'confirm_temp_password')
                }
              />
            </Cellbox>
          ) : null}
          <br />

          <div
            className="flexcenter"
            style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
          >
            <button
              className="bp5-button bp5-large bp5-intent-primary"
              style={{ cursor: 'pointer', position: 'relative', top: 20 }}
              onClick={() => this.handleSubmit()}
            >
              {this.state.editMode === true ? (
                <span>Update</span>
              ) : (
                <span>Create</span>
              )}
            </button>
          </div>
        </div>
      </PureGridBox24th>
    );
  }
}

const mapStateToProps = (state) => {
  let assignable_roles = arrayFromKeyedObject(state.data.roles);
  assignable_roles = _.filter(assignable_roles, (role) => {
    return role.name !== 'sysadmin';
  });

  return {
    user: state.data.user,
    users: state.data.users,
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    roles: state.data.roles,
    assignable_roles: assignable_roles,
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userCreate: (
      first_name,
      last_name,
      email,
      restaurants,
      departments,
      roles,
      onSuccess,
      onFail
    ) => {
      dispatch(
        userCreate(
          first_name,
          last_name,
          email,
          restaurants,
          departments,
          roles,
          onSuccess,
          onFail
        )
      );
    },
    userUpdate: (
      id,
      first_name,
      last_name,
      restaurants,
      departments,
      roles,
      temp_password,
      onSuccess,
      onFail
    ) => {
      dispatch(
        userUpdate(
          id,
          first_name,
          last_name,
          restaurants,
          departments,
          roles,
          null,
          null,
          null,
          temp_password,
          onSuccess,
          onFail
        )
      );
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
    userGetData: (onSuccess, onFail) => {
      dispatch(userGetData(onSuccess, onFail));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpsertUser));
