import React from 'react';

const Spaner = ({ text, highlight, highlightClass }) => {
  var parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span>
    {parts.map((part, i) => part.toLowerCase() === highlight.toLowerCase() ?
      <span className={highlightClass} key={i}>{part}</span>
      :
      <span key={i}>{part}</span>)}
  </span>;
};

export default Spaner;