import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "@blueprintjs/core";
import pjson from '../../../package.json';
import QuestionSetTable from '../question/QuestionSetTable';
import UserTable from '../user/UserTable';
import Spaner from '../utils/Spaner';
import { departmentFetch } from "../../actions/departmentActions";
import { restaurantFetch } from "../../actions/restaurantActions";
import { questionTypeFetch } from "../../actions/questionActions";
import { userFetch } from "../../actions/userActions";
import { breadcrumbSet } from "../../actions/utilActions";
import { questionSetFetch } from "../../actions/questionSetActions";
import { connect } from "react-redux";

const ManagerDashboard = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();



  useEffect(() => {
    console.log("MOUNTING THIS MG DASH?")
    dispatch(userFetch());
    // dispatch(departmentFetch());
    // dispatch(questionTypeFetch());
    // dispatch(restaurantFetch());
    // dispatch(questionSetFetch(true, [], []));
    // dispatch(breadcrumbSet([{ name: 'dashboard', active: true, link: '/admindashboard' }]));

 }, []);

 return (<div>GREAT</div>)

//  const users = useSelector(state => state.data.users);
//  const restaurants = useSelector(state => state.data.restaurants);
//  const departments = useSelector(state => state.data.departments);

//   if (!users || !restaurants || !departments) {
//     return (
//       <div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
//         <Spinner />
//       </div>
//     );
//   }
//   console.log("DATA NOW 2", users, restaurants, departments)
//   const goNewManager = () => navigate('/user/new');
//   return (
//     <div>
//       <div className="flexcenter" style={{ fontSize: 14, color: '#006666', marginTop: 20 }}>
//         <b>HCR Training: v{pjson.version}</b>
//       </div>

//       <div className="flexcenter" style={{ color: '#006666', marginTop: 20 }}>
//         <UserTable />
//       </div>

//       <div className="flexcenter" style={{ color: '#006666', marginTop: 30 }}>
//         <QuestionSetTable />
//       </div>

//       <div className="flexcenter" style={{ color: '#006666', marginTop: 10 }}>
//         <button className="bp5-button bp5-large bp5-intent-primary" onClick={goNewManager}>New Test</button>
//         <Spaner width="sm" />
//         <button className="bp5-button bp5-large bp5-intent-primary" onClick={() => navigate('/admin/report')}>Report View</button>
//       </div><br />
//     </div>
//   );
};

// const mapStateToProps = (state) => ({
//   user: state.data.user,
//   users: state.data.users,
//   questionSets: state.data.questionSets,
//   restaurants: state.data.restaurants,
//   departments: state.data.departments,
//   roles: state.data.roles,
// });

// const mapDispatchToProps = {
//   userFetch,
//   questionSetFetch,
//   breadcrumbSet,
//   departmentFetch,
//   restaurantFetch,
//   questionTypeFetch,
// };

export default ManagerDashboard;
