import React, { useEffect } from 'react';
import ChartJs from '../../utils/chart';

const LineGraph = (props) => {
  useEffect(() => {
    const ctx = document.getElementById(props.uuid);
    const ctx2 = ctx.getContext('2d');
    const gradient = ctx2.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgb(239,244,251)');
    gradient.addColorStop(1, 'rgb(255,255,255)');

    props.chartData.datasets[0].backgroundColor = gradient;

    const chartInstance = new ChartJs.Chart(ctx, {
      type: 'line',
      data: {
        labels: props.chartData.labels,
        datasets: props.chartData.datasets,
      },
      options: {
        maintainAspectRatio: false,
        spanGaps: false, // Do not connect gaps
        plugins: {
          title: {
            display: false,
            text: props.title,
          },
          tooltip: {
            callbacks: {
              title: (context) => {
                const title = context[0].label;
                return title.split(',').slice(0, 2).join(',');
              },
              label: (context) => {
                const label = context.dataset.label || '';
                const yAxis = context.formattedValue;

                return label
                  ? `${label}: ${props.isCurrency ? `$${yAxis}` : `${yAxis} Items`}`
                  : `${yAxis}`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
        scales: props.scales || {
          x: {
            type: 'time', // Ensure proper time series handling
            time: {
              unit: 'day', // Customize granularity
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10, // Adjust for better readability
            },
          },
          y: {
            beginAtZero: true, // Start Y-axis at 0
          },
        },
        elements: {
          point: {
            radius: 4, // Ensure points are visible
            hoverRadius: 7,
            backgroundColor: '#2c73bc',
          },
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [props]);

  return (
    <div>
      <canvas id={props.uuid} width={props.width || 300} height={props.height || 100} />
    </div>
  );
};

export default LineGraph;
