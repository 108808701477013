import _ from 'lodash';
import { normalize, schema } from 'normalizr';
import { arrayFromKeyedObject } from '../components/utils/sharedUtils';
import { autoReduce } from '../reducers/shared';
const questionSchema = new schema.Entity('questions');
const questionTypeSchema = new schema.Entity('questionTypes');
const departmentSchema = new schema.Entity('departments');
const restaurantSchema = new schema.Entity('restaurants');
const resourceSchema = new schema.Entity('resources');
const roleSchema = new schema.Entity('roles');
const questionSetSchema = new schema.Entity('questionSets', {
  questions: [questionSchema],
  departments: [departmentSchema],
  restaurants: [restaurantSchema],
});
const questionSetResourceSchema = new schema.Entity('questionSetResources');
const reportSetSchema = new schema.Entity('questionSets');
const userSchema = new schema.Entity('user', {
  departments: [departmentSchema],
  restaurants: [restaurantSchema],
  roles: [roleSchema],
});
const usersSchema = new schema.Entity('users', {
  departments: [departmentSchema],
  restaurants: [restaurantSchema],
  roles: [roleSchema],
});
const questionInstanceSchema = new schema.Entity('questionInstances');
const questionSetInstanceSchema = new schema.Entity('questionSetInstances');
const initialStateSchema = {
  user: {},
  users: {},
  questionSets: {},
  questionSetResources: {},
  questionSetInstances: {},
  questionTypes: {},
  questions: {},
  questionInstances: {},
  resources: {},
  roles: {},
  toaster: {},
  reportOverview: [],
  reportSelectedDepartments: [],
  reportSelectedRestaurants: [],
};

let initialState = initialStateSchema;

// function logOnAction (actionType, str){
//   let action = "QUESTION_SET_INSTANCES_WITH_QUESTION_INSTANCES_SUCCESS"
//   if (action === actionType){
//     console.log(str)
//   }
// }

const data = (state = initialState, action) => {
  if (action.normalized || action.removeKeys || action.overrides) {
    return autoReduce(action, state);
  }

  let stateReturn = {
    ...state,
  };
  let normalized;

  if (action.pushAssociatedKeys) {
    _.forEach(action.pushAssociatedKeys, (pK) => {
      if (stateReturn[pK.entity][pK.entityKey]) {
        let existingKeys =
          stateReturn[pK.entity][pK.entityKey][pK.associatedEntity] || [];
        stateReturn[pK.entity][pK.entityKey][pK.associatedEntity] = _.union(
          existingKeys,
          pK.associatedKeys
        );
      }
    });
  }

  if (action.removeEntityKeys) {
    _.forEach(action.removeEntityKeys, (rK) => {
      _.forEach(rK.entityKeys, (key) => {
        if (stateReturn[rK.entity][key]) {
          delete stateReturn[rK.entity][key];
        }
      });
    });
  }

  if (action.removeAssociatedKeys) {
    _.forEach(action.removeAssociatedKeys, (aK) => {
      if (stateReturn[aK.entity][aK.entityKey]) {
        _.forEach(aK.associatedKeys, (key) => {
          stateReturn[aK.entity][aK.entityKey][aK.associatedEntity] = _.remove(
            stateReturn[aK.entity][aK.entityKey][aK.associatedEntity],
            (kk) => {
              return kk !== key;
            }
          );
        });
      }
    });
  }

  if (action.normalized) {
    Object.keys(action.normalized.entities).forEach(function (key) {
      stateReturn[key] = {
        ...stateReturn[key],
        ...action.normalized.entities[key],
      };
    });
    return stateReturn;
  }
  switch (action.type) {
    case 'GET_CSRF_SUCCESS':
      return _.mergeWith({}, state, { _csrf: action.csrf });
    case 'USER_LOGIN_SUCCESS':
      if (action.payload.id) {
        let user = normalize(action.payload, userSchema).entities.user;
        user = user[Object.keys(user)[0]];
        stateReturn.user = user;
      } else {
        stateReturn.user = {};
      }
      break;
    case 'USER_LOGIN_FAIL':
    case 'USER_GET_DATA_SUCCESS':
      // if (action.payload.id) {
      //   let user = normalize(action.payload, userSchema).entities.user;
      //   user = user[Object.keys(user)[0]];
      //   stateReturn.user = user
      // }
      // else {
      //   stateReturn.user = {}
      // }
      break;
    case 'USER_REGISTER_SUCCESS':
    case 'USER_REGISTER_FAIL':
      stateReturn = { ...state, user: Object.assign({}, action.payload) };
      break;
    case 'USER_UPDATE_SUCCESS_2':
      console.log('action.payload: ', action.payload);
      console.log('state.user: ', state.user);
      stateReturn = {
        ...state,
        user: { ...state.user, ...action.payload },
        users: {
          ...state.users,
          [action.payload.id]: action.payload,
        },
      };
      break;
    // case 'USER_UPDATE_FAIL':
    // case 'USER_CREATE_SUCCESS':
    // case 'USER_CREATE_FAIL':
    //   stateReturn = { ...state, user: Object.assign({}, action.payload) };
    //   break;
    case 'USER_LOGOUT_SUCCESS':
    case 'USER_LOGOUT_FAIL':
      //localStorage.clear();
      return initialStateSchema;
    case 'USER_FETCH_SUCCESS':
      let normalizedUsers = normalize(action.payload, [usersSchema]);
      stateReturn = { ...state, users: normalizedUsers.entities.users };
      break;
    case 'USER_FETCH_FAIL':
      stateReturn = { ...state, users: action.payload }; //Object.assign({}, action.payload);
      break;
    case 'USER_PASSWORD_RESET_REQUEST_SUCCESS':
    case 'USER_PASSWORD_RESET_REQUEST_FAIL':
    case 'USER_VALIDATE_TOKEN_SUCCESS':
    case 'USER_VALIDATE_TOKEN_FAIL':
    case 'USER_PASSWORD_RESET_SUCCESS':
    case 'USER_PASSWORD_RESET_FAIL':
      break;
    case 'QUESTION_SET_FETCH_SUCCESS':
      let normalizedQuestionSets = normalize(action.payload.questionSets, [
        questionSetSchema,
      ]);
      let normalizedResources = normalize(action.payload.resources, [
        resourceSchema,
      ]);
      let normalizedQuestionSetResources = normalize(
        action.payload.questionSetResources,
        [questionSetResourceSchema]
      );

      stateReturn = {
        ...state,
        questionSets: normalizedQuestionSets.entities.questionSets || {},
        questions: normalizedQuestionSets.entities.questions || {},
        resources: normalizedResources.entities.resources || {},
        questionSetResources: _.get(
          normalizedQuestionSetResources,
          'entities.questionSetResources',
          {}
        ),
      };
      break;
    case 'QUESTION_SET_UPDATE_SUCCESS':
      stateReturn = {
        ...state,
        questionSets: {
          ...state.questionSets,
          [action.payload.questionSet.id]: normalize(
            action.payload.questionSet,
            questionSetSchema
          ).entities.questionSets[action.payload.questionSet.id],
        },
      };
      break;
    case 'QUESTION_SET_FETCH_FAIL':
      break;
    case 'QUESTION_SET_CREATE_SUCCESS':
      stateReturn = {
        ...state,
        questionSets: {
          ...state.questionSets,
          [action.payload.questionSet.id]: action.payload.questionSet,
        },
      };
      break;
    case 'QUESTION_SET_CREATE_FAIL':
      break;
    case 'QUESTION_SET_UPLOAD_FILE_SUCCESS':
      state.resources[action.payload.data.resource.id] =
        action.payload.data.resource;
      state.questionSetResources[action.payload.data.questionSetResource.id] =
        action.payload.data.questionSetResource;
      if (
        action.payload.data.questionSetId !== undefined &&
        action.payload.data.questionSetId !== null
      ) {
        if (
          !state.questionSets[action.payload.data.questionSetId]
            .questionSetResources
        ) {
          state.questionSets[
            action.payload.data.questionSetId
          ].questionSetResources = [];
        }
        state.questionSets[
          action.payload.data.questionSetId
        ].questionSetResources.push(action.payload.data.questionSetResource.id);
      }
      stateReturn = { ...state };
      break;
    case 'QUESTION_SET_UPLOAD_FILE_FAIL':
      break;
    case 'QUESTION_SET_RESOURCE_MOVE_UP_SUCCESS':
      stateReturn = { ...state };
      let questionSetResource =
        stateReturn.questionSetResources[
          action.payload.data.questionSetResourceId
        ];
      let questionSetResourceAbove = _.find(
        arrayFromKeyedObject(stateReturn.questionSetResources),
        {
          question_set: questionSetResource.question_set,
          rank: questionSetResource.rank - 1,
        }
      );
      stateReturn.questionSetResources[questionSetResource.id].rank =
        questionSetResource.rank - 1;
      stateReturn.questionSetResources[questionSetResourceAbove.id].rank =
        questionSetResourceAbove.rank + 1;
      break;
    case 'QUESTION_SET_RESOURCE_DELETE_SUCCESS':
      stateReturn = Object.assign({}, state);
      stateReturn.questionSets[
        action.payload.data.questionSetId
      ].questionSetResources = _.remove(
        stateReturn.questionSets[action.payload.data.questionSetId]
          .questionSetResources,
        (qsr) => {
          return (
            Number(qsr) !== Number(action.payload.data.questionSetResourceId)
          );
        }
      );

      stateReturn.questionSetResources = removeKey(
        stateReturn.questionSetResources,
        Number(action.payload.data.questionSetResourceId)
      );
      stateReturn.questionSetResources = {
        ...stateReturn.questionSetResources,
        ...normalize(
          _(arrayFromKeyedObject(stateReturn.questionSetResources))
            .filter(
              (qsr) => qsr.question_set === action.payload.data.questionSetId
            )
            .orderBy(['rank'], ['asc'])
            .map((qsr, i) => {
              qsr.rank = i;
              return qsr;
            })
            .value(),
          [questionSetResourceSchema]
        ).entities.questionSetResources,
      };

      if (action.payload.data.resourceDeleted === true) {
        stateReturn.resources = removeKey(
          stateReturn.resources,
          action.payload.data.resourceId
        );
      }
      break;
    case 'QUESTION_SET_RESOURCE_MOVE_UP_FAIL':
      break;
    case 'QUESTION_SET_RESOURCE_MOVE_DOWN_SUCCESS':
      let qsResource =
        stateReturn.questionSetResources[
          action.payload.data.questionSetResourceId
        ];
      let questionSetResourceBelow = _.find(
        arrayFromKeyedObject(stateReturn.questionSetResources),
        { question_set: qsResource.question_set, rank: qsResource.rank + 1 }
      );
      stateReturn.questionSetResources[qsResource.id].rank =
        qsResource.rank + 1;
      stateReturn.questionSetResources[questionSetResourceBelow.id].rank =
        questionSetResourceBelow.rank - 1;
      break;
    case 'QUESTION_SET_RESOURCE_MOVE_DOWN_FAIL':
      break;
    case 'QUESTION_SET_GRADING_SUCCESS':
      let normalizedQuestionSetInstances = normalize(
        action.payload.questionSetInstances,
        [questionSetInstanceSchema]
      );
      let normalizedQuestionInstances = normalize(
        action.payload.questionInstances,
        [questionInstanceSchema]
      );

      stateReturn = {
        ...state,
        questionSetInstances: {
          ...state.questionSetInstances,
          ...(normalizedQuestionSetInstances.entities.questionSetInstances ||
            {}),
        },
        questionInstances: {
          ...state.questionInstances,
          ...(normalizedQuestionInstances.entities.questionInstances || {}),
        },
      };
      break;
    case 'QUESTION_INSTANCE_GRADE_SUCCESS':
      state.questionInstances[action.payload.questionInstanceId].flex.grade =
        Number(action.payload.grade);
      stateReturn = { ...state };
      break;
    case 'QUESTION_SET_INSTANCES_WITH_QUESTION_INSTANCES_FAIL':
      break;
    case 'QUESTION_SET_INSTANCES_CREATE_SUCCESS':
      break;
    // case 'QUESTION_SET_INSTANCES_CREATE_SUCCESS':
    //   stateReturn = {
    //     ...state,
    //     questionInstances: {
    //       ...state.questionInstances,
    //     }
    //   }
    //   break;
    case 'QUESTION_SET_INSTANCES_CREATE_FAIL':
      break;
    case 'QUESTION_INSTANCE_UPSERT_SUCCESS':
      // let questionSetInstances = state.questionSetInstances;
      // if (action.payload.questionSetInstance) {
      //   questionSetInstances[action.payload.questionSetInstance.id] = action.payload.questionSetInstance
      // }

      // stateReturn = {
      //   ...state,
      //   questionInstances: {
      //     ...state.questionInstances,
      //     [action.payload.questionInstance.id]: action.payload.questionInstance
      //   },
      //   questionSetInstances: questionSetInstances
      // }
      break;
    case 'QUESTION_INSTANCE_UPSERT_FAIL':
      break;
    case 'QUESTION_CREATE_SUCCESS':
      state.questionSets[action.payload.question.question_set].questions.push(
        action.payload.question.id
      );
      stateReturn = {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.question.id]: normalize(
            action.payload.question,
            questionSchema
          ).entities.questions[action.payload.question.id],
        },
        questionSets: state.questionSets,
      };
      break;
    case 'QUESTION_CREATE_FAIL':
      break;
    case 'IS_QUESTION_SET_CLOSED_SUCCESS':
      break;
    case 'QUESTION_UPDATE_SUCCESS':
      console.log(
        'QUESTION_UPDATE_SUCCESS action.payload.question: ',
        action.payload.question
      );
      const oldQuestion = action.payload.question?.id
        ? state.questions[action.payload.question.id]
        : {};
      const modifiedQuestion = { ...oldQuestion, ...action.payload.question };
      stateReturn = {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.question.id]: modifiedQuestion,
        },
      };
      break;
    case 'QUESTION_TYPE_FETCH_SUCCESS':
      normalized = normalize(action.payload.questionTypes, [
        questionTypeSchema,
      ]);
      stateReturn = {
        ...state,
        questionTypes: normalized.entities.questionTypes,
      };
      break;
    case 'QUESTION_TYPE_FETCH_FAIL':
      break;
    case 'QUESTION_MOVE_DOWN_SUCCESS':
      let questionMoveDownId = action.payload.question.id;
      let questionToMoveDownRank = state.questions[questionMoveDownId].rank;
      let rankBelow = questionToMoveDownRank + 1;
      let belowRankedQuestion = _.find(state.questions, {
        rank: rankBelow,
        question_set: state.questions[questionMoveDownId].question_set,
      });

      state.questions[questionMoveDownId].rank = rankBelow;
      state.questions[belowRankedQuestion.id].rank = questionToMoveDownRank;
      stateReturn = { ...state };
      break;
    case 'QUESTION_MOVE_DOWN_FAIL':
      break;
    case 'QUESTION_MOVE_UP_SUCCESS':
      let questionMoveUpId = action.payload.question.id;
      let questionToMoveUpRank = state.questions[questionMoveUpId].rank;
      let rankAbove = questionToMoveUpRank - 1;
      let aboveRankedQuestion = _.find(state.questions, {
        rank: rankAbove,
        question_set: state.questions[questionMoveUpId].question_set,
      });

      state.questions[questionMoveUpId].rank = rankAbove;
      state.questions[aboveRankedQuestion.id].rank = questionToMoveUpRank;
      stateReturn = { ...state };
      break;
    case 'QUESTION_MOVE_UP_FAIL':
      break;
    case 'QUESTION_UPLOAD_FILE_SUCCESS':
      state.resources[action.payload.data.resource.id] =
        action.payload.data.resource;
      if (!state.questions[action.payload.data.questionId].resources) {
        state.questions[action.payload.data.questionId].resources = [];
      }
      state.questions[action.payload.data.questionId].resources.push(
        action.payload.data.resource.id
      );
      stateReturn = { ...state };
      break;
    case 'QUESTION_UPLOAD_FILE_FAIL':
      break;
    case 'QUESTION_REMOVE_FILE_SUCCESS':
      let resourceIndex = state.questions[
        action.payload.question
      ].resources.indexOf(action.payload.resource);
      if (resourceIndex > -1) {
        state.questions[action.payload.question].resources.splice(
          resourceIndex,
          1
        );
      }
      stateReturn = { ...state };
      break;
    case 'QUESTION_REMOVE_FILE_FAIL':
      break;
    case 'DEPARTMENT_FETCH_SUCCESS':
      const activesNormalized = normalize(action.payload.actives, [
        departmentSchema,
      ]);
      const allNormalized = normalize(action.payload.all, [departmentSchema]);
      stateReturn = {
        ...state,
        departments: activesNormalized.entities.departments,
        departmentsWithInactives: allNormalized.entities.departments,
      };
      break;
    case 'DEPARTMENT_CREATE_SUCCESS':
    case 'DEPARTMENT_UPDATE_SUCCESS':
      const departmentsWithInactives = {
        ...state.departmentsWithInactives,
        [action.payload.id]: action.payload,
      };
      const activesArray = Object.values(departmentsWithInactives).filter(
        (d) => d.active
      );
      normalized = normalize(activesArray, [departmentSchema]);

      stateReturn = {
        ...state,
        departmentsWithInactives,
        departments: normalized.entities.departments,
      };
      break;
    case 'RESTAURANT_FETCH_SUCCESS':
      normalized = normalize(action.payload, [restaurantSchema]);
      stateReturn = {
        ...state,
        restaurants: normalized.entities.restaurants,
      };
      break;
    case 'RESTAURANT_FETCH_FAIL':
      break;
    case 'REPORTS_VIEW_FETCH_SUCCESS':
      let normalizedReportSets = normalize(action.payload.reportQuestionSets, [
        reportSetSchema,
      ]);
      let normalizedReportUsers = normalize(action.payload.reportUsers, [
        usersSchema,
      ]);

      stateReturn = {
        ...state,
        reportQuestionSets: normalizedReportSets.entities.questionSets || {},
        reportUsers: normalizedReportUsers.entities.users || {},
      };

      break;
    case 'REPORTS_VIEW_FETCH_FAIL':
      break;
    case 'BREADCRUMB_SET_SUCCESS':
      stateReturn = {
        ...state,
        breadcrumbs: action.payload.breadcrumbs || [],
      };
      break;
    // case 'GET_QUESTION_INSTANCES_SUCCESS':
    //   let normalizedInstances = normalize(action.payload.questionSetInstances, [questionSetInstanceSchema]);
    //   stateReturn = {
    //     ...state,
    //     questionSetInstances: normalizedInstances.entities.questionSetInstances || {}
    //   }
    //   break;
    case 'GET_QUESTION_INSTANCES_FAIL':
      break;
    case 'CLOSED_QUESTION_SET_INSTANCE_SUCCESS':
      stateReturn = {
        ...state,
      };
      break;
    case 'CLOSED_QUESTION_SET_INSTANCE_FAIL':
      break;
    case 'SHOW_TOASTER_SUCCESS':
      stateReturn = {
        ...state,
        toaster: action.payload.toaster,
      };
      break;
    case 'REPORT_OVERVIEW_DATA_FETCH_SUCCESS':
      stateReturn = {
        ...state,
        reportOverview: action.payload.reportResults,
      };
      break;
    case 'DELETE_QUESTION_SET_SUCCESS':
      break;
    case 'DELETE_QUESTION_SET_FAIL':
      break;
    case 'SET_REPORT_SELECTED_RESTAURANTS':
      stateReturn = {
        ...state,
        reportSelectedRestaurants: action.payload.restaurants || [],
      };

      break;
    case 'SET_REPORT_SELECTED_DEPARTMENTS':
      stateReturn = {
        ...state,
        reportSelectedDepartments: action.payload.departments || [],
      };
      break;
    default:
      break;
  }

  return stateReturn;
};

function removeKey(obj, deleteKey) {
  let clone = Object.assign({}, obj);
  delete clone[deleteKey];
  return clone;
}

export default data;
