import _ from 'lodash';

/***************************************************
 * Function to convert from minutes to milliseconds *
 ****************************************************/

export function isNull(value) {
  if (value === 0) {
    return false;
  }
  if (!value || value === undefined || value === null) {
    return true;
  }
  return false;
}

export function safeToPercentStr(val) {
  if (typeof val === 'string') {
    val = Number(val);
  }
  val = (val * 100).toFixed(1);
  return val.toString();
}

export function minutesToMilliseconds(minutes) {
  return minutes * 60 * 1000;
}

export function arraysIntersect(arr1, arr2) {
  return arr1.some((r) => arr2.includes(r));
}

export function commaSeperateStrArray(array) {
  let str = '';
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (i + 1 === array.length) {
        str += array[i];
      } else {
        str += array[i] + ', ';
      }
    }
  }
  return str;
}

export function commaSeperateArrayByField(array, field) {
  let str = '';
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] && array[i][field]) {
        if (i + 1 === array.length) {
          str += array[i][field];
        } else {
          str += array[i][field] + ', ';
        }
      }
    }
  }
  return str;
}

export function arrayMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const keyedObjectArrayMatch = (obj, matchKey) => {
  let returnObject = null;
  Object.keys(obj).map(function (key, index) {
    if (matchKey === key) {
      returnObject = obj[key];
      return returnObject;
    }
    return null;
  }, this);
  return returnObject;
};

export const arrayFromKeyedObject = (obj) => {
  let returnArray = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      returnArray.push(obj[key]);
    }
  }
  return returnArray;
};

export const findPropertyKeyed = (object, property, val) => {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      if (object[key][property] === val) {
        return object[key];
      }
    }
  }
  return null;
};

export const arrayFromKeys = (obj, idArray) => {
  if (!obj || !idArray) {
    return [];
  }
  return _.map(idArray, (r) => {
    return obj[r];
  }).filter((r) => !!r);
};

export const getFileType = (str) => {
  str = str.split('.');
  let lowercaseExt = str[str.length - 1].toLowerCase();
  let audioExtensions = ['mp3', 'wav', 'ogg', 'aac'];
  let imageExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'svg', 'gif'];
  let videoExtensions = ['webm', 'mp4', 'mov'];

  if (audioExtensions.indexOf(lowercaseExt) > -1) {
    return 'audio';
  }

  if (imageExtensions.indexOf(lowercaseExt) > -1) {
    return 'image';
  }

  if (videoExtensions.indexOf(lowercaseExt) > -1) {
    return 'video';
  }

  return 'unsupported';
};

export const getFileIcon = (file) => {
  let str = file.split('.');
  let lowercaseExt = str[str.length - 1].toLowerCase();
  let supportedFiles = [
    'ae',
    'ai',
    'csv',
    'doc',
    'docx',
    'exe',
    'mkv',
    'mobi',
    'mov',
    'mp3',
    'mpg',
    'otf',
    'pdf',
    'ppt',
    'pptx',
    'psd',
    'rar',
    'tar',
    'txt',
    'wav',
    'wma',
    'xls',
    'xlsx',
    'zip',
  ];

  if (supportedFiles.indexOf(lowercaseExt) > -1) {
    return `/images/icons/${lowercaseExt}.png`;
  }

  return `/images/icons/unknown.png`;
};

export const getQuestionTypeFromFlex = (flex) => {
  if (flex.true_false) {
    return 'true_false';
  } else if (flex.multiple_choice) {
    return 'multiple_choice';
  } else if (flex.essay) {
    return 'essay';
  } else if (flex.fill_in_the_blanks) {
    return 'fill_in_the_blanks';
  } else {
    return null;
  }
};

export const userHasRole = (checkRole, userRoleIds, roles) => {
  if (userRoleIds?.[0]?.id) {
    userRoleIds = _.map(userRoleIds, (r) => r.id);
  }
  if (!userRoleIds) {
    return false;
  }
  for (var a = 0; a < userRoleIds.length; a++) {
    let role = roles[userRoleIds[a]];
    if (role && role.name === checkRole) {
      return true;
    }
  }
  return false;
};

export const userHasRoleV2 = (checkRole, userRoles) => {
  const matchedRole = Object.values(userRoles).find(
    (x) => x.name === checkRole
  );
  if (matchedRole) {
    return true;
  }
  return false;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getRandomStr = () => {
  let text = '';
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const getDashboard = (user, roles) => {
  let mappedRoles = _.map(user.roles, (id) => roles[id].name);
  for (let i = 0; i < mappedRoles.length; i++) {
    if (['sysadmin', 'admin'].indexOf(mappedRoles[i]) > -1) {
      return '/admindashboard';
    }
    if (['manager'].indexOf(mappedRoles[i]) > -1) {
      return '/managerdashboard';
    }
    if (['employee'].indexOf(mappedRoles[i]) > -1) {
      return '/dashboard';
    }
  }

  return '/dashboard';
};
