
export const genericAction = (actionName) => {
  return (payload, onSuccess, onFail, autoSuccessMessage, autoHandleError, autoHandleLoading) => ({
    type: actionName,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null,
    autoSuccessMessage,
    autoHandleError,
    autoHandleLoading
  }
  )
};
// export const showErrorMessage = genericAction (ACTIONS.SHOW_ERROR);
// export const showSuccessMessage = genericAction (ACTIONS.SHOW_SUCCESS);
// export const startLoading = genericAction (ACTIONS.IS_LOADING);
// export const stopLoading = genericAction (ACTIONS.NOT_LOADING);
// export const doLogout = genericAction (ACTIONS.LOGOUT);
// export const setInviteFriendModal = genericAction (ACTIONS.SET_INVITE_FRIEND_MODAL);
// export const setInviteToken = genericAction (ACTIONS.SET_INVITE_TOKEN);
