import React, { useState, useEffect } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { Table, Checkbox, Modal, Button, Input, message, Form } from 'antd';
// import { userHasRole } from '../utils/sharedUtils';
import { Alert, Intent } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepartment,
  departmentFetch,
  updateDepartment,
} from '../../actions/departmentActions';

const DepartmentTable = () => {
  const dispatch = useDispatch();
  const departments = Object.values(
    useSelector((state) => state.data.departmentsWithInactives)
  );

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isActiveModalVisible, setIsActiveModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [editName, setEditName] = useState('');
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true, // Enable page size changer
    pageSizeOptions: [5,10,20], // Options for records per page
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  useEffect(() => {
    console.log('departmentFetch()');
    if (!departments || departments.length === 0) {
      dispatch(departmentFetch());
    }
  }, []);

  // onChange handler
  const handleChange = (pagination, filters, sorter) => {
    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // Update state
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    setFilters(filters);
    setSorter(sorter);
  };


  const handleEditClick = (department) => {
    setSelectedDepartment(department);
    setEditName(department.name);
    setIsEditModalVisible(true);
  };

  const handleActiveChange = (department) => {
    setSelectedDepartment(department);
    setIsActiveModalVisible(true);
  };

  const confirmActiveChange = () => {
    console.log('confirmActiveChange()');
    dispatch(
      updateDepartment({
        id: selectedDepartment.id,
        updates: { active: !selectedDepartment.active },
      })
    );
    setIsActiveModalVisible(false);
  };

  const handleEditSave = () => {
    console.log('handleEditSave()');
    dispatch(
      updateDepartment({
        id: selectedDepartment.id,
        updates: { name: editName },
      })
    );
    setIsEditModalVisible(false);
  };

  const handleCreateDepartment = () => {
    console.log('handleCreateDepartment()', newDepartmentName);
    if (!newDepartmentName.trim()) return;
    dispatch(createDepartment({ name: newDepartmentName, active: true }));
    setNewDepartmentName('');
    setIsCreateModalVisible(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 'auto',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={record.active}
          onChange={() => handleActiveChange(record)}
        />
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      dataIndex: 'edit',
      className: 'tests-table-edit-column',
      width: 100,
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditClick(record)}>
          <FontAwesomeIcon className="fa-md" icon="edit" />
        </Button>
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
            <Button
        className="bp5-button bp5-large bp5-intent-primary"
        type="primary"
        onClick={() => setIsCreateModalVisible(true)}
        style={{ marginBottom: '16px' }}
      >
        Create Department
      </Button>
      
      <Table
      bordered
  title={() => <h2>Departments</h2>}
  dataSource={departments}
  columns={columns}
  rowKey={(record) => record.id}
  style={{ width: '100%' }}
  pagination={{
    current: pagination.current,
    pageSize: pagination.pageSize,
    total: departments.length, // Total count of items
    showSizeChanger: true, // Enable page size changer
    pageSizeOptions: ["5", "10", "20", "50"], // Options for records per page
  }}
  onChange={handleChange}
/>



      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Yes"
        icon="user"
        intent={Intent.PRIMARY}
        isOpen={isActiveModalVisible}
        onCancel={() => setIsActiveModalVisible(false)}
        onConfirm={confirmActiveChange}
      >
        Are you sure you want to change the active status of this department?
      </Alert>

      {/* Edit Modal */}
      {isEditModalVisible && (
        <Modal
          title="Edit Department Name"
          visible={isEditModalVisible}
          onOk={handleEditSave}
          // afterClose={() => setIsEditModalVisible(false)}
          onCancel={() => setIsEditModalVisible(false)}
          closable
        >
          <Input
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            placeholder="Enter department name"
          />
        </Modal>
      )}

      {/* Create Department Modal */}
      {isCreateModalVisible && (
        <Modal
          title="Create Department"
          visible={isCreateModalVisible}
          onOk={handleCreateDepartment}
          onCancel={() => setIsCreateModalVisible(false)}
        >
          <Form layout="vertical">
            <Form.Item label="Department Name" required>
              <Input
                value={newDepartmentName}
                onChange={(e) => setNewDepartmentName(e.target.value)}
                placeholder="Enter new department name"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default DepartmentTable;
