/* eslint-disable semi */
import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';
import request from '../utils/api/ServerRequest';

const departmentSchema = new schema.Entity('departments');
const restaurantSchema = new schema.Entity('restaurants');
const roleSchema = new schema.Entity('roles');

const usersSchema = new schema.Entity('users', {
  departments: [departmentSchema],
  restaurants: [restaurantSchema],
  roles: [roleSchema],
});

async function fetchUsersAPI(payload) {
  try {
    return await request.get('/v1/user/fetch');
  } catch (e) {
    return e;
  }
}

async function updateUserAPI(payload) {
  try {
    return await request.put('v1/user/update', payload);

    // await axios.get('http://localhost:8081/v1/user/update', {
    //   params: {
    //     id: payload.id,
    //     first_name: payload.first_name,
    //     last_name: payload.last_name,
    //     restaurants: payload.restaurants,
    //     departments: payload.departments,
    //     current_password: payload.current_password,
    //     new_password: payload.new_password
    //   }
    // });
  } catch (e) {
    return e;
  }
}

async function userCreateAPI(payload) {
  try {
    return await request.post('v1/user/create', {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email: payload.email,
      restaurants: payload.restaurants,
      departments: payload.departments,
      roles: payload.roles,
    });
    // await axios.get('http://localhost:8081/v1/user/create', {
    //   params: {
    //     first_name: payload.first_name,
    //     last_name: payload.last_name,
    //     email: payload.email,
    //     restaurants: payload.restaurants,
    //     departments: payload.departments,
    //     roles: payload.roles
    //   }
    // });
  } catch (e) {
    return e;
  }
}

async function userGetDataAPI(payload) {
  try {
    return await request.get('/v1/user/get_data');
  } catch (e) {
    return e;
  }
}

const userSaga = {
  userFetch: function* (action) {
    let success = false;
    try {
      const response = yield call(fetchUsersAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({ type: 'USER_FETCH_SUCCESS', payload: response.data.users });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {}

    if (!success) {
      yield put({ type: 'USER_FETCH_FAIL', payload: { users: [] } });
      if (action.onFail) action.onFail();
    }
  },

  userUpdate: function* (action) {
    let success = false;
    let response;
    try {
      response = yield call(updateUserAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put(
          // {
          //   type: 'USER_UPDATE_SUCCESS',
          //   normalized: normalize(response.data.user, usersSchema),
          // },
          { type: 'USER_UPDATE_SUCCESS_2', payload: response.data.user }
        );
        if (action.onSuccess) {
          action.onSuccess(response.data.user);
        }
      }
    } catch (e) {}

    if (!success) {
      yield put({ type: 'USER_UPDATE_FAIL', payload: { user: {} } });
      if (action.onFail)
        action.onFail(
          _.get(response, 'data.error', 'An error occured updating user.')
        );
    }
  },

  userCreate: function* (action) {
    let success = false;
    try {
      const response = yield call(userCreateAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'USER_CREATE_SUCCESS',
          normalized: normalize(response.data.user, usersSchema),
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {}

    if (!success) {
      yield put({ type: 'USER_CREATE_FAIL', payload: null });
      if (action.onFail) action.onFail();
    }
  },

  userGetData: function* (action) {
    let success = false;
    try {
      const response = yield call(userGetDataAPI, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({
          type: 'USER_GET_DATA_SUCCESS',
          payload: response.data.user,
        });
        yield put({
          type: 'USER_GET_DATA_ROLES_SUCCESS',
          normalized: normalize(response.data.roles, [roleSchema]),
        });
        if (action.onSuccess) action.onSuccess();
      }
    } catch (e) {}

    if (!success) {
      yield put({ type: 'USER_GET_DATA_FAIL', user: {} });
      if (action.onFail) action.onFail();
    }
  },
};

export default userSaga;
