




export function yyyymmddhhmmss(d) {
  function padLeft (number, length){
    if (length === null || length === undefined) {
      length = 2;
    }
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
   
    return str;
  }
  if (d instanceof Date && !isNaN(d)){
    return [ 
      d.getFullYear(),
      padLeft((d.getMonth()+1)),
      padLeft(d.getDate())
      ].join('/')+
      ' ' +
    [ padLeft(d.getHours()),
      padLeft(d.getMinutes()),
      padLeft(d.getSeconds())].join(':');
  }
  return 'NA'
}