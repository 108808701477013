import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, MenuItem, Intent, RadioGroup, Radio } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import PureGridBox24th from '../utils/PureGridBox24th';
import {
  questionCreate,
  questionUpdate,
  questionUploadFile,
  questionRemoveFile,
} from '../../actions/questionActions';
import { validate } from '../utils/validate';
import Cellbox from '../utils/Cellbox';
import _ from 'lodash';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Spaner from '../utils/Spaner';
import PicturesWallUpload from '../utils/PicturesWallUpload';
import { arrayFromKeys, arrayFromKeyedObject } from '../utils/sharedUtils';
import cfg from '../../config';
import { breadcrumbSet } from '../../actions/utilActions';
import withRouter from '../common/withRouter';
import { AppToaster } from '../common/AppToaster';
import { isQuestionSetClosed } from '../../actions/questionSetActions';
import Loading from '../shared/Loading';
import { Typography } from 'antd';

const defaultFlex = {
  true_false: {
    answer: true,
    true_label: 'True',
    false_label: 'False',
  },
  multiple_choice: {
    answer: null,
    options: [{ label: '' }, { label: '' }],
  },
  essay: {
    answer: null,
  },
  fill_in_the_blanks: {
    parts: [],
    // {type: 'text', value: '', answers: []},
    // {type: 'blank', value: '', answers: []},
    // {type: 'number', value: '', answers: []}
  },
};

const fill_in_the_blank_part_types = ['text', 'blank', 'number'];

class UpsertQuestion extends Component {
  constructor(props) {
    super(props);
    let questionId = null;
    // let questionSetId = null;
    let editMode = false;
    let question_text = '';
    let point_value = '';
    let type_name = 'Choose...';
    let type = null;
    let flex = {};
    if (this.props.router.params.questionId) {
      questionId = this.props.router.params.questionId;
      let question = this.props.questions[questionId];
      flex = question.flex || {};
      question_text = question.question_text;
      point_value = question.point_value;
      let question_type = this.props.questionTypes[question.type]; //_.find(this.props.questionTypes, { id: question.type })
      type = question_type.id;
      type_name = question_type.name;
      editMode = true;
    }

    this.state = {
      questionId: questionId,
      questionSetId: this.props.router.params.questionSetId,
      question_text: question_text,
      point_value: point_value || 1, // Defaulting value to 1 when the user it logs the first time
      type: type,
      type_name: type_name,
      editMode: editMode,
      flex: {
        ...defaultFlex,
        ...flex,
      },
      questionIsBlocked: undefined,
      validation: {
        question_text: {
          rules: [{ name: 'isString' }],
          isValid: true,
          errors: [],
        },
        point_value: {
          rules: [{ name: 'isIntOrStringInt' }],
          isValid: true,
          errors: [],
        },
        // We should add the 'point_value' validation to just allow numbers
        flex: {
          true_false: {
            answer: {
              rules: [{ name: 'isString' }],
              isValid: true,
              errors: [],
            },
          },
        },
      },
      isNewQuestion: false,
    };
  }

  componentDidMount() {
    this.props.breadcrumbSet([
      { name: 'Tests', active: true, link: '/tests' },
      {
        name: 'Edit test',
        active: true,
        link: '/test/' + this.props.router.params.questionSetId,
      },
      { name: 'Question', active: false, link: null },
    ]);
    this.props.isQuestionSetClosed(
      this.props.router.params.questionSetId,
      (isClosed) => {
        this.setState({ questionIsBlocked: !isClosed });
      },
      (err) => {
        console.error(err);
        this.setState({ questionIsBlocked: false });
      }
    );
  }

  handleStateChange(event, attribute) {
    this.setState({
      [attribute]: event.target.value,
    });
  }

  handleSuccess = (response) => {
    if (this.state.editMode) {
      AppToaster.show({
        message: 'Question updated',
        intent: Intent.SUCCESS,
        icon: 'tick',
      });
    } else {
      AppToaster.show({
        message: 'Question created',
        intent: Intent.SUCCESS,
        icon: 'tick',
      });
      this.setState({
        point_value: 1, // Setting the value to 1 per default
      });
      let find_type = _.get(
        this.props.questionTypes,
        response.question.type,
        -1
      );
      let newFlex = this.state.flex;
      // Switching to knw what type of question we're creating/ updating
      switch (find_type.code) {
        case 'true_false':
          newFlex.true_false.answer = null;
          newFlex.true_false.true_label = 'True';
          newFlex.true_false.false_label = 'False';
          this.props.router.navigate(
            '/test/' + this.props.router.params.questionSetId + '/question/new'
          );
          break;
        case 'multiple_choice':
          newFlex.multiple_choice.answer = null;
          newFlex.multiple_choice.options = [];
          this.props.router.navigate(
            '/test/' + this.props.router.params.questionSetId + '/question/new'
          );
          break;
        case 'fill_in_the_blanks':
          newFlex.fill_in_the_blanks.parts = [];
          this.props.router.navigate(
            '/test/' + this.props.router.params.questionSetId + '/question/new'
          );
          break;
        case 'essay':
          newFlex.essay.answer = null;
          this.props.router.navigate(
            '/test/' + this.props.router.params.questionSetId + '/question/new'
          );
          break;
        default:
          break;
      }
      this.setState({
        question_text: '',
        description: '',
        isNewQuestion: false,
        editMode: false,
        flex: newFlex,
      });
    }
  };

  handleFail = (errors) => {
    if (!errors) {
      return null;
    }
    let errorStr = '';
    if (errors.length > 0) {
      _.forEach(errors, (error) => {
        errorStr += error.detail;
        errorStr += ' ';
      });
      AppToaster.show({
        message: errorStr,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      AppToaster.show({
        message: 'An unexpected error has occured',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    }

    this.setState({
      point_value: 1, // Setting the value to 1 per default
    });
  };

  handleFileSuccess = (response) => {
    AppToaster.show({
      message: 'File updated',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  handleFileFail = (errors) => {
    if (!errors) {
      return null;
    }
    let errorStr = '';
    if (errors.length > 0) {
      _.forEach(errors, (error) => {
        errorStr += error.detail;
        errorStr += ' ';
      });
      AppToaster.show({
        message: errorStr,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      AppToaster.show({
        message: 'An unexpected error has occured',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    }
  };

  getFlexFromType = (flex, type) => {
    let flexdata = {};
    let question_type = this.props.questionTypes[type]; //_.find(this.props.questionTypes, { id: type });
    if (!question_type) {
      return null;
    }
    switch (question_type.code) {
      case 'true_false':
        flexdata = {
          true_false: flex.true_false,
        };
        break;
      case 'multiple_choice':
        flexdata = {
          multiple_choice: flex.multiple_choice,
        };
        break;
      case 'essay':
        flexdata = {
          essay: {},
        };
        break;
      case 'fill_in_the_blanks':
        flexdata = {
          fill_in_the_blanks: flex.fill_in_the_blanks,
        };
        break;
      default:
        flexdata = {
          [question_type.code]: {},
        };
        break;
    }
    return flexdata;
  };

  handleSubmit = () => {
    let validation = validate(this.state);
    let invalidCount = 0;
    //todo validate flex data
    let flex = this.getFlexFromType(this.state.flex, this.state.type);

    this.setState({ isNewQuestion: true });

    Object.keys(validation).forEach((key) => {
      if (!validation[key].isValid) {
        invalidCount++;
      }
    });

    if (invalidCount > 0) {
      this.setState({
        validation: validation,
      });
    } else {
      const onSuccess = (response) => this.handleSuccess(response);
      const onFail = (response) => this.handleFail(response);
      //const onFail = (response) => this.handleFail(response);
      if (this.state.editMode === false && this.state.point_value !== 0) {
        this.props.questionCreate(
          this.state.question_text,
          this.state.type,
          flex,
          this.state.questionSetId,
          this.state.point_value,
          onSuccess,
          onFail
        );
      } else if (this.state.editMode && this.state.point_value !== 0) {
        this.props.questionUpdate(
          this.state.question_text,
          this.state.type,
          flex,
          this.state.questionId,
          this.state.point_value,
          onSuccess,
          onFail
        );
      }
    }
  };

  getIntent(name) {
    if (this.state.validation[name].isValid) {
      return 'bp5-intent-primary';
    } else {
      return 'bp5-intent-warning';
    }
  }

  getIntentDeep(attrString) {
    let isValid = _.get(this.state.validation, attrString);
    if (isValid) {
      return 'bp5-intent-primary';
    } else {
      return 'bp5-intent-warning';
    }
  }

  getQuestionFiles() {
    let server = cfg.server_url;
    let questionResources = arrayFromKeys(
      this.props.resources,
      this.props.questions[this.state.questionId].resources
    );
    let fileList = [];
    if (questionResources.length > 0) {
      questionResources.forEach((resource) => {
        fileList.push({
          uid: resource.id,
          name: resource.name,
          questionId: this.state.questionId,
          status: 'done',
          url:
            server +
            '/v1/protectedfile/download?subdir=question&file=' +
            resource.asset.name,
        });
      });
      return fileList;
    }
    return [];
  }

  renderQuestionTypeItem = (item) => {
    return (
      <MenuItem
        active={_.findIndex(this.state.questionTypes, { id: item.id }) !== -1}
        key={item.id}
        onClick={() => this.handleQuestionTypeChange(item)}
        text={item.name}
        shouldDismissPopover={true}
      />
    );
  };

  // prettyTypeName = (name) => {
  //   switch(name){
  //   case 'filler':
  //   return 'Text';
  //   case 'text':
  //   return 'Blank';
  //   case 'number':
  //   return 'Number';
  //   }
  // }

  renderFillInTheBlankTypeItem = (item, index) => {
    return (
      <MenuItem
        active={false}
        key={item}
        onClick={(e) => this.handleFillInTheBlankTypeChange(e, item, index)}
        text={item}
        shouldDismissPopover={true}
      />
    );
  };

  handleFillInTheBlankTypeChange = (e, item, index) => {
    let flexModified = this.state.flex;
    flexModified.fill_in_the_blanks.parts[index].type = item;
    this.setState({
      flex: flexModified,
    });
  };

  handleQuestionTypeChange = (item) => {
    this.setState({
      type_name: item.name,
      type: item.id,
    });
  };

  handleTrueFalseChange = (e) => {
    this.setState({
      flex: {
        ...this.state.flex,
        true_false: {
          ...this.state.flex.true_false,
          answer: e.target.value,
        },
      },
    });
  };

  handleDeepFlexChange = (e, deepFlex) => {
    let modifiedFlex = this.state.flex;
    _.set(modifiedFlex, deepFlex, e.target.value);
    this.setState({
      flex: modifiedFlex,
    });
  };

  handleMultipleChoiceAnswerChange = (e) => {
    let modifiedFlex = this.state.flex;
    _.set(modifiedFlex, 'multiple_choice.answer', Number(e.target.value));
    this.setState({
      flex: modifiedFlex,
    });
  };

  handleMultipleChoiceChange = (e, attr, index) => {
    let modifiedFlex = this.state.flex;
    _.set(
      modifiedFlex,
      'multiple_choice.options[' + index + '][' + attr + ']',
      e.target.value
    );
    this.setState({
      flex: modifiedFlex,
    });
  };

  multichoiceOptions = () => {
    return this.state.flex.multiple_choice.options.map((option, index) => {
      return this.multichoiceOption(option, index);
    });
  };

  multichoiceOption = (option, index) => {
    return (
      <span key={index}>
        <input
          className={'bp5-input bp5-large bp5-intent-primary'}
          style={{ width: '130%' }}
          type="text"
          placeholder="Option label"
          value={this.state.flex.multiple_choice.options[index].label}
          onChange={(e) => this.handleMultipleChoiceChange(e, 'label', index)}
        />
        <span onClick={() => this.multipleChoiceRemoveOption(index)}>
          <FontAwesomeIcon
            style={{ color: 'red', cursor: 'pointer' }}
            className="fa-lg"
            icon={['far', 'minus-circle']}
          />
        </span>
        <br />
        <br />
        <Radio
          checked={this.state.flex.multiple_choice.answer === index}
          onChange={this.handleMultipleChoiceAnswerChange}
          label=""
          value={index}
        />
      </span>
    );
  };

  multipleChoiceAddOption = () => {
    let modifiedFlex = this.state.flex;
    modifiedFlex.multiple_choice.options.push({ label: '' });
    this.setState({
      flex: modifiedFlex,
    });
  };

  multipleChoiceRemoveOption = (index) => {
    let modifiedFlex = this.state.flex;
    modifiedFlex.multiple_choice.options.splice(index, 1);
    if (modifiedFlex.multiple_choice.answer === index) {
      modifiedFlex.multiple_choice.answer = null;
    } else if (modifiedFlex.multiple_choice.answer > index) {
      modifiedFlex.multiple_choice.answer--;
    }

    this.setState({
      flex: modifiedFlex,
    });
  };

  fillInTheBlanksAddPart = () => {
    let modifiedFlex = this.state.flex;
    modifiedFlex.fill_in_the_blanks.parts.push({
      type: 'text',
      value: '',
      answers: [],
    });
    this.setState({
      flex: modifiedFlex,
    });
  };

  handleFillInTheBlankFillerChange = (e, index) => {
    let modifiedFlex = this.state.flex;
    _.set(
      modifiedFlex,
      'fill_in_the_blanks.parts[' + index + '].value',
      e.target.value
    );
    this.setState({
      flex: modifiedFlex,
    });
  };

  handleRemovePicture = (file) => {
    const onSuccess = (response) => this.handleFileSuccess(response);
    const onFail = (response) => this.handleFileFail(response);

    this.props.questionRemoveFile(file, onSuccess, onFail);
  };

  handleUploadFile = (request) => {
    const onSuccess = (response) => this.handleFileSuccess(response);
    const onFail = (response) => this.handleFileFail(response);

    this.props.questionUploadFile(request, onSuccess, onFail);
  };

  renderFillInTheBlanksPreview = () => {
    return this.state.flex.fill_in_the_blanks.parts.map((part, index) => {
      switch (part.type) {
        case 'text':
          return <span key={index}>{part.value} </span>;
        case 'blank':
        case 'number':
        default:
          return <span key={index}>________ </span>;
      }
    });
  };

  fillInTheBlankAddAnswer = (part, index) => {
    let flex_state = this.state.flex;
    let val = '';
    if (part.type === 'blank') {
      val = '';
    } else if (part.type === 'number') {
      val = 0;
    }
    flex_state.fill_in_the_blanks.parts[index].answers.push(val);
    this.setState({
      flex: flex_state,
    });
    // _.get(mutatedState, 'flex.fill_in_the_blanks.parts['+index+']'.answers, [])
  };

  renderFillInTheBlanksParts = () => {
    let qdata = this.state.flex.fill_in_the_blanks;
    return this.renderFillInTheBlankPartSwitch(qdata);
  };
  renderFillInTheBankPartSelect = (part, index) => {
    return (
      <span key={index}>
        <Select
          filterable={false}
          className="bp5-large"
          item={this.state.flex.fill_in_the_blanks.parts[index].type}
          items={fill_in_the_blank_part_types}
          itemRenderer={(data) =>
            this.renderFillInTheBlankTypeItem(data, index)
          }
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={(data) => null}
          closeOnSelect={true}
        >
          <Button
            text={this.state.flex.fill_in_the_blanks.parts[index].type}
            rightIcon="double-caret-vertical"
          />
        </Select>
      </span>
    );
  };

  renderFillInTheBlankAnswers = (part, partsIndex) => {
    return part.answers.map((answer, answerIndex) => {
      switch (part.type) {
        case 'blank':
          return (
            <input
              key={answerIndex}
              type="blank"
              className={'bp5-input bp5-large bp5-intent-primary'}
              value={
                this.state.flex.fill_in_the_blanks.parts[partsIndex].answers[
                  answerIndex
                ]
              }
              onChange={(e) =>
                this.handleFillInTheBlankAnswerChange(
                  e,
                  partsIndex,
                  answerIndex
                )
              }
            />
          );
        case 'number':
          return (
            <input
              key={answerIndex}
              type="number"
              className={'bp5-input bp5-large bp5-intent-primary'}
              value={
                this.state.flex.fill_in_the_blanks.parts[partsIndex].answers[
                  answerIndex
                ]
              }
              onChange={(e) =>
                this.handleFillInTheBlankAnswerChange(
                  e,
                  partsIndex,
                  answerIndex
                )
              }
            />
          );
        default:
          return null;
      }
    });
  };

  handleFillInTheBlankAnswerChange = (e, partsIndex, answerIndex) => {
    let flex_state = this.state.flex;
    flex_state.fill_in_the_blanks.parts[partsIndex].answers[answerIndex] =
      e.target.value;
    this.setState({
      flex: flex_state,
    });
  };

  renderFillInTheBlankPartSwitch = (qdata) => {
    return qdata.parts.map((part, index) => {
      switch (part.type) {
        case 'text':
          return (
            <div key={index} style={{ marginTop: 12 }}>
              <span style={{ width: 50 }}>#{index}: </span>
              <Spaner width="lg" />
              <span style={{ width: 100 }}>
                {' '}
                {this.renderFillInTheBankPartSelect(part, index)}
              </span>
              <span>
                <input
                  name={'text_' + index}
                  style={{ width: 300 }}
                  className={'bp5-input bp5-large bp5-intent-primary'}
                  onChange={(e) =>
                    this.handleFillInTheBlankFillerChange(e, index)
                  }
                  value={this.state.flex.fill_in_the_blanks.parts[index].value}
                />
              </span>
            </div>
          );
        case 'blank':
        case 'number':
          return (
            <div key={index} style={{ marginTop: 12 }}>
              <div>
                <span style={{ width: 50 }}>#{index}: </span>
                <Spaner width="lg" />
                <span style={{ width: 100 }}>
                  {' '}
                  {this.renderFillInTheBankPartSelect(part, index)}
                </span>
                <span>
                  <span
                    style={{ border: 0, padding: 10, lineHeight: 2.5 }}
                    className={''}
                  >
                    ________
                  </span>
                </span>
              </div>
              <div style={{ marginTop: 12, display: 'inline-block' }}>
                <Button
                  onClick={() => this.fillInTheBlankAddAnswer(part, index)}
                  intent="primary"
                  large
                >
                  Add Answer
                </Button>
                {this.renderFillInTheBlankAnswers(part, index)}
              </div>
            </div>
          );
        default:
          return <span style={{ marginTop: 12 }} key={index} />;
      }
    });
  };

  // createNewQuestion = () => {
  //   this.setState({name: '', description: "", isNewQuestion: false, editMode: false, flex: {} }); // Cleaning up the fields and disable the "New question" button
  //   this.props.router.navigate('/test/'+this.props.router.params.questionSetId+'/question/new');
  // }

  renderQuestionDetails = () => {
    const fileList = this.getQuestionFiles() || [];
    if (this.state.type !== null) {
      let question_type = this.props.questionTypes[this.state.type]; //_.find(this.props.questionTypes, { id: this.state.type });
      switch (question_type.code) {
        case 'true_false':
          return (
            <div>
              <div style={{ textAlign: 'left' }}>
                <br />
                <h3>
                  <b>New section: true/false</b>
                </h3>
              </div>
              <hr />
              <br />

              <Cellbox>
                <b>Choose the correct answer</b>
                <br />
                <br />
                <RadioGroup
                  onChange={this.handleTrueFalseChange}
                  selectedValue={this.state.flex.true_false.answer}
                >
                  <Radio label="True" value="true" />
                  <Radio label="False" value="false" />
                </RadioGroup>
              </Cellbox>

              <Cellbox>
                <b>True label</b>
                <br />
                <br />
                <input
                  className={'bp5-input bp5-large bp5-intent-primary'}
                  type="text"
                  placeholder="True label"
                  value={this.state.flex.true_false.true_label}
                  onChange={(e) =>
                    this.handleDeepFlexChange(e, 'true_false.true_label')
                  }
                />
              </Cellbox>
              <Cellbox>
                <b>False label</b>
                <br />
                <br />

                <input
                  className={'bp5-input bp5-large bp5-intent-primary'}
                  type="text"
                  placeholder="False label"
                  value={this.state.flex.true_false.false_label}
                  onChange={(e) =>
                    this.handleDeepFlexChange(e, 'true_false.false_label')
                  }
                />
              </Cellbox>
            </div>
          );
        case 'multiple_choice':
          return (
            <div>
              <div style={{ textAlign: 'left' }}>
                <br />
                <h3>
                  <b>New section: multiple choice</b>
                </h3>
              </div>
              <hr />

              <Cellbox>
                <b>Answer</b>
                <br />
                <br />
                <RadioGroup>{this.multichoiceOptions()}</RadioGroup>
                <Button
                  onClick={() => this.multipleChoiceAddOption()}
                  intent="primary"
                  large
                >
                  Add Option
                </Button>
              </Cellbox>
            </div>
          );
        case 'essay':
          return <div></div>;
        case 'fill_in_the_blanks':
          return (
            <div>
              <div style={{ textAlign: 'left' }}>
                <br />
                <h3>
                  <b>New section: Fill in the blanks</b>
                </h3>
              </div>
              <hr />
              <Cellbox pure_override="pure-u-1 pure-u-md-24-24 pure-u-lg-6-24"></Cellbox>
              <Cellbox pure_override="pure-u-1 pure-u-md-24-24 pure-u-lg-12-24">
                <div style={{ textAlign: 'left' }}>
                  <b>Preview</b>
                  <br />
                  <br />
                  <div
                    style={{
                      padding: 32,
                      color: 'black',
                      fontSize: 16,
                      border: '1px solid lightgrey',
                    }}
                  >
                    <div style={{ marginBottom: 20 }}>
                      {fileList.map((file) => (
                        <img width={200} src={file.url} alt="img" />
                      ))}
                    </div>
                    {this.renderFillInTheBlanksPreview()}
                  </div>
                  <div style={{ marginTop: 12 }}>
                    <Button
                      onClick={() => this.fillInTheBlanksAddPart()}
                      intent="primary"
                      large
                    >
                      Add Part
                    </Button>
                  </div>
                  {this.renderFillInTheBlanksParts()}
                </div>
              </Cellbox>
              <Cellbox pure_override="pure-u-1 pure-u-md-24-24 pure-u-lg-6-24"></Cellbox>
            </div>
          );
        default:
          return (
            <div>
              Question type not found... {JSON.stringify(question_type)}
            </div>
          );
      }
    }
  };

  render() {
    let fileList = [];
    if (this.state.editMode === true) {
      fileList = this.getQuestionFiles();
    }
    let questionTypeArray = arrayFromKeyedObject(this.props.questionTypes);
    if (this.state.questionIsBlocked === undefined) {
      return <Loading />;
    }
    return (
      <PureGridBox24th>
        <div style={{ textAlign: 'left' }}>
          <br />
          <h3>
            <b>Create a new question</b>
          </h3>
        </div>
        <hr />

        <div style={{ textAlign: 'left', fontSize: 14 }}>
          <br />
          <Cellbox
            pure_override="pure-u-1"
            validation={this.state.validation.description}
          >
            <b>Question Text</b>
            <br />
            <br />
            <input
              style={{ width: '100%' }}
              className={
                'bp5-input bp5-large ' + this.getIntent('question_text')
              }
              type="text"
              value={this.state.question_text}
              onChange={(e) => this.handleStateChange(e, 'question_text')}
            />
          </Cellbox>
          <br />
          <Cellbox validation={this.state.validation.point_value}>
            <b>Point value</b>
            <br />
            <br />
            <input
              className={'bp5-input bp5-large ' + this.getIntent('point_value')}
              type="number"
              min="0"
              step={1}
              value={this.state.point_value}
              onChange={(e) => this.handleStateChange(e, 'point_value')}
              maxLength={5}
            />
          </Cellbox>
          <Cellbox validation={this.state.validation.type}>
            <b>Type</b>
            <br />
            <br />

            <Select
              filterable={false}
              className="bp5-large"
              item={questionTypeArray[0]}
              items={questionTypeArray}
              itemRenderer={(data) => this.renderQuestionTypeItem(data)}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={(data) => this.handleQuestionTypeChange(data)}
            >
              <Button
                text={this.state.type_name}
                rightIcon="double-caret-vertical"
              />
            </Select>
          </Cellbox>

          {this.renderQuestionDetails()}

          {this.state.editMode === true && (
            <PicturesWallUpload
              style={{ display: 'flex', justifyContent: 'center' }}
              disabled={this.state.questionIsBlocked}
              accept="image/*"
              maxFiles={5}
              uploadFile={this.handleUploadFile}
              data={{ questionId: this.state.questionId }}
              remove={this.handleRemovePicture}
              fileList={fileList}
            />
          )}

          {this.state.questionIsBlocked && (
            <div
              className="flexcenter"
              style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
            >
              <Typography.Text type="danger" style={{ marginLeft: 10 }}>
                This question cannot be edited because the test it belongs to is
                either in progress or has ungraded answers.
              </Typography.Text>
            </div>
          )}

          <div
            className="flexcenter"
            style={{ textAlign: 'left', width: '100%', marginTop: 16 }}
          >
            <Button
              disabled={this.state.questionIsBlocked}
              onClick={() => this.handleSubmit()}
              intent={this.state.questionIsBlocked ? null : 'primary'}
              large
            >
              {this.state.editMode ? <span>Update</span> : <span>Add</span>}
            </Button>
          </div>
        </div>
      </PureGridBox24th>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionSets: state.data.questionSets,
    questionTypes: state.data.questionTypes,
    questions: state.data.questions,
    resources: state.data.resources,
    toaster: state.data.toaster,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    questionCreate: (
      question_text,
      type,
      flex,
      questionSetId,
      point_value,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionCreate(
          question_text,
          type,
          flex,
          questionSetId,
          point_value,
          onSuccess,
          onFail
        )
      );
    },
    questionUpdate: (
      question_text,
      type,
      flex,
      id,
      point_value,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionUpdate(
          question_text,
          type,
          flex,
          id,
          point_value,
          onSuccess,
          onFail
        )
      );
    },
    questionUploadFile: (request, onSuccess, onFail) => {
      dispatch(questionUploadFile(request, onSuccess, onFail));
    },
    questionRemoveFile: (request, onSuccess, onFail) => {
      dispatch(questionRemoveFile(request, onSuccess, onFail));
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
    isQuestionSetClosed: (questionSetId, onSuccess) => {
      return dispatch(isQuestionSetClosed(questionSetId, onSuccess));
    },
    // questionFetchFiles: () => {
    //   dispatch(questionFetchFiles(onSuccess, onFail));
    // }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpsertQuestion));
