import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
//import faCoffee from '@fortawesome/fontawesome-free-solid/faCoffee'
//import faAddressBook from '@fortawesome/fontawesome-pro-solid/faAddressBook'

class Test1 extends Component {
    render() {
        return (
            <div className="flexcenter"  style={{ color: '#006666' }}>
                <span style={{margin: 32}}><FontAwesomeIcon size='2x' icon="address-book" /></span>
                <span style={{margin: 32}}><FontAwesomeIcon size='2x' icon="arrow-alt-from-right" /></span>
            </div>
        )
    }
}

export default Test1