
import request from '../utils/api/ServerRequest';
import { call, put } from "redux-saga/effects";
import _ from "lodash";

async function fetchRestaurants(payload) {
  try {
    return await request.get('/v1/restaurant/fetch');
  }
  catch (e) {
    return e;
  }
}

const restaurantSaga = {
  restaurantFetch: function* (action) {
    let success = false;
    try {
      const response = yield call(fetchRestaurants, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        success = true;
        yield put({ type: "RESTAURANT_FETCH_SUCCESS", payload: response.data.restaurants });
        if (action.onSuccess)
          action.onSuccess();
      }
    } catch (e) { }

    if (!success) {
      yield put({ type: "RESTAURANT_FETCH_FAIL", payload: { restaurants: [] } });
      if (action.onFail)
        action.onFail();
    }
  },
}

export default restaurantSaga;