import React, { Component } from 'react';

class PureGridBox24th extends Component {
    render() {
        return (
            <div className="pure-g">
                <div className="pure-u-1 pure-u-md-2-24"></div>
                <div className="pure-u-1 pure-u-md-20-24">
                    {this.props.children}
                </div>
                <div className="pure-u-1 pure-u-md-2-24"></div>
            </div>
        )
    }
}

export default PureGridBox24th