import {
    QUESTION_CREATE,
    QUESTION_UPDATE,
    QUESTION_DELETE,
    QUESTION_TYPE_FETCH,
    QUESTION_MOVE_UP,
    QUESTION_MOVE_DOWN,
    QUESTION_UPLOAD_FILE,
    QUESTION_REMOVE_FILE,
    QUESTION_INSTANCE_GRADE,
    GET_QUESTION_INSTANCES_FROM_QSI
  } from './actionTypes';

export const questionCreate = (question_text, type, flex, questionSetId, point_value, onSuccess, onFail) => ({
    type: QUESTION_CREATE,
    payload: {
        question_text: question_text, 
        type: type,
        flex: flex,
        questionSetId: questionSetId,
        point_value: point_value
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionUpdate = (question_text, type, flex, id, point_value, onSuccess, onFail) => ({
    type: QUESTION_UPDATE,
    payload: {
        question_text: question_text, 
        type: type,
        flex: flex,
        id: id,
        point_value: point_value,
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionDelete = (questionId, onSuccess, onFail) => ({
  type: QUESTION_DELETE,
  payload: {
    questionId: questionId
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const questionTypeFetch = (onSuccess, onFail) => ({
    type: QUESTION_TYPE_FETCH,
    payload: {
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionMoveUp = (questionId, onSuccess, onFail) => ({
    type: QUESTION_MOVE_UP,
    payload: {
        questionId: questionId
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionMoveDown = (questionId, onSuccess, onFail) => ({
    type: QUESTION_MOVE_DOWN,
    payload: {
        questionId: questionId
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionUploadFile = (request, onSuccess, onFail) => ({
    type: QUESTION_UPLOAD_FILE,
    payload: {
        request: request
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionRemoveFile = (request, onSuccess, onFail) => ({
    type: QUESTION_REMOVE_FILE,
    payload: {
        request: request
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const questionInstanceGrade = (questionInstanceId, grade, onSuccess, onFail) => ({
    type: QUESTION_INSTANCE_GRADE,
    payload: {
        question_instance_id: questionInstanceId,
        grade: grade
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const getQuestionInstancesFromQsi = (question_set_instance_id, onSuccess, onFail) => ({
  type: GET_QUESTION_INSTANCES_FROM_QSI,
  payload: {
    question_set_instance: question_set_instance_id,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
