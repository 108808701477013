import request from '../utils/api/ServerRequest';
import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

async function getReports(payload) {
  try {
    return await request.get('/v1/reportadmin/get_reports', {
      departments: payload.departments,
      restaurants: payload.restaurants,
    });
  } catch (e) {
    return e;
  }
}

async function getReportOverview(payload) {
  try {
    return await request.get('/v1/reportadmin/get_report_overview', {});
  } catch (e) {
    return e;
  }
}

async function getReportOverviewResult(payload) {
  try {
    return await request.get('/v1/reportadmin/get_report_overview_data', {});
  } catch (e) {
    return e;
  }
}

const reportSaga = {
  fetchReportOverview: function* (action) {
    try {
      const response = yield call(getReportOverview, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'REPORT_OVERVIEW_FETCH_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: 'REPORT_OVERVIEW_FETCH_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      action.onFail(e);
    }
  },

  fetchReports: function* (action) {
    try {
      const response = yield call(getReports, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'REPORTS_VIEW_FETCH_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: 'REPORTS_VIEW_FETCH_FAIL', payload: null });
        if (action.onFail) {
          action.onFail(response.data.errors);
        }
      }
    } catch (e) {
      action.onFail(e);
    }
  },

  fetchReportOverviewResults: function* (action) {
    try {
      const response = yield call(getReportOverviewResult, action.payload);
      if (_.get(response, 'data.status', null) === 'success') {
        yield put({
          type: 'REPORT_OVERVIEW_DATA_FETCH_SUCCESS',
          payload: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess?.();
        }
      } else {
        yield put({ type: 'REPORT_OVERVIEW_DATA_FETCH_FAIL', payload: null });
        if (action.onFail) {
          action.onFail?.(response.data.errors);
        }
      }
    } catch (e) {
      console.error('CUSTOM ERROR:', e);
      action.onFail?.(e);
    }
  },
};

export default reportSaga;
