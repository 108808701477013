import {
  USER_FETCH,
  USER_UPDATE,
  USER_CREATE,
  USER_GET_DATA,
  USER_GET_DATA_EXTENDED
} from './actionTypes';

import { genericAction } from './genericAction';

export const userUpdate = (id, first_name, last_name, restaurants, departments, roles, current_password, new_password, active, temp_password, onSuccess, onFail) => ({
  type: USER_UPDATE,
  payload: {
    id: id,
    first_name: first_name,
    last_name: last_name,
    restaurants: restaurants,
    departments: departments,
    roles: roles,
    current_password: current_password,
    new_password: new_password,
    active: active,
    temp_password: temp_password
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userCreate = (first_name, last_name, email, restaurants, departments, roles, onSuccess, onFail) => ({
  type: USER_CREATE,
  payload: {
    first_name: first_name,
    last_name: last_name,
    email: email,
    restaurants: restaurants,
    departments: departments,
    roles: roles
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userFetch = (onSuccess, onFail) => ({
  type: USER_FETCH,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userGetData = genericAction(USER_GET_DATA);
export const userGetDataExtended = genericAction(USER_GET_DATA_EXTENDED);

// export const userGetData = (onSuccess, onFail) => ({
//   type: USER_GET_DATA,
//   payload: {

//   },
//   onSuccess: onSuccess || null,
//   onFail: onFail || null
// });