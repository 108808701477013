import { userHasRole } from '../components/utils/sharedUtils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useGrantedRoutes = () => {
  const user = useSelector((state) => state.data.user);
  const roles = useSelector((state) => state.data.roles);

  const getDashboardLink = () => {
    if (userHasRole('admin', user.roles, roles) || userHasRole('sysadmin', user.roles, roles)) {
      return '/admindashboard';
    } else if (userHasRole('manager', user.roles, roles)) {
      return '/managerdashboard';
    } else if (userHasRole('employee', user.roles, roles)) {
      return '/dashboard';
    } else {
      return '/';
    }
  };

  return { getDashboardLink };
};

export default useGrantedRoutes;
