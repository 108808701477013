import React, { Component } from 'react';
import { connect } from "react-redux";
import { getQuestionSetInstances } from "../../actions/questionSetActions";
import { questionInstanceUpsert, setFinishedTime, closeQuestionSetInstance } from "../../actions/questionSetActions";
import { arrayFromKeyedObject, getQuestionTypeFromFlex } from '../utils/sharedUtils';
import { ProgressBar, Intent } from "@blueprintjs/core";
import { Card, RadioGroup, Radio, TextArea, Spinner } from "@blueprintjs/core";
import Spaner from '../utils/Spaner';
import cfg from '../../config';
import _ from 'lodash';
import withRouter from '../common/withRouter';

const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;

class TestWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            questionSetInstance: {},
            questionTypes: this.props.questionTypes,
            questions: arrayFromKeyedObject(this.props.questions),
            resources: arrayFromKeyedObject(this.props.resources),
            id_question: parseInt(this.props.router.params.questionId, 10), // Id to control the routes between questions
            withoutTime: true,
            enableGoBack: false, // Switch to know if is the last question and shows "Go back" button
            answer: null,
            // Total time for the test
            getsecs: "",
            getmins: "",
            progress: null,
            submitting: false
        };
        this._end_time = null
    }


    componentWillMount() {
        if (this.props.questionSetInstance !== undefined && !this.props.questionSetInstance.end_time) {
            this._end_time = new Date()
            this._end_time = this._end_time.setSeconds(this._end_time.getSeconds() + this.props.questionSet.time)
            // Updating the end time to the question set instance
            this.props.setFinishedTime(
                this._end_time,
                this.props.questionSetInstance.id,
                () => {
                    let seconds = this.props.questionSet.time % 60;
                    let minutes = this.props.questionSet.time / 60;

                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }

                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }

                    this.props.getQuestionSetInstances(this.props.router.params.questionSetId, null, null);

                    this.setState({
                        getsecs: seconds,
                        getmins: minutes
                    })
                },
                null
            );
        }
        else { // Otherwise, the end time is already established
            if (this.props.questionSetInstance !== undefined && this.props.questionSet.time !== 0) {
                this._end_time = new Date(Number(this.props.questionSetInstance.end_time))
                let now = new Date();
                let distance = new Date(this._end_time) - now;

                if (distance < 0) {
                    this.setState({
                        getmins: "00",
                        getsecs: "00"
                    })
                    let position = this.props.questionSet.questions.indexOf(this.state.id_question);
                    this.synchronizeAnswers(this.props.questionSet.questions[position], false, true, false);
                }
                else {
                    this.setState({
                        getmins: Math.floor((distance % _hour) / _minute) < 10 ? "0" + Math.floor((distance % _hour) / _minute) : Math.floor((distance % _hour) / _minute),
                        getsecs: Math.floor((distance % _minute) / _second) < 10 ? "0" + Math.floor((distance % _minute) / _second) : Math.floor((distance % _minute) / _second)
                    });
                }
            }
        }
    }

    /****************************************************************
    * Here I got all the necessary data to manipulate the time and  *
    * its launched after the component is mount.                    *
    * ***************************************************************/

    componentDidMount() {
        if (this.props.questionSetInstance !== undefined) {
            let newArray = this.updateCodeType();
            this.setState({
                questions: newArray
            }, () => {
                this.updateSelectedQuestions(arrayFromKeyedObject(this.props.questionInstances));
            })

            if (parseInt(this.props.questionSet.time, 10) !== 0) {
                // Start the interval with the timer
                this.countdownTimer = setInterval(this.startWatch, 1000);
            }
        }
    }

    //Method to fetch the data if the url changed 
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            // Render the option selected with the new updating
            this.updateSelectedQuestions(arrayFromKeyedObject(this.props.questionInstances));
            //  this.updatingProgress();
        }
    }

    componentWillUnmount() {
        // Clear the interval and stoping the timer
        clearInterval(this.countdownTimer);
    }

    handleOnSuccess = (question_set_id, cur_question_id, finished_status) => {
        if (finished_status === 0) {
            this.props.router.navigate('/testing/' + question_set_id + '/question/' + (parseInt(cur_question_id, 10)));
        }
        else if (finished_status === 1 || finished_status === 2) {
            this.props.router.navigate('/testing/' + question_set_id);
        }
        else {
            this.props.router.navigate('/testing/' + question_set_id);
        }
    }


    //Method to update the questions already answered by the user
    updateSelectedQuestions = (questionInstances) => {
        let question = _.find(questionInstances, { question: parseInt(this.props.router.params.questionId, 10), question_set_instance: this.props.questionSetInstance.id });
        if (_.has(question, 'flex.answer')) {
            this.setState({
                answer: question.flex.answer
            });
        }
        else {
            this.setState({
                answer: null
            });
        }
    }

    upsertQuestionInstance = (data, question_set_id, cur_question_id, finished_status) => {
        this.props.questionInstanceUpsert(
            data.question,
            data.user,
            data.flex,
            question_set_id,
            finished_status,
            () => this.handleOnSuccess(question_set_id, cur_question_id, finished_status),
            null
        );
        //used to ensure component does not try to render with closed test
        if (finished_status === 1 || finished_status === 2) {
            this.setState({
                submitting: true
            })
        }
    }

    synchronizeAnswers = (cur_question_id, isGoBack, isFinishedTest, isPrevious) => {

        let question_set_id = parseInt(this.props.router.params.questionSetId, 10); // Id question set
        let question_id = parseInt(this.props.router.params.questionId, 10); // Id question

        // Attributes needed to upsert the question
        let instance = { // Aditional information for the question
            question: question_id,
            user: this.props.user.id,
            flex: {
                answer: this.state.answer
            }
        };

        let current_question = _.find(this.state.questions, { id: question_id });

        // If the current question doesn't exist, setting the answer on null 
        if (!current_question) {
            return this.setState({
                answer: null
            })
        }

        let question_type = getQuestionTypeFromFlex(current_question.flex);

        //call one time the create (upsert) on this data

        instance.flex.type = question_type;
        // The user clicks on the "Go back" button and I should return the id question set to the dashboard
        if (isFinishedTest) {
            this.upsertQuestionInstance(instance, question_set_id, cur_question_id, 2);
        }
        else if (isGoBack) {
            this.upsertQuestionInstance(instance, question_set_id, cur_question_id, 1);
        }
        else if (isPrevious) {
            this.upsertQuestionInstance(instance, question_set_id, cur_question_id, 0);
        }
        else {
            this.upsertQuestionInstance(instance, question_set_id, cur_question_id, 0);
        }
    }

    /*************************************************************
    * Method to start the timer per test with the time remaining *
    **************************************************************/

    startWatch = () => {
        let now = new Date();
        let elapsed_time = new Date(this._end_time) - now;

        // The time is already over due to the end time is less than now
        if (elapsed_time < 0 && this._end_time) {
            let position = this.props.questionSet.questions.indexOf(this.state.id_question);
            this.props.closeQuestionSetInstance(
                this.props.questionSetInstance.id,
                () => {
                    this.synchronizeAnswers(this.props.questionSet.questions[position], false, true, false);
                },
                null
            ); // Closing the test because the timer is already over
            clearInterval(this.countdownTimer); // Clearing the interval 
        }
        else {
            this.setState({
                getmins: Math.floor((elapsed_time % _hour) / _minute) < 10 ? "0" + Math.floor((elapsed_time % _hour) / _minute) : Math.floor((elapsed_time % _hour) / _minute),
                getsecs: Math.floor((elapsed_time % _minute) / _second) < 10 ? "0" + Math.floor((elapsed_time % _minute) / _second) : Math.floor((elapsed_time % _minute) / _second)
            })
        }
    }

    handleMultipleChoiceChange = (e) => {
        this.setState({
            answer: e.target.value
        });
    }

    //Handle the true false radio group in questions
    handleTrueFalseChange = (e) => {
        this.setState({
            answer: e.target.value
        });
    }

    handleEssayChange = (e) => {
        this.setState({
            answer: e.target.value
        });
    }

    handleFillInTheBlankChange = (e, index) => {
        let currentAnswer = this.state.answer || []
        currentAnswer[index] = e.target.value
        this.setState({
            answer: currentAnswer
        });
    }

    goBackToDashboard = () => {
        let position = this.props.questionSet.questions.indexOf(this.state.id_question);

        if (!this.state.answer) {
            this.setState({ id_question: this.props.questionSet.questions[position] },
                () => {
                    this.props.router.navigate('/testing/' + this.props.router.params.questionSetId);
                })
        }
        else {
            this.synchronizeAnswers(this.props.questionSet.questions[position], true, false, false);
        }
    }

    /*********************************************
    * Button to handle the "Next Question" click *
    **********************************************/

    goToNextQuestion = () => {
        let position = this.props.questionSet.questions.indexOf(this.state.id_question) + 1;
        this.synchronizeAnswers(this.props.questionSet.questions[position], false, false, false);
        this.setState({ id_question: this.props.questionSet.questions[position] }) // Plus 1 to the id to know what is the next question
    }

    /*************************************************
    * Button to handle the "Previous Question" click *
    **************************************************/

    goToPreviousQuestion = () => {
        let position = this.props.questionSet.questions.indexOf(this.state.id_question) - 1;
        // If the answer is NULL and I go back, I don't update anything, just change of view to the previous
        if (!this.state.answer) {
            this.setState({ id_question: this.props.questionSet.questions[position] },
                () => {
                    this.props.router.navigate('/testing/' + this.props.router.params.questionSetId + '/question/' + this.props.questionSet.questions[position]);
                })
        }
        else {
            this.synchronizeAnswers(this.props.questionSet.questions[position], false, false, true);
            this.setState({ id_question: this.props.questionSet.questions[position] });
        }
    }

    /***************************************************************************
    * Method to finish the test and it shows when you are at the last question *
    ****************************************************************************/

    finishTest = () => {
        let position = this.props.questionSet.questions.indexOf(this.state.id_question);
        this.synchronizeAnswers(this.props.questionSet.questions[position], false, true, false);
    }

    /**************************************************
    * Updating the question type for each one of them *
    ***************************************************/
    updateCodeType = () => {
        let array = [];
        let obj = {};
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].flex.hasOwnProperty("true_false")) {
                obj = { ...this.state.questions[i] }; //creating copy of object
                obj.code_type = 'true_false'; //updating value
                array.push(obj);
                obj = {};
            }
            else if (this.state.questions[i].flex.hasOwnProperty("multiple_choice")) {
                obj = { ...this.state.questions[i] }; //creating copy of object
                obj.code_type = 'multiple_choice'; //updating value
                array.push(obj);
                obj = {};
            }
            else if (this.state.questions[i].flex.hasOwnProperty("essay")) {
                obj = { ...this.state.questions[i] }; //creating copy of object
                obj.code_type = 'essay'; //updating value
                array.push(obj);
                obj = {};
            }
            else if (this.state.questions[i].flex.hasOwnProperty("fill_in_the_blanks")) {
                obj = { ...this.state.questions[i] }; //creating copy of object
                obj.code_type = 'fill_in_the_blanks'; //updating value
                array.push(obj);
                obj = {};
            }
        }
        return array;
    }

    renderFillInTheBlankQuestion = () => {
        let fill_in_the_blanks = this.props.questions[this.state.id_question].flex.fill_in_the_blanks
        return (
            fill_in_the_blanks.parts.map((part, index) => {
                switch (part.type) {
                    case "text":
                        return (
                            <span style={{ lineHeight: 3, fontSize: 16 }} key={index}>{part.value}</span>
                        )
                    case "blank":
                        return (
                            <span style={{ lineHeight: 3, marginLeft: 8, marginRight: 8 }} key={index}><input className={"bp3-input bp3-large bp3-intent-primary"} onChange={(e) => this.handleFillInTheBlankChange(e, index)} style={{ textAlign: 'center' }} value={_.get(this.state, 'answer[' + index + ']', '')} /></span>
                        )
                    case "number":
                        return (
                            <span style={{ lineHeight: 3, marginLeft: 8, marginRight: 8 }} key={index}><input className={"bp3-input bp3-large bp3-intent-primary"} onChange={(e) => this.handleFillInTheBlankChange(e, index)} style={{ textAlign: 'center' }} type="number" value={_.get(this.state, 'answer[' + index + ']', 0)} /></span>
                        )
                    default:
                        return null
                }
            })
        )
    }

    render() {
        if (this.state.submitting || this.props.questionSetInstance === undefined) {
            return <Spinner />
        }
        else {
            return (
                <div className="pure-u-1">
                    <div className="flexcenter" style={{ marginTop: 16 }}>
                        <div style={{ display: 'block', fontSize: 16, width: '100%', maxWidth: 800 }}>
                            <h2><b>{this.props.questionSet.name}</b></h2>
                            {
                                // Verify if the question set has established the timer, if not I hide the span
                                this.props.questionSet.time !== 0 ?
                                    <span style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: 10,
                                        fontWeight: 'bold',
                                        fontFamily: 'tahoma',
                                        display: 'block',
                                        textAlign: 'center',
                                        color: '#137cbd'
                                    }}> Time: {this.state.getmins + ":" + this.state.getsecs} </span>
                                    :
                                    null
                            }
                            Progress
                            <span style={{ width: '100%', display: 'inline-block' }}>
                                <ProgressBar
                                    intent={Intent.PRIMARY}
                                    stripes={false} animate={false}
                                    value={_.get(this.props.questionSetInstance, 'flex.meta_data.progress', 0)}
                                />
                                Question: {this.props.questionSet.questions.findIndex(k => k === this.state.id_question) + 1}<br />
                            </span>
                        </div><br />
                    </div>
                    <div className="flexcenter" style={{ marginTop: 16 }}>
                        {
                            // Mapping the resources to print inside the card the images related to that test.
                            this.props.questions[this.props.router.params.questionId].resources.length !== 0 ?
                                this.props.questions[this.props.router.params.questionId].resources.map((numberResource) => {
                                    return (
                                        <img
                                            key={this.props.resources[numberResource].id} // Key to bring the traking inside the array 
                                            src={cfg.server_url + "/v1/protectedfile/download?subdir=question&file=" + this.props.resources[numberResource].asset.name}
                                            alt="Resources for the question" // Alternative if the image is not uploaded.
                                            height={100} // 100 pixels of height
                                            width={100} // 100 pixels of width
                                        />
                                    )
                                })
                                :
                                <span></span>
                        }
                    </div>
                    <div className="flexcenter" style={{ marginTop: 16 }}>
                        <b> {this.props.questions[this.state.id_question].question_text} </b>
                    </div>
                    <div className="flexcenter" style={{ marginTop: 16 }}>
                        {
                            this.props.questions[this.state.id_question].flex.hasOwnProperty("true_false") && !this.props.questionSetInstance.closed ?
                                <Card style={{ width: '90%', maxWidth: 600, marginBottom: 25 }} interactive>
                                    <RadioGroup
                                        onChange={this.handleTrueFalseChange}
                                        selectedValue={this.state.answer}
                                    >
                                        <Radio
                                            label={this.props.questions[this.state.id_question].flex.true_false.true_label}
                                            value="true"
                                        />
                                        <Radio label={this.props.questions[this.state.id_question].flex.true_false.false_label} value="false" />
                                    </RadioGroup>
                                </Card>
                                : this.props.questions[this.state.id_question].flex.hasOwnProperty("multiple_choice") && !this.props.questionSetInstance.closed ?
                                    <Card style={{ width: '90%', maxWidth: 600, marginBottom: 25 }} interactive>
                                        <RadioGroup
                                            onChange={this.handleMultipleChoiceChange}
                                            selectedValue={this.state.answer}
                                        >
                                            {
                                                this.props.questions[this.state.id_question].flex.multiple_choice.options.map((option, index) => {
                                                    return <Radio
                                                        key={index}
                                                        label={option.label}
                                                        value={option.label}
                                                    />
                                                })
                                            }
                                        </RadioGroup>
                                    </Card>
                                    :
                                    this.props.questions[this.state.id_question].flex.hasOwnProperty("essay") && !this.props.questionSetInstance.closed ?
                                        <div>
                                            <TextArea
                                                large={true}
                                                className={"txtarea"}
                                                intent={Intent.PRIMARY}
                                                onChange={this.handleEssayChange}
                                                value={this.state.answer || ""}
                                            />
                                        </div>
                                        :
                                        this.props.questions[this.state.id_question].flex.hasOwnProperty("fill_in_the_blanks") ?
                                            <Card style={{ width: '100%', maxWidth: 600, marginBottom: 25 }} interactive>
                                                <div>
                                                    {this.renderFillInTheBlankQuestion()}
                                                </div>
                                            </Card>
                                            :
                                            null
                        }
                    </div>

                    <div className="flexcenter" style={{ marginTop: 16 }}>
                        {
                            // This condition is to know if the user is answering the first question of the set
                            this.state.id_question === this.props.questionSet.questions[0] || this.state.enableGoBack ?
                                <button className="bp3-button bp3-large bp3-intent-primary"
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.goBackToDashboard}
                                >
                                    Go back
                                </button>
                                // This one condition the user is answering other question that is not the first one
                                : this.props.questionSet.questions.indexOf(parseInt(this.props.router.params.questionId, 10)) !== -1 ?
                                    <button className="bp3-button bp3-large bp3-intent-primary"
                                        style={{ cursor: 'pointer' }}
                                        onClick={this.goToPreviousQuestion}
                                    >
                                        Previous
                                    </button>
                                    :
                                    null
                        }
                        <Spaner width="sm" />
                        {
                            // The user is answering the last question of the set
                            this.state.id_question === this.props.questionSet.questions[this.props.questionSet.questions.length - 1]
                                && !this.state.enableGoBack ?
                                <button className="bp3-button bp3-large bp3-intent-primary"
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.finishTest}
                                    disabled={
                                        this.state.answer === null || (parseInt(this.state.total_seconds, 10) === 0 && !this.state.withoutTime)
                                    }
                                >
                                    Finish
                                </button>
                                // There are more questions to answer, so it appears the "Next Question" 
                                :
                                <button className="bp3-button bp3-large bp3-intent-primary"
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.goToNextQuestion}
                                    disabled={
                                        (this.state.answer === null || this.state.enableGoBack) || (parseInt(this.state.total_seconds, 10) === 0 && !this.state.withoutTime)
                                    }
                                >
                                    Next
                                </button>
                        }
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    let questionSetInstances = arrayFromKeyedObject(state.data.questionSetInstances)
    let questionSetInstance = _.find(questionSetInstances, { question_set: parseInt(ownProps.router.params.questionSetId, 10), closed: false });

    return {
        user: state.data.user,
        questionSet: state.data.questionSets[ownProps.router.params.questionSetId],
        questionSets: state.data.questionSets,
        questionSetInstance: questionSetInstance,
        questionInstances: state.data.questionInstances,
        questionSetInstances: state.data.questionSetInstances,
        questions: state.data.questions,
        questionTypes: state.data.questionTypes,
        resources: state.data.resources
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getQuestionSetInstances: (questionSetId, onSuccess, onFail) => {
            dispatch(getQuestionSetInstances(questionSetId, false, onSuccess, onFail));
        },
        questionInstanceUpsert: (question, user, flex, question_set, last_question, onSuccess, onFail) => {
            dispatch(questionInstanceUpsert(question, user, flex, question_set, last_question, onSuccess, onFail));
        },
        setFinishedTime: (end_time, question_set_instance, onSuccess, onFail) => {
            dispatch(setFinishedTime(end_time, question_set_instance, onSuccess, onFail));
        },
        closeQuestionSetInstance: (question_set_instance, onSuccess, onFail) => {
            dispatch(closeQuestionSetInstance(question_set_instance, onSuccess, onFail));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestWrapper));