import React, { Component } from 'react';
import Login from '../auth/Login';

class Home extends Component {
    render() {
        return (
          <div>
            <div className="flexcenter" style={{ color: '#006666' }}>
              <img alt="logo" style={{ width: 200, maxWidth: '50%', marginTop: 32 }} src="https://www.handcraftedrestaurants.com/hcronline/img/hcr-logo.png" />
            </div>
            <Login />
          </div>
        )
    }
}

export default Home