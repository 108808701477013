import {
    GET_CSRF,
    LOGIN,
    REGISTER,
    LOGOUT,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET,
    VALIDATE_TOKEN,
    ROLE_FETCH
} from './actionTypes';

export const roleFetch = (onSuccess, onFail) => ({
  type: ROLE_FETCH,
  payload: {

  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getCSRF = (onSuccess, onFail) => ({
    type: GET_CSRF,
    payload: {

    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const login = (email, password, method, onSuccess, onFail) => ({
    type: LOGIN,
    payload: {
        email: email,
        password: password,
        method: method
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const logout = (onSuccess) => ({
    type: LOGOUT,
    payload: {

    },
    onSuccess: onSuccess || null,
});

export const register = (email, first_name, last_name, password, restaurants, departments, captcha, onSuccess, onFail) => ({
    type: REGISTER,
    payload: {
        email: email,
        first_name: first_name,
        last_name: last_name,
        password: password,
        restaurants: restaurants,
        departments: departments,
        captcha: captcha
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const passwordResetRequest = (email, captcha, onSuccess, onFail) => ({
    type: PASSWORD_RESET_REQUEST,
    payload: {
        email: email,
        captcha: captcha
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const passwordReset = (password, token, onSuccess, onFail) => ({
    type: PASSWORD_RESET,
    payload: {
        password: password,
        token: token
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const validateToken = (token, onSuccess, onFail) => ({
    type: VALIDATE_TOKEN,
    payload: {
        token: token,
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});