import React from "react";
import App from "../App";

import 'antd/dist/reset.css'; // For Ant Design v5+
import '../css/grids.css';
import '../css/grids-responsive.css';
// import '../css/blueprint.css';
import '../css/table.css';
// import '../css/blueprint-select.css';
// import '../css/blueprint-icons.css';
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '../css/index.css';

const AppContainer = () => {
  return <App />;
}

export default AppContainer;