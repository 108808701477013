import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  questionSetFetch,
  getQuestionSetInstances,
} from '../../actions/questionSetActions';
import {
  arrayFromKeyedObject,
  arraysIntersect,
  safeToPercentStr,
} from '../utils/sharedUtils';
import { Card, ProgressBar, Intent, Spinner } from '@blueprintjs/core';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import { Table } from "antd";
import _ from 'lodash';
import { departmentFetch } from '../../actions/departmentActions';
import { restaurantFetch } from '../../actions/restaurantActions';
import { questionTypeFetch } from '../../actions/questionActions';
import { breadcrumbSet } from '../../actions/utilActions';
import PureGridBox24th from '../utils/PureGridBox24th';
import faBadgeCheck from '@fortawesome/fontawesome-pro-regular/faBadgeCheck';
import faTimesCircle from '@fortawesome/fontawesome-pro-regular/faTimesCircle';
import withRouter from '../common/withRouter';
class EmployeeDashboard extends Component {
  constructor(props) {
    super(props);
    // State to save the resources as an array instead of an object
    // This allow to map when its going to show the images per test
    this.state = {
      resources: [],
      requiredTests: [],
      optionalTests: [],
      requiredTestsCompleted: false,
    };
    this._restaurantsInitialized = false;
    this._departmentsInitialized = false;
    this._questionSetsInitialized = false;
  }

  checkAllInitialized() {
    const questionsArray = Object.values(this.props.questionSets);
    if (
      _.every([
        this._restaurantsInitialized,
        this._departmentsInitialized,
        this._questionSetsInitialized,
      ]) &&
      this.props.questionSets &&
      Object.keys(this.props.questionSets).length > 0
    ) {
      let requiredTests = [];
      let optionalTests = [];
      _.forEach(this.props.questionSets, (questionSet) => {
        if (
          arraysIntersect(questionSet.restaurants, this.props.user.restaurants)
        ) {
          if (
            arraysIntersect(
              questionSet.departments,
              this.props.user.departments
            )
          ) {
            requiredTests.push(questionSet);
          } else {
            optionalTests.push(questionSet); // I just added the optional tests to restaurants belonging the users
          }
        } else {
        }
      });
      this.setState(
        {
          requiredTests: requiredTests,
          optionalTests: optionalTests,
        },
        () => {
          let _testCompleted = [];
          /* Logic to know if the user completed all the test belonging. */
          _.forEach(this.state.requiredTests, (test) => {
            _.forEach(
              arrayFromKeyedObject(this.props.questionSetInstances),
              (qsi) => {
                if (test.id === qsi.question_set) {
                  _testCompleted.push(test);
                }
              }
            );
          });

          _testCompleted = _.uniqBy(_testCompleted, 'id'); // Taking off the duplicates
          if (_testCompleted.length === this.state.requiredTests.length) {
            this.setState({
              requiredTestsCompleted: true,
            });
          }
        }
      );
    } else {
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionSets !== this.props.questionSets) {
      this.checkAllInitialized();
    }
  }

  componentDidMount() {
    this.props.getQuestionSetInstances(null, null, null);

    //once all the data is initialized determine required and optional tests

    const onSuccessQuestionSetFetch = () => {
      this._questionSetsInitialized = true;
      this.setState({ resources: arrayFromKeyedObject(this.props.resources) });
      this.checkAllInitialized();
    };

    this.props.restaurantFetch(() => {
      this._restaurantsInitialized = true;
      this.checkAllInitialized();
    });
    this.props.departmentFetch(() => {
      this._departmentsInitialized = true;
      this.checkAllInitialized();
    });
    this.props.questionSetFetch(false, [], [], onSuccessQuestionSetFetch);
    this.props.questionTypeFetch();

    if (this.props.user.temporary_password) {
      this.props.breadcrumbSet([
        {
          name: 'temporarypasswordreset',
          active: true,
          link: '/temporarypasswordreset',
        },
      ]);
    } else {
      this.props.breadcrumbSet([
        { name: 'dashboard', active: true, link: '/dashboard' },
      ]);
    }
  }

  hasFailedTest(qsi) {
    let pendingGrading;
    let userPointValue;
    const multipleScores = qsi.questionSetInstance != null;
    if (multipleScores) {
      pendingGrading = _.get(
        qsi,
        'questionSetInstance.flex.meta_data.pending_grading',
        0
      );
      userPointValue = _.get(
        qsi,
        'questionSetInstance.flex.meta_data.user_point_value',
        0
      );
    } else {
      pendingGrading = _.get(qsi, 'flex.meta_data.pending_grading', 0);
      userPointValue = _.get(qsi, 'flex.meta_data.user_point_value', 0);
    }

    if (pendingGrading === true) {
      return false; //TODO: should check value without each of the pending grading questions?
    } else if (userPointValue === 0) {
      //no points = fail
      return true;
    }
    let score;
    if (multipleScores) {
      score = _.get(qsi, 'questionSetInstance.flex.meta_data.percent_score', 0);
    } else {
      score = _.get(qsi, 'flex.meta_data.percent_score', 0);
    }

    let passingScore = _.get(qsi, 'passing_score', 0);
    return score * 100 < passingScore;
  }

  hasPassedTest(qsi) {
    let score;
    const multipleScores = qsi.questionSetInstance != null;
    if (multipleScores) {
      score = _.get(qsi, 'questionSetInstance.flex.meta_data.percent_score', 0);
    } else {
      score = _.get(qsi, 'flex.meta_data.percent_score', 0);
    }
    let passingScore = _.get(qsi, 'passing_score', 0);
    return score * 100 >= passingScore;
  }

  render() {
    const { size, hasValue, intent, value } = this.state;

    const renderQuestionSets = (questionSetSource) => {
      let questionSets = [];
      let questionSetInstances = arrayFromKeyedObject(
        this.props.questionSetInstances
      );
      let filteredQSInstances = [];

      const getNumberOfAttempts = (questionSetInstances, questionSetId) => {
        let number_of_attempts = 0;

        _.forEach(questionSetInstances, (qs) => {
          if (qs.question_set === questionSetId) {
            number_of_attempts += 1;
          }
        });
        return number_of_attempts;
      };

      const goToQuestionSet = (qs) => {
        this.props.router.navigate('/testing/' + qs.id);
      };

      const renderResources = (qs, isClosed, isPending, hasMultipleScores) => {
        if (isClosed && !isPending) {
          if (hasMultipleScores) {
            return (
              <div>
                <b>
                  Best :{' '}
                  {safeToPercentStr(
                    _.get(
                      qs.bestScoreInstance,
                      'flex.meta_data.percent_score',
                      0
                    )
                  ) + '%'}
                </b>{' '}
                &nbsp;
                <b>
                  Latest :{' '}
                  {safeToPercentStr(
                    _.get(
                      qs.questionSetInstance,
                      'flex.meta_data.percent_score',
                      0
                    )
                  ) + '%'}
                </b>
              </div>
            );
          } else {
            return (
              <b>
                Score :{' '}
                {safeToPercentStr(
                  _.get(
                    qs.questionSetInstance,
                    'flex.meta_data.percent_score',
                    0
                  )
                ) + ' %'}
              </b>
            );
          }
        } else if (isPending) {
          return (
            <div>
              <b>
                Best :{' '}
                {safeToPercentStr(
                  _.get(qs.bestScoreInstance, 'flex.meta_data.percent_score', 0)
                ) + '%'}
              </b>{' '}
              &nbsp;
              <b>Latest : "PENDING GRADING"</b>
            </div>
          );
        }
      };

      const renderSituation = (qs, isClosed, hasMultipleScores) => {
        const renderGreenCheck = () => {
          return (
            <b style={{ color: 'green' }}>
              <span style={{ position: 'relative', bottom: 20 }}>Passed</span>
              &nbsp;&nbsp;
              <FontAwesomeIcon
                icon={faBadgeCheck}
                size="5x"
                style={{ color: 'green' }}
              />
            </b>
          );
        };

        const renderRedCheck = () => {
          return (
            <b style={{ color: 'red' }}>
              <span style={{ position: 'relative', bottom: 20 }}>Failed</span>
              &nbsp;&nbsp;
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="5x"
                style={{ color: 'red' }}
              />
            </b>
          );
        };

        const renderPendingGrading = (qs) => {
          return <b>"PENDING GRADING"</b>;
        };

        if (isClosed) {
          if (hasMultipleScores) {
            if (this.hasPassedTest(qs.bestScoreInstance)) {
              return renderGreenCheck();
            } else if (this.hasFailedTest(qs.bestScoreInstance)) {
              return renderRedCheck();
            } else {
              return renderPendingGrading(qs);
            }
          } else {
            if (this.hasPassedTest(qs)) {
              return renderGreenCheck();
            } else {
              if (this.hasFailedTest(qs)) {
                return renderRedCheck();
              } else {
                return renderPendingGrading(qs);
              }
            }
          }
        }
      };

      _.forEach(questionSetSource, (qs) => {
        if (qs.active) {
          filteredQSInstances = _.filter(questionSetInstances, {
            question_set: qs.id,
          });
          questionSets.push(qs);

          if (filteredQSInstances.length > 1) {
            qs.questionSetInstance = _.find(filteredQSInstances, {
              createdAt: _.maxBy(filteredQSInstances, 'createdAt').createdAt,
            });
            qs.questionSetInstance.flex.meta_data.hasMoreScore = true;
            filteredQSInstances.forEach((qsi, index) => {
              if (index === 0) {
                qs.bestScoreInstance = qsi;
              } else {
                let percentHighest =
                  qs.bestScoreInstance.flex.meta_data.percent_score;
                let currentQsi = qsi.flex.meta_data.percent_score;

                if (currentQsi > percentHighest) {
                  qs.bestScoreInstance = qsi;
                }
              }
              qs.bestScoreInstance.passing_score = qs.passing_score;
            });
          } else if (filteredQSInstances.length) {
            qs.questionSetInstance = _.find(filteredQSInstances, {
              createdAt: _.maxBy(filteredQSInstances, 'createdAt').createdAt,
            });
            qs.questionSetInstance.flex.meta_data.hasMoreScore = false;
          }
        }
      });

      return questionSets.map((qs) => {
        let isClosed = _.get(qs.questionSetInstance, 'closed', false);
        let isPending = _.get(
          qs.questionSetInstance,
          'flex.meta_data.pending_grading',
          false
        );
        let hasMultipleScores = _.get(
          qs.questionSetInstance,
          'flex.meta_data.hasMoreScore',
          false
        );
        let progress;
        if (hasMultipleScores) {
          progress = _.get(qs.bestScoreInstance, 'flex.meta_data.progress', 0);
        } else {
          progress = _.get(
            qs.questionSetInstance,
            'flex.meta_data.progress',
            0
          );
        }

        return (
          <Card
            key={qs.id}
            onClick={() => goToQuestionSet(qs)}
            style={{ width: '90%', maxWidth: 600, marginBottom: 25 }}
            interactive
          >
            <div>
              <b>{qs.name}</b>
            </div>
            <br />
            <div>
              <b>Description : </b>
              <span>{qs.description}</span>
            </div>
            <br />
            <div>
              <b>Number of attempts : </b>
              <span>
                {getNumberOfAttempts(
                  arrayFromKeyedObject(this.props.questionSetInstances),
                  qs.id
                )}
              </span>
            </div>
            <br />
            <b>Resources: </b>
            <br />
            <br />
            {renderResources(qs, isClosed, isPending, hasMultipleScores)}
            <br /> <br />
            Progress:{' '}
            <span style={{ width: '89%', display: 'inline-block' }}></span>
            <ProgressBar
              intent={Intent.PRIMARY}
              stripes={false}
              animate={false}
              value={progress}
            />
            <br />
            {renderSituation(qs, isClosed, hasMultipleScores)}
          </Card>
        );
      });
    };
    if (!this.props.restaurants || !this.props.departments) {
      return (
        <div>
          <Spinner
            intent={intent}
            size={size}
            value={hasValue ? value : null}
          />
        </div>
      );
    }

    return (
      <div>
        <PureGridBox24th>
          <div style={{ fontSize: 14, width: '100%', marginTop: 18 }}>
            <h3>
              <b>
                Required Tests{' '}
                {this.state.requiredTestsCompleted ? (
                  <b style={{ color: 'green' }}>
                    <span
                      style={{
                        position: 'relative',
                        float: 'right',
                        fontSize: 14,
                        right: 45,
                      }}
                    >
                      All required tests were completed
                    </span>
                    &nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      size="5x"
                      style={{
                        float: 'right',
                        position: 'relative',
                        bottom: 35,
                        width: 35,
                        left: 261,
                      }}
                    />
                  </b>
                ) : null}
              </b>
            </h3>
            <hr />
          </div>
        </PureGridBox24th>
        <div
          className="flexcenter"
          style={{
            color: '#006666',
            position: 'relative',
            top: 20,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <h3 style={{ position: 'relative', right: '23%' }} > Required Tests </h3> */}

          {renderQuestionSets(this.state.requiredTests)}
          <br />
        </div>

        <PureGridBox24th>
          <div style={{ fontSize: 14, width: '100%', marginTop: 18 }}>
            <h3>
              <b>Other Tests</b>
            </h3>
            <hr />
          </div>
        </PureGridBox24th>
        <div
          className="flexcenter"
          style={{
            color: '#006666',
            position: 'relative',
            top: 20,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <h3 style={{ position: 'relative', right: '23%' }} > Required Tests </h3> */}

          {renderQuestionSets(this.state.optionalTests)}
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    questionSets: state.data.questionSets,
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    resources: state.data.resources,
    questionSetInstances: state.data.questionSetInstances,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    questionSetFetch: (
      include_answers,
      restaurants,
      departments,
      onSuccess,
      onFail
    ) => {
      dispatch(
        questionSetFetch(
          include_answers,
          restaurants,
          departments,
          onSuccess,
          onFail
        )
      );
    },
    departmentFetch: (onSuccess, onFail) => {
      dispatch(departmentFetch(onSuccess, onFail));
    },
    restaurantFetch: (onSuccess, onFail) => {
      dispatch(restaurantFetch(onSuccess, onFail));
    },
    questionTypeFetch: (onSuccess, onFail) => {
      dispatch(questionTypeFetch(onSuccess, onFail));
    },
    breadcrumbSet: (breadcrumbs) => {
      dispatch(breadcrumbSet(breadcrumbs));
    },
    getQuestionSetInstances: (question_set, onSuccess, onFail) => {
      dispatch(getQuestionSetInstances(question_set, false, onSuccess, onFail));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeDashboard));
