// Test Action Types.
export const TEST_CONNECTION = 'TEST_CONNECTION';

// Auth Action Types
export const AUTHENTICATE = 'AUTHENTICATE';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const ROLE_FETCH = 'ROLE_FETCH';

// User Action Types.
export const USER_FETCH = 'USER_FETCH';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CREATE = 'USER_CREATE';
export const USER_GET_DATA = 'USER_GET_DATA';
export const USER_GET_DATA_EXTENDED = 'USER_GET_DATA_EXTENDED';

// Question Set Action Types
export const QUESTION_SET_FETCH = 'QUESTION_SET_FETCH';
export const QUESTION_SET_CREATE = 'QUESTION_SET_CREATE';
export const QUESTION_SET_UPDATE = 'QUESTION_SET_UPDATE';
export const QUESTION_SET_UPLOAD_FILE = 'QUESTION_SET_UPLOAD_FILE';
export const QUESTION_SET_GRADING = 'QUESTION_SET_GRADING';
export const QUESTION_SET_INSTANCES_CREATE = 'QUESTION_SET_INSTANCES_CREATE';
export const GET_QUESTION_SET_INSTANCES = 'GET_QUESTION_SET_INSTANCES';
export const QUESTION_INSTANCE_UPSERT = 'QUESTION_INSTANCE_UPSERT';
export const QUESTION_SET_RESOURCE_MOVE_DOWN =
  'QUESTION_SET_RESOURCE_MOVE_DOWN';
export const QUESTION_SET_RESOURCE_DELETE = 'QUESTION_SET_RESOURCE_DELETE';
export const QUESTION_SET_RESOURCE_MOVE_UP = 'QUESTION_SET_RESOURCE_MOVE_UP';
export const QUESTION_SET_INSTANCE_FINISH_TIME =
  'QUESTION_SET_INSTANCE_FINISH_TIME';
export const CLOSE_QUESTION_SET_INSTANCE = 'CLOSE_QUESTION_SET_INSTANCE';
export const DELETE_QUESTION_SET_INSTANCE = 'DELETE_QUESTION_SET_INSTANCE';

// Question Action Types
export const QUESTION_CREATE = 'QUESTION_CREATE';
export const QUESTION_UPDATE = 'QUESTION_UPDATE';
export const QUESTION_TYPE_FETCH = 'QUESTION_TYPE_FETCH';
export const QUESTION_MOVE_UP = 'QUESTION_MOVE_UP';
export const QUESTION_MOVE_DOWN = 'QUESTION_MOVE_DOWN';
export const QUESTION_DELETE = 'QUESTION_DELETE';
export const QUESTION_UPLOAD_FILE = 'QUESTION_UPLOAD_FILE';
export const QUESTION_REMOVE_FILE = 'QUESTION_REMOVE_FILE';
export const QUESTION_INSTANCE_GRADE = 'QUESTION_INSTANCE_GRADE';
export const GET_QUESTION_INSTANCES_FROM_QSI =
  'GET_QUESTION_INSTANCES_FROM_QSI';

// Departments Action Types
export const DEPARTMENT_FETCH = 'DEPARTMENT_FETCH';
export const DEPARTMENT_CREATE = 'DEPARTMENT_CREATE';
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';

// Restaurants Action Types
export const RESTAURANT_FETCH = 'RESTAURANT_FETCH';

// Reports views for Admins
export const REPORTS_VIEW_FETCH = 'REPORTS_VIEW_FETCH';
export const REPORTS_OVERVIEW_FETCH = 'REPORTS_OVERVIEW_FETCH';
export const REPORTS_OVERVIEW_RESULTS_FETCH = 'REPORTS_OVERVIEW_RESULTS_FETCH';

// Form Validation Action Types
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const VALIDATE = 'VALIDATE';
export const SET_INVALID = 'SET_INVALID';
export const SET_VALID = 'SET_VALID';
export const SUBMIT = 'SUBMIT';
export const SUBMITTING = 'SUBMITTING';
export const SUBMITTED = 'SUBMITTED';

// Util action Types
export const BREADCRUMB_SET = 'BREADCRUMB_SET';
export const GET_CSRF = 'GET_CSRF';
export const SHOW_TOASTER = 'SHOW_TOASTER';
export const SET_REPORT_SELECTED_RESTAURANTS =
  'SET_REPORT_SELECTED_RESTAURANTS';
export const SET_REPORT_SELECTED_DEPARTMENTS =
  'SET_REPORT_SELECTED_DEPARTMENTS';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const IS_QUESTION_SET_CLOSED = 'IS_QUESTION_SET_CLOSED';
