import React, { useEffect, Component } from 'react';
import fontawesome from '@fortawesome/fontawesome';
import faCoffee from '@fortawesome/fontawesome-pro-solid/faCoffee';
import faAddressBook from '@fortawesome/fontawesome-pro-solid/faAddressBook';
import faArrowAltFromRight from '@fortawesome/fontawesome-pro-solid/faArrowAltFromRight';
import faHome from '@fortawesome/fontawesome-pro-solid/faHome';
import faEdit from '@fortawesome/fontawesome-pro-solid/faEdit';
import faMinusCircle from '@fortawesome/fontawesome-pro-regular/faMinusCircle';
import faArrowCircleDown from '@fortawesome/fontawesome-pro-regular/faArrowCircleDown';
import faArrowCircleUp from '@fortawesome/fontawesome-pro-regular/faArrowCircleUp';
import faCheckCircle from '@fortawesome/fontawesome-pro-regular/faCheckCircle';
import faTimes from '@fortawesome/fontawesome-pro-regular/faTimes';
import faEye from '@fortawesome/fontawesome-pro-regular/faEye';
import faSpinner from '@fortawesome/fontawesome-pro-regular/faSpinner';
import faTrashAlt from '@fortawesome/fontawesome-pro-regular/faTrashAlt';
import RouterContainer from './containers/RouterContainer';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider, Progress, Spin } from 'antd';
import AppPublic from './containers/AppPublic';
import { useSelector, useDispatch } from 'react-redux';
import { getCSRF } from './actions/authActions';
import { userGetData } from './actions/userActions';
import Loading from './components/shared/Loading';
fontawesome.library.add(
  faCoffee,
  faAddressBook,
  faArrowAltFromRight,
  faHome,
  faEdit,
  faMinusCircle,
  faArrowCircleDown,
  faArrowCircleUp,
  faCheckCircle,
  faTimes,
  faSpinner,
  faTrashAlt,
  faEye
);

const customTheme = {
  token: {
    colorPrimary: '#7CAB2F', // Your primary color
    colorLink: '#1DA57A', // Link color
  },
};

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.data.user);

  useEffect(() => {
    dispatch(getCSRF());
    dispatch(
      userGetData({}, (data) => {
        console.log('success to user call', data);
      })
    );
  }, []);

  const renderApp = () => {
    if (user === undefined || user === null) {
      return <Loading />;
    }
    if (!user?.authorized) {
      return <AppPublic />;
    } else {
      return <RouterContainer />;
    }
  };

  console.log('User?', user);

  return (
    <div className="App">
      <ConfigProvider theme={customTheme}>
        <Router>{renderApp()}</Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
