import { all, call, put, } from 'redux-saga/effects'; //call
import { autoYields } from '../reducers/schema';
import HttpRequest, { objectToQuery } from '../utils/api/HttpRequest'
const cfg = require('../config')

const client = new HttpRequest({baseUrl: cfg.server_url});

export async function genericGet(data, url) {
  console.log("calling generic get", data, url)
  return client.get(url + objectToQuery(data));
}

export async function genericPost(data, url) {
  return client.post(url, data);
}

export async function genericPut(data, url) {
  return client.put(url, data);
}

export async function genericDelete(data, url) {
  return client.delete(`${url}/${data.id}`);
}

export const genericSaga = {

  *genericHandler(action, genericRestHandler, doAutoYields = true, passthroughActionName = '') {
    try {
      console.log("in the generic handler?")
      if (action.autoHandleLoading === true) {
        // yield put({ type: ACTIONS.IS_LOADING });
      }

      const response = (yield call(genericRestHandler, action.payload))
      if (response.status === 200 || response.status === 201) {
        if (doAutoYields){
          const yields = autoYields(response.data);
          if (yields.length > 0) {
            yield all(yields);
          }
        }
        if (passthroughActionName !== ''){
          yield put({ type: passthroughActionName, data: response.data });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        // if (action.autoSuccessMessage){
        //   yield put({ type: ACTIONS.SUCCESS, payload: {message: action.autoSuccessMessage, epoch: new Date().getTime()} });
        // }
        // if (action.autoHandleLoading === true) {
        //   yield put({ type: ACTIONS.NOT_LOADING });
        // }
      }
      else {
        throw response.status;
      }
    } catch (e) {
      if (e?.response?.status === 401){
        // yield put({ type: ACTIONS.LOGOUT, payload: {forceLogout: true} }); 
      }
      if (action.onFail) {
        action.onFail(e);
      }
      if (action.autoHandleLoading === true) {
        // yield put({ type: ACTIONS.NOT_LOADING });
      }
      if (action.autoHandleError === true) {
        // const friendlyError = GetFriendlyError(e);
        // yield put({ type: ACTIONS.ERROR, payload: {error: friendlyError, epoch: new Date().getTime()} });
      }
    }
  },

};

export default genericSaga;
