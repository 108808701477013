import React, { Component } from 'react';
import { connect } from "react-redux";
import Spaner from '../components/utils/Spaner';
import axios from "axios";
import { Button, Intent } from "@blueprintjs/core";
import { AppToaster } from '../components/common/AppToaster';
import request from '../utils/api/ServerRequest';

const cfg = require('../../src/config');

class UtilityContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        // this.resetDatabase.bind(this);
        this.seedDatabase.bind(this);
        this.defaultPasswords.bind(this);
    }

    // async resetDatabase() {
    //     try {
    //         let response = await axios.get(cfg.server_url + '/v1/init/destroyAll', {
    //             params: {

    //             }
    //         });
    //         console.log("DATABASE CLEARED", response);
    //         this.handleSuccess("Database reset");
    //         return response

    //     }
    //     catch (e) {
    //         this.handleFail(JSON.stringify(e));
    //         return e
    //     }
    // }

    async seedDatabase() {
        try {
            let response = await request.get('/v1/init/seed', {});
            console.log("DATABASE SEEDED", response);
            this.handleSuccess("Database seeded");
            return response
        }
        catch (e) {
            this.handleFail(JSON.stringify(e));
            return e
        }
    }

    async seedDepartments() {
      try {
          let response = await request.get(cfg.server_url + '/v1/init/init_departments', {});
          console.log("DEPARTMENTS SEEDED", response);
          this.handleSuccess("Departments seeded");
          return response
      }
      catch (e) {
          this.handleFail(JSON.stringify(e));
          return e
      }
  }


    async defaultPasswords() {
      try {
          let response = await request.get(cfg.server_url + '/v1/init/reset_default_users', {});
          console.log("USER PASSWORDS UPDATED", response);
          this.handleSuccess("User passwords updated");
          return response
      }
      catch (e) {
          this.handleFail(JSON.stringify(e));
          return e
      }
  }

    async clearInProgress() {
      try {
          let response = await axios.get(cfg.server_url + '/v1/questionset/remove_all_in_progress', {
              params: {

              }
          });
          console.log("Cleared Entries", response);
          this.handleSuccess("Cleared Entries");
          return response
      }
      catch (e) {
          this.handleFail(JSON.stringify(e));
          return e
      }
  }

    handleSuccess = (message) => {
      console.log("SHOW IT!")
      AppToaster.show({
        message: message,
        intent: Intent.SUCCESS,
        icon: 'tick'
    })
    }

    handleFail = (message) => {
      AppToaster.show({
        message: message,
        intent: Intent.DANGER,
        icon: 'warning-sign'
    })
    }

    render() {
      return (
          <div>
              <div className="flexcenter">
                  <Button onClick={() => this.seedDepartments()} intent="primary" large>Seed Departments</Button>
                  <Spaner width={'sm'} />
                  <Button onClick={() => this.seedDatabase()} intent="primary" large>Seed Database</Button>
                  <Spaner width={'sm'} />
                  {/* <Button onClick={() => this.resetDatabase()} intent="primary" large>Reset Database</Button>
                  <Spaner width={'sm'} /> */}
                  <Button onClick={() => this.defaultPasswords()} intent="primary" large>Default Passwords</Button>
              </div>
          </div>
      );
    }
}

const mapStateToProps = (state) => {
  return {
    toaster: state.data.toaster
  };
};


export default connect(mapStateToProps, null)(UtilityContainer);
