import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import useGrantedRoutes from '../../hooks/useGrantedRoutes';

const Breadcrumbs = () => {
  const crumbs = useSelector((state) => state.data.breadcrumbs || []);
  const { getDashboardLink } = useGrantedRoutes();

  return (
    <div
      style={{
        margin: 0,
        marginLeft: '16px',
        flex: 1,
      }}
    >
      <Breadcrumb
        items={crumbs.map((crumb, index) => ({
          title: (
            <>
              {crumb.link ? (
                <Link
                  to={
                    crumb.name.toLowerCase() === 'dashboard'
                      ? getDashboardLink()
                      : crumb.link
                  }
                >
                  {crumb.name}
                </Link>
              ) : (
                <>{crumb.name}</>
              )}
            </>
          ),
        }))}
      />
    </div>
  );
};

export default Breadcrumbs;
