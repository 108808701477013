import React, { Component } from 'react';
import { connect } from "react-redux";
// import TrueFalse from './TrueFalse';
import { Button, MenuItem } from "@blueprintjs/core"; //MenuDivider
// import Spaner from '../utils/Spaner';
import PureGridBox24th from '../utils/PureGridBox24th';
import { Select } from '@blueprintjs/select';

const items = [{ id: 0, value: 'True / False' }, { id: 1, value: 'Multichoice' }, { id: 2, value: 'Fill in the Blanks' }]
class UpsertQuestionSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            type: 'Choose...'
        };
    }

    CellBox = (label, contents) => {
        return (
            <div className="pure-u-1 pure-u-md-12-24 pure-u-xl-6-24" style={{ textAlign: 'left' }}>
                <span>{label}</span><br />
                {contents}
            </div>
        )
    }

    handleStateChange(event, attribute) {
        this.setState({
            [attribute]: event.target.value
        })
    }

    handleSubmit() {
        console.log(this.state);
    }

    renderItem = (item) => {
        return (
            <MenuItem
                active={false}
                key={item.id}
                onClick={() => this.handleValueChange(item)}
                text={item.value}
            />
            // <div key={item.id}>{item.value}</div>
        );
    }

    handleValueChange = (item) => {
        this.setState({
            type: item.value
        })
    }

    render() {
        return (



            <PureGridBox24th>
                <div style={{ color: 'blue', textAlign: 'center', fontSize: 16 }}>
                    <br />


                    {this.CellBox('Name', <input className="bp5-input bp5-large bp5-intent-primary" type="text" placeholder="name" value={this.state.name} onChange={(e) => this.handleStateChange(e, 'name')} />)}
                    {this.CellBox('Description', <input className="bp5-input bp5-large bp5-intent-primary" type="text" placeholder="name" value={this.state.description} onChange={(e) => this.handleStateChange(e, 'description')} />)}

                    {this.CellBox('Type',

                        <Select
                            className="bp5-large"
                            filterable={false}
                            item={items[0]}
                            items={items}
                            itemRenderer={(data) => this.renderItem(data)}
                            noResults={<MenuItem disabled={true} text="No results." />}
                            onItemSelect={this.handleValueChange}
                        >
                            <Button text={this.state.type} rightIcon="double-caret-vertical" />
                        </Select>

                    )}
                    <div className="flexcenter" style={{ textAlign: 'left', width: '100%', marginTop: 16 }}>
                        <Button onClick={() => this.handleSubmit()} intent="primary" large>Submit</Button>
                    </div>
                </div>
            </ PureGridBox24th>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(null, mapDispatchToProps)(UpsertQuestionSet);