import React, { Component } from 'react';
import { register } from "../../actions/authActions";
import { connect } from "react-redux";
import ReCAPTCHA from 'react-google-recaptcha';
import { Intent, Spinner } from "@blueprintjs/core";
import cfg from '../../config.js';
import PureGridBox24th from '../utils/PureGridBox24th';
import Cellbox from '../utils/Cellbox';
import MultiSelectWrapper from '../utils/MultiSelectWrapper';
import { arrayFromKeyedObject } from '../utils/sharedUtils';
import { departmentFetch } from "../../actions/departmentActions";
import { restaurantFetch } from "../../actions/restaurantActions";
import { validate, countErrors } from '../utils/validate';
import { getCSRF } from "../../actions/authActions";
import _ from 'lodash';
import { AppToaster } from '../common/AppToaster.js';
import withRouter from '../common/withRouter';
class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      repeat_password: '',
      first_name: '',
      last_name: '',
      captcha: null,
      departments: [],
      restaurants: [],
      validation: {
        first_name: { rules: [{ name: 'isString' }], isValid: false, errors: [] },
        last_name: { rules: [{ name: 'isString' }], isValid: false, errors: [] },
        departments: { rules: [{ name: 'isRequired' }], isValid: false, errors: [] },
        restaurants: { rules: [{ name: 'isRequired' }], isValid: false, errors: [] },
        password: { rules: [{ name: 'isPassword' }], isValid: false, errors: [] },
        repeat_password: { rules: [{ name: 'matchesVal', val: '', errMessage: 'Password must match' }], isValid: true, errors: [] },
        email: { rules: [{ name: 'isEmail' }], isValid: false, errors: [] },
      },
    };
  }

  componentWillMount() {
    this.props.departmentFetch();
    this.props.restaurantFetch();
  }

  handleStateChange = (event, attribute) => {
    this.setState({
      [attribute]: event.target.value
    })
  }

  doValidation = (param) => {
    // let validation = this.state.validation;
    // let mockState = {validation: {
    //   [param]: this.state.validation[param]
    // }}
    // let mockValidation = validate(mockState);

    // // console.log("OUT", Object.assign(validation, mockValidation.validation));

    // this.setState({
    //   validation: Object.assign(validation, mockValidation.validation)
    // });
  }

  handleSubmit = () => {
    let modState = this.state;
    modState.validation.repeat_password.rules[0].val = this.state.password
    let validation = validate(modState);
    if (countErrors(validation) > 0) {
      this.setState({
        validation: validation
      });
    }
    else {
      if (this.state.captcha !== null) {
        const onSuccess = () => this.handleSuccess();
        const handleFail = (error) => this.handleFail(error);
        this.props.getCSRF(() => this.props.register(
          this.state.email, this.state.first_name, this.state.last_name, this.state.password, _.map(this.state.restaurants, 'id'), _.map(this.state.departments, 'id'), this.state.captcha, onSuccess, handleFail)
        )

      }
      else {
        AppToaster.show({ message: 'Complete recaptcha.', intent: Intent.DANGER, icon: 'warning-sign'})
      }
    }
  }

  handleSuccess = () => {
    AppToaster.show({ message: 'Registration Success. You can now login.', intent: Intent.SUCCESS, icon: 'tick'})
    this.props.router.navigate('/login');
  }

  handleFail = (error) => {
    AppToaster.show({ message: error, intent: Intent.DANGER, icon: 'warning-sign'})
  }

  onCaptchaChange = (value) => {
    this.setState({
      captcha: value
    })
  }

  setDepartments = (departments) => {
    this.setState({
      departments: departments
    });
  }

  setRestaurants = (restaurants) => {
    this.setState({
      restaurants: restaurants
    });
  }

  render() {
    if (!this.props.restaurants || !this.props.departments) {
      return (
        <Spinner />
      )
    }
    let pureClass = "pure-u-1 pure-u-md-2-5 pure-u-xl-3-12"
    return (
      <div>
        <div>
          <div className="flexcenter" style={{ paddingTop: 16, fontSize: 24 }}>
            Register
        </div>
          <PureGridBox24th>
            <div className="flexcenter">
              <Cellbox showBreak={false} pure_override={pureClass} label="First Name" validation={this.state.validation.first_name}>
                <div className="">
                  <input onBlur={() => this.doValidation('first_name')} className="bp5-input bp5-fill bp5-intent-primary" type="text" value={this.state.first_name} onChange={(e) => this.handleStateChange(e, 'first_name')} dir="auto" />
                </div>
              </Cellbox>
            </div>
            <div className="flexcenter">
              <Cellbox showBreak={false} pure_override={pureClass} label="Last Name" validation={this.state.validation.last_name}>
                <div className="">
                  <input className="bp5-input bp5-fill bp5-intent-primary" type="text" value={this.state.last_name} onChange={(e) => this.handleStateChange(e, 'last_name')} dir="auto" />
                </div>
              </Cellbox>
            </div>
            <div className="flexcenter">
              <Cellbox showBreak={false} pure_override={pureClass} label="Email" validation={this.state.validation.email}>
                <div className="">
                  <input className="bp5-input bp5-fill bp5-intent-primary" type="text" value={this.state.email} onChange={(e) => this.handleStateChange(e, 'email')} dir="auto" />
                </div>
              </Cellbox>
            </div>
            <div className="flexcenter">
              <Cellbox showBreak={false} pure_override={pureClass} label="Password" validation={this.state.validation.password}>
                <div className="">
                  <input className="bp5-input bp5-fill bp5-intent-primary" type="password" value={this.state.password} onChange={(e) => this.handleStateChange(e, 'password')} dir="auto" />
                </div>
              </Cellbox>
            </div>
            <div className="flexcenter">
              <Cellbox showBreak={false} pure_override={pureClass} label="Repeat Password" validation={this.state.validation.repeat_password}>
                <div className="">
                  <input className="bp5-input bp5-fill bp5-intent-primary" type="password" value={this.state.repeat_password} onChange={(e) => this.handleStateChange(e, 'repeat_password')} dir="auto" />
                </div>
              </Cellbox>
            </div>
            <div className="flexcenter">
              <Cellbox pure_override={pureClass} label="Departments" validation={this.state.validation.departments}>
                <MultiSelectWrapper
                  initialItems={this.state.departments}
                  items={arrayFromKeyedObject(this.props.departments)}
                  onChange={this.setDepartments}
                >
                </MultiSelectWrapper>
              </Cellbox>
            </div>

            <div className="flexcenter">
              <Cellbox pure_override={pureClass} label="Restaurants" validation={this.state.validation.restaurants}>
                <MultiSelectWrapper
                  initialItems={this.state.restaurants}
                  items={arrayFromKeyedObject(this.props.restaurants)}
                  onChange={this.setRestaurants}
                >
                </MultiSelectWrapper>
              </Cellbox>
            </div>
          </PureGridBox24th>

          <div className="flexcenter" style={{ margin: 16 }}>
            <ReCAPTCHA
              ref="recaptcha"
              sitekey={cfg.google.recaptcha.public}
              onChange={(e) => this.onCaptchaChange(e)}
            />
          </div>

          <div className="pure-u-1" style={{ paddingTop: 16 }}>
            <div className="flexcenter">
              <div className="pure-u-xl-1-6 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-2">
                <button onClick={() => this.handleSubmit()} className="bp5-button bp5-fill bp5-intent-primary">Submit</button>
              </div>
            </div>
          </div>



        </div>


      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return {
    restaurants: state.data.restaurants,
    departments: state.data.departments,
    toaster: state.data.toaster
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    register: (email, first_name, last_name, password, restaurants, departments, captcha, onSuccess, onFail) => {
      dispatch(register(email, first_name, last_name, password, restaurants, departments, captcha, onSuccess, onFail))
    },
    departmentFetch: (onSuccess, onFail) => {
      dispatch(departmentFetch(onSuccess, onFail));
    },
    restaurantFetch: (onSuccess, onFail) => {
      dispatch(restaurantFetch(onSuccess, onFail));
    },
    getCSRF: (onSuccess) => {
      dispatch(getCSRF(onSuccess))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
