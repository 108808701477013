import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

import { questionSetFetch } from '../../actions/questionSetActions';
import { breadcrumbSet } from '../../actions/utilActions';
import QuestionSetTable from '../question/QuestionSetTable';
import Spaner from '../utils/Spaner';

const TestPage = () => {
  const [state, setState] = useState({
    size: undefined,
    hasValue: undefined,
    intent: undefined,
    value: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(questionSetFetch(true, [], []));
    dispatch(breadcrumbSet([{ name: 'Tests', active: true, link: '/tests' }]));
  }, [dispatch]);

  const { size, hasValue, intent, value } = state;

  return (
    <div>
      <div
        style={{ textAlign: 'left', position: 'relative' }}
      >
        <button
          className="bp5-button bp5-large bp5-intent-primary"
          style={{ cursor: 'pointer', position: 'relative' }}
          onClick={() => navigate('/test/new')}
        >
          New Test
        </button>
        <Spaner width="sm" />
        <button
          className="bp5-button bp5-large bp5-intent-primary"
          style={{ cursor: 'pointer', position: 'relative' }}
          onClick={() => navigate('/admin/report')}
        >
          Report View
        </button>
        <Spaner width="sm" />
        <button
          className="bp5-button bp5-large bp5-intent-primary"
          style={{ cursor: 'pointer', position: 'relative' }}
          onClick={() => navigate('/admin/reportoverview')}
        >
          Report Overview
        </button>
      </div>
      <div
        className="flexcenter"
        style={{ position: 'relative', marginTop: 30 }}
      >
        <QuestionSetTable />
      </div>
    </div>
  );
};

export default TestPage;
