import { REPORTS_VIEW_FETCH, REPORTS_OVERVIEW_FETCH, REPORTS_OVERVIEW_RESULTS_FETCH, GET_DASHBOARD_DATA } from "./actionTypes";
import { genericAction } from './genericAction';

export const fetchReports = (departments, restaurants, onSuccess, onFail) => ({
  type: REPORTS_VIEW_FETCH,
  payload: {
    departments: departments,
    restaurants: restaurants
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const fetchReportOverviewResults = (onSuccess, onFail) => ({
  type: REPORTS_OVERVIEW_RESULTS_FETCH,
  payload: null,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const fetchReportOverview = (onSuccess, onFail) => ({
  type: REPORTS_OVERVIEW_FETCH,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getDashboardData = genericAction(GET_DASHBOARD_DATA);