import { isArray } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'
import { isNil } from 'lodash'

const departmentSchema = new schema.Entity('departments');
const restaurantSchema = new schema.Entity('restaurants');
const questionSetInstanceSchema = new schema.Entity('questionSetInstances');
export const roleSchema = new schema.Entity('roles')


const userSchema = new schema.Entity('user', {
    departments: [departmentSchema],
    restaurants: [restaurantSchema],
    roles: [roleSchema]
  });


const overrideResult = 'overrideResult'
const customHandle = 'customHandle'

export function autoYields (data) {

    const yields = []
    const pData = {
      role: roleSchema,
      roles: [roleSchema],
      questionSetInstances: [questionSetInstanceSchema],
      user: customHandle,
    }

    Object.keys(data).forEach((property) => {
      if (pData[property]) {
        const successType = "REDUCE_" + property + "_SUCCESS";

        switch (pData[property]) {
          case overrideResult:
              yields.push(
                put({
                  type: successType,
                  overrides: [
                   {
                    key: property,
                    value: data[property]
                   }
                  ],
                })
              );
            break;
          case customHandle:
            if (property === 'user'){
                let user = normalize(data[property], userSchema).entities.user;
                user = user[Object.keys(user)[0]];
                yields.push(
                    put({
                      type: successType,
                      overrides: [
                       {
                        key: property,
                        value: user
                       }
                      ],
                    })
                  );
            }
            break;
          default:
            if (!isNil(data[property])){
              yields.push(
                put({
                  type: successType,
                  normalized: normalize(data[property], pData[property]),
                })
              );
            }
            break;
        }
      }
      else if (property.startsWith('deleted_')){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}