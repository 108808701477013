import React, { Component } from 'react';
import { logout } from "../../actions/authActions";
import { connect } from "react-redux";
import { Intent, Spinner } from "@blueprintjs/core";
import withRouter from '../common/withRouter'
import { AppToaster } from '../common/AppToaster';
class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount() {
    this.handleSubmit();
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handleSubmit = () => {
    const onSuccess = () => this.handleSuccess();
    this.props.logout(onSuccess);
  }

  handleSuccess = () => {
    AppToaster.show({ message: 'You are Logged Out', intent: Intent.SUCCESS, icon: 'tick'})
    this.props.router.navigate('/');
  }

  handleFail = () => {
    AppToaster.show({ message: 'Logout Attempt Failed', intent: Intent.DANGER, icon: 'warning-sign'})
  }

  render() {
    return (
      <div style={{ color: 'blue', textAlign: 'center', fontSize: 16 }}>
        <br /><br />
        <Spinner />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: (onSuccess) => {
      dispatch(logout(onSuccess))
    },
  }
}

export default connect(null, mapDispatchToProps)(withRouter(Logout));