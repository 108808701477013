import React, { Component } from 'react';
import { Table, Input, Button, Checkbox } from 'antd';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Intent, Alert } from '@blueprintjs/core';
import { userUpdate } from '../../actions/userActions';
import HighlightText from '../utils/HighlightText';
import withRouter from '../common/withRouter';
import { SearchOutlined } from '@ant-design/icons';
import {
  arrayFromKeyedObject,
  commaSeperateStrArray,
  commaSeperateArrayByField,
} from '../utils/sharedUtils';
import { AppToaster } from '../common/AppToaster.js';

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTextFirstName: '',
      searchTextLastName: '',
      searchTextEmail: '',
      filteredInfo: { active: ['true'] },
      activeAlertOpen: false,
      activeUserId: null,
    };
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize, // Keep track of the current page size
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleSearch = (key, selectedKeys, confirm) => () => {
    confirm();
    this.setState({ [key]: selectedKeys[0] });
  };

  handleReset = (key, clearFilters) => {
    clearFilters();
    this.setState({ [key]: '' });
  };

  handleUpdateActiveCancel = () => {
    this.setState({
      activeAlertOpen: false,
    });
  };

  handleUpdateActiveConfirm = () => {
    this.setState({
      activeAlertOpen: false,
    });
    const onSuccess = () => this.handleSuccess();
    const onFail = () => this.handleFail();
    let record = this.props.users[this.state.activeUserId];
    this.props.userUpdate(record.id, !record.active, onSuccess, onFail);
  };

  handleActiveChange = (record) => {
    let activeUserId = record.id;
    this.setState({
      activeAlertOpen: true,
      activeUserId: activeUserId,
    });
  };

  handleSuccess = (response) => {
    AppToaster.show({
      message: 'The status has been successfully changed',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  handleFail = (errors) => {
    if (!errors) {
      return null;
    }
    let errorStr = '';
    if (errors.length > 0) {
      _.forEach(errors, (error) => {
        errorStr += error.detail;
        errorStr += ' ';
      });
      AppToaster.show({
        message: errorStr,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      AppToaster.show({
        message: 'An unexpected error has occured',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    }
  };

  checkAuthForEdit = (record) => {
    if (!this.props.user_roles) {
      return false;
    }
    if (
      this.props.user_roles.includes('sysadmin') ||
      this.props.user_roles.includes('admin')
    ) {
      return true;
    }
    if (this.props.user_roles.includes('manager')) {
      // Check that it is in the same restaurant and the same departments.
      return (
        this.props.user.restaurants.some((x) =>
          record.restaurants.includes(x)
        ) &&
        this.props.user.departments.some((x) => record.departments.includes(x))
      ); // The user and the record share at least a restaurant
    }
    return false;
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    let userColumns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        // specify the condition of filtering result
        sorter: (a, b) => {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
            return -1;
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="First name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextFirstName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.first_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextFirstName } = this.state;
          return searchTextFirstName ? (
            <HighlightText
              text={text}
              highlight={searchTextFirstName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return -1;
          if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Last name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextLastName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.last_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextLastName } = this.state;
          return searchTextLastName ? (
            <HighlightText
              text={text}
              highlight={searchTextLastName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (a.email.toLowerCase() > b.email.toLowerCase()) return -1;
          if (a.email.toLowerCase() < b.email.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Email"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextEmail',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextEmail',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset('searchTextEmail', clearFilters)}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.email.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextEmail } = this.state;
          return searchTextEmail ? (
            <HighlightText
              text={text}
              highlight={searchTextEmail}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Role',
        dataIndex: 'roleConcat',
        render: (text, record) => {
          return text;
        },
        filters: this.props.roleFilters,
        onFilter: (value, record) => record.roleConcat.includes(value),
      },
      {
        title: 'Departments',
        dataIndex: 'departmentsConcat',
        render: (text, record) => {
          return text;
        },
        filters: this.props.departmentFilters,
        onFilter: (value, record) => record.departmentsConcat.includes(value),
      },
      {
        title: 'Restaurants',
        dataIndex: 'restaurantsConcat',
        render: (text, record) => {
          return text;
        },
        filters: this.props.restaurantFilters,
        onFilter: (value, record) => record.restaurantsConcat.includes(value),
      },
      {
        title: 'Active',
        dataIndex: 'active',
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false },
        ],
        onFilter: (value, record) => {
          return record.active === value;
        },
        // filteredValue: filteredInfo.active || null,
        render: (text, record) => {
          return (
            <span>
              <Checkbox
                checked={record.active}
                onChange={() => this.handleActiveChange(record)}
              />
            </span>
          );
        },
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        render: (text, record) =>
          this.checkAuthForEdit(record) ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.props.router.navigate('/user/' + record.id);
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon="edit" />
            </span>
          ) : null,
      },
      {
        title: 'View',
        dataIndex: 'view',
        render: (text, record) =>
          this.checkAuthForEdit(record) ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.props.router.navigate('/user/view/' + record.id);
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                className="fa-lg"
                icon={['far', 'eye']}
              />
            </span>
          ) : null,
      },
    ];

    // console.log('this.props.userArray: ', this.props.userArray);

    return (
      <div>
<Table
  rowKey={(record) => record.id}
  columns={userColumns}
  dataSource={this.props.userArray}
  title={() => <h2>Users</h2>}
  bordered={true}
  onChange={this.handleChange}
  pagination={{
    current: this.state.currentPage || 1, // Current page from state or default to 1
    pageSize: this.state.pageSize || 20, // Current page size or default to 25
    total: this.props.userArray.length, // Total number of users
    showSizeChanger: true, // Enable the page size changer
    pageSizeOptions: ["5", "10", "20", "50"], // Define available page size options
    onShowSizeChange: (current, size) => {
      this.setState({
        currentPage: current,
        pageSize: size, // Update the page size dynamically
      });
    },
  }}
/>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Yes"
          icon="user"
          intent={Intent.PRIMARY}
          isOpen={this.state.activeAlertOpen}
          onCancel={this.handleUpdateActiveCancel}
          onConfirm={this.handleUpdateActiveConfirm}
        >
          Are you sure you want to{' '}
          {_.get(
            this.props.users,
            '[' + this.state.activeUserId + '].active',
            false
          )
            ? 'Deactivate'
            : 'Activate'}{' '}
          this user?
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isManager = false;
  let usersFiltered = [];

  // Verify if the user already connected is a manager
  arrayFromKeyedObject(state.data.roles).forEach((role) => {
    if (role['name'] === 'manager') {
      if (state.data.user.roles[0] === role['id']) {
        isManager = true;
      }
    }
  });

  // If the user has the role of manager
  if (isManager) {
    arrayFromKeyedObject(state.data.users).forEach((user) => {
      user.restaurants.forEach((restaurant) => {
        if (
          state.data.user.restaurants.join().indexOf(restaurant.toString()) !==
          -1
        ) {
          usersFiltered.push(user);
        }
      });
    });
  } else {
    usersFiltered = arrayFromKeyedObject(state.data.users || []);
  }

  // Delete users duplicated
  usersFiltered = _.uniqBy(usersFiltered, (user) => {
    return user.id;
  });

  let restaurantFilters = [];
  _.forOwn(state.data.restaurants, function (value, key) {
    restaurantFilters.push({
      text: value.name,
      value: value.name,
    });
  });

  let departmentFilters = [];
  _.forOwn(state.data.departments, function (value, key) {
    departmentFilters.push({
      text: value.name,
      value: value.name,
    });
  });

  let roleFilters = [];
  _.forOwn(state.data.roles, function (value, key) {
    roleFilters.push({
      text: value.name,
      value: value.name,
    });
  });

  usersFiltered.map((user, index) => {
    let departments = [];
    let restaurants = [];
    _.forEach(user.departments, (departmentId) => {
      departments.push(state.data.departments[departmentId]);
    });
    _.forEach(user.restaurants, (restaurantId) => {
      restaurants.push(state.data.restaurants[restaurantId]);
    });
    user.departmentsConcat = commaSeperateArrayByField(departments, 'name');
    user.restaurantsConcat = commaSeperateArrayByField(restaurants, 'name');
    let roles = user.roles.map((role) => {
      return state.data.roles[role].name;
    });
    user.roleConcat = commaSeperateStrArray(roles);
    return user;
  });

  let user_roles = [];
  _.forEach(state.data.user.roles, (roleId) => {
    if (state.data.roles[roleId]) {
      user_roles.push(state.data.roles[roleId].name);
    }
  });

  return {
    departments: state.data.departments,
    departmentFilters: departmentFilters,
    restaurants: state.data.restaurants,
    restaurantFilters: restaurantFilters,
    roleFilters: roleFilters,
    toaster: state.data.toaster,
    user: state.data.user,
    userArray: usersFiltered,
    user_roles: user_roles,
    users: state.data.users || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userUpdate: (id, active, onSuccess, onFail) => {
      dispatch(
        userUpdate(
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          active,
          null,
          onSuccess,
          onFail
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserTable));
