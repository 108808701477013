import React, { Component } from 'react';
import { Table, Button, Input } from 'antd';
import { Alert, Intent } from '@blueprintjs/core'; //Button, H5, IToaster, Switch,
import { connect } from 'react-redux';
import { getQuestionSetInstances } from '../../actions/questionSetActions';
import { deleteQuestionSetInstance } from '../../actions/questionSetActions';
import { arrayFromKeyedObject } from '../utils/sharedUtils';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-pro-regular/faTrashAlt';
import faClone from '@fortawesome/fontawesome-pro-regular/faClone';
import _ from 'lodash';
import HighlightText from '../utils/HighlightText';
import { yyyymmddhhmmss } from '../../utils/DateHelpers';
import withRouter from '../common/withRouter';
import { SearchOutlined } from '@ant-design/icons';
import { AppToaster } from '../common/AppToaster';
class QuestionSetInstanceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTextFirstName: '',
      searchTextLastName: '',
      searchTextEmail: '',
      isOpen: false, // Active and deactivate the modal to delete the question set instance
      qsUser: '',
      id_question_set_instance: null,
    };
  }

  componentWillMount() {
    this.props.getQuestionSetInstances(
      parseInt(this.props.router.params.questionSetId, 10),
      null,
      null
    );
  }

  handleSuccess = () => {
    // this.setState({
    //   questionsInProgress: arrayFromKeyedObject(this.props.questionsInProgress)
    // })
  };

  handleSuccessDeleted = () => {
    this.setState(
      {
        isOpen: false,
      },
      () => {
        AppToaster.show({
          message: 'Question set instance deleted',
          intent: Intent.SUCCESS,
          icon: 'tick',
        });
      }
    );
  };

  handleFail = () => {
    console.log('Error deleting question instance');
  };

  handleUpdateCancel = () => {
    this.setState({ isOpen: false });
  };

  handleSearch = (key, selectedKeys, confirm) => () => {
    confirm();
    this.setState({ [key]: selectedKeys[0] });
  };

  handleReset = (key, clearFilters) => {
    clearFilters();
    this.setState({ [key]: '' });
  };

  handleUpdateConfirm = () => {
    this.props.deleteQuestionSetInstance(
      this.state.id_question_set_instance,
      this.handleSuccessDeleted,
      this.handleFail
    );
  };

  render() {
    const { isOpen } = this.state;
    let confirmButtonText = 'Delete';
    let alertWord = confirmButtonText.toLowerCase();
    let alertText = (
      <p>
        Are you sure you want to <b>{alertWord}</b> the question set instance
        for <b>{this.state.qsUser}?</b>
      </p>
    );

    let questionSetColumns = [
      {
        title: 'Started',
        dataIndex: 'started',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        },
      },
      {
        title: 'End Time',
        dataIndex: 'end_time',
        sorter: (a, b) => {
          if (a.end_time > b.end_time) return -1;
          if (a.end_time < b.end_time) return 1;
          return 0;
        },
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        sorter: (a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="First name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextFirstName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextFirstName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.first_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextFirstName } = this.state;
          return searchTextFirstName ? (
            <HighlightText
              text={text}
              highlight={searchTextFirstName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        sorter: (a, b) => {
          if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return -1;
          if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Last name"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextLastName',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() =>
                this.handleReset('searchTextLastName', clearFilters)
              }
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.last_name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextLastName } = this.state;
          return searchTextLastName ? (
            <HighlightText
              text={text}
              highlight={searchTextLastName}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => {
          if (a.email.toLowerCase() > b.email.toLowerCase()) return -1;
          if (a.email.toLowerCase() < b.email.toLowerCase()) return 1;
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder="Email"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={this.handleSearch(
                'searchTextEmail',
                selectedKeys,
                confirm
              )}
            />
            <Button
              type="primary"
              onClick={this.handleSearch(
                'searchTextEmail',
                selectedKeys,
                confirm
              )}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset('searchTextEmail', clearFilters)}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#108ee9' : '#aaa',
            }}
          />
        ),
        onFilter: (value, record) =>
          record.email.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text) => {
          const { searchTextEmail } = this.state;
          return searchTextEmail ? (
            <HighlightText
              text={text}
              highlight={searchTextEmail}
              highlightClass={'highlight'}
            />
          ) : (
            text
          );
        },
      },
      {
        title: 'Closed',
        dataIndex: 'closed',
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false },
        ],
        onFilter: (value, record) => {
          return record.closed.toString() === value.toString();
        },
        render: (text, record) => {
          return text.toString();
        },
      },
      {
        title: 'Has Ungraded',
        dataIndex: 'ungraded',
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false },
        ],
        onFilter: (value, record) => {
          return record.ungraded.toString() === value.toString();
        },
        render: (text, record) => {
          return text.toString();
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        render: (text, record) => (
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({
                  isOpen: true,
                  qsUser: record.first_name,
                  id_question_set_instance: record.id,
                });
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon={faTrashAlt} />
            </span>
            <span
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                this.props.router.navigate(
                  '/test/' +
                    this.props.router.params.questionSetId +
                    '/question_set_instances/' +
                    record.id +
                    '/questions'
                );
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon={faClone} />
            </span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={questionSetColumns}
          dataSource={this.props.tableData}
          title={() => <h2>{this.props.questionSet.name} Test Instances</h2>}
          bordered={true}
        />
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText={confirmButtonText}
          icon="changes"
          intent={Intent.PRIMARY}
          isOpen={isOpen}
          onCancel={this.handleUpdateCancel}
          onConfirm={this.handleUpdateConfirm}
        >
          {alertText}
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let currentQuestionSet = _.get(
    state.data,
    'questionSets[' + ownProps.router.params.questionSetId + ']'
  );
  let questionSetInstances = arrayFromKeyedObject(
    state.data.questionSetInstances
  );
  questionSetInstances = _.filter(questionSetInstances, {
    question_set: Number(ownProps.router.params.questionSetId),
  });
  let tableData = [];

  questionSetInstances.map((item) => {
    let user = state.data.users[item.user] || {};
    return tableData.push({
      id: item.id,
      createdAt: item.createdAt,
      started: yyyymmddhhmmss(new Date(Number(item.createdAt))), //new Date(item.createdAt).toISOString().split('T')[0],
      end_time: yyyymmddhhmmss(new Date(Number(item.end_time))),
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      closed: item.closed,
      ungraded: _.get(item, 'flex.meta_data.pending_grading', 'error'),
    });
  });

  return {
    tableData: tableData,
    questionSet: currentQuestionSet,
    users: state.data.users,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuestionSetInstances: (question_set, onSuccess, onFail) => {
      dispatch(getQuestionSetInstances(question_set, false, onSuccess, onFail));
    },
    deleteQuestionSetInstance: (question_set, onSuccess, onFail) => {
      dispatch(deleteQuestionSetInstance(question_set, onSuccess, onFail));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionSetInstanceTable)
);
