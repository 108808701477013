import React, { useState, useEffect } from 'react';
import LineGraph from '../shared/LineGraph';
import { useDispatch } from 'react-redux';
import { getDashboardData } from '../../actions/reportsActions';
import { breadcrumbSet } from '../../actions/utilActions';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [testData, setTestData] = useState([]);

  useEffect(() => {
    dispatch(
      getDashboardData({}, (data) => {
        const testArray = data.question_set_instances;

        // Step 1: Convert timestamps and group by day
        const aggregatedByDay = testArray.reduce((acc, record) => {
          const date = new Date(record.createdAt).toISOString().split('T')[0]; // Extract YYYY-MM-DD
          acc[date] = (acc[date] || 0) + 1; // Increment the count for the day
          return acc;
        }, {});

        // Step 2: Fill gaps in dates (ensure continuity)
        const allDates = Object.keys(aggregatedByDay);
        const minDate = new Date(Math.min(...allDates.map((d) => new Date(d))));
        const maxDate = new Date(Math.max(...allDates.map((d) => new Date(d))));

        let currentDate = minDate;
        const filledData = [];
        while (currentDate <= maxDate) {
          const dateString = currentDate.toISOString().split('T')[0];
          filledData.push({
            label: `${dateString}T00:00:00Z`,
            value: aggregatedByDay[dateString] || 0, // Fill gaps with 0
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setTestData(filledData);
      })
    );
    dispatch(
      breadcrumbSet([
        { name: 'Dashboard', active: true, link: '/admindashboard' },
      ])
    );
  }, [dispatch]);

  if (!testData.length) {
    return null;
  }

  return (
    <div>
      <h3>Tests Taken</h3>
      <LineGraph
        key={1}
        isCurrency={false} // Adjust as necessary
        chartData={{
          labels: testData.map((x) => x.label), // ISO 8601 strings
          datasets: [
            {
              label: `Count`,
              data: testData.map((x) => x.value),
              borderColor: '#2c73bc',
              backgroundColor: '#ecf2fa',
              fill: false,
              pointStyle: 'circle',
              pointRadius: 4,
              pointHoverRadius: 7,
            },
          ],
        }}
        uuid={'testQty'}
      />
    </div>
  );
};

export default AdminDashboard;
