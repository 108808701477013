import React, { Component } from 'react';
import { errorsToString } from '../utils/validate';

class Cellbox extends Component {

  renderBreak = () => {
    if (this.props.showBreak===false){
      return(
        null
      )
    }
    else{
      return(
        <br />
      )
    }
  }

  render() {

    let pureClass = ""
    if (!this.props.pure_override){
      let pure_sm = "pure-u-sm-12-24"
      let pure_md = "pure-u-md-12-24"
      let pure_xl = "pure-u-xl-6-24"
      if (this.props.pure_sm){
        pure_sm = this.props.pure_sm
      }
      if (this.props.pure_md){
        pure_md = this.props.pure_md
      }
      if (this.props.pure_xl){
        pure_xl = this.props.pure_xl
      }
      pureClass = "pure-u-1 "+pure_sm+" "+pure_md+" "+pure_xl
    }
    else{
      pureClass = this.props.pure_override
    }

    let styleOv = "";
    
    if (!this.props.style_override)
    {
        styleOv = "textAlign: 'left'";  
    }
    else
    {
        styleOv = this.props.style_override;
    }
    
    return (
      <div className={pureClass} style={{styleOv}}>
      
        <span>{this.props.label}</span><br />
        {this.props.children}{this.renderBreak()}
        {
          this.props.validation && this.props.validation.isValid === false ?
            <span style={{ color: 'red' }}>
              {errorsToString(this.props.validation.errors)}
            </span>
            :
            <span>&nbsp;</span>
        }
      </div>
    );
  }
}

export default Cellbox;